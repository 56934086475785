import React from "react";
import moment from "moment";
import Tooltip from "components/tooltip";
import { ToCurrency } from "components/utils";
import { IsUserSignedIn } from "components/session";
import truckIcon from "img/truck-icon.svg";
import boxIcon from "img/box-icon.svg";
import couponIcon from "img/coupon-icon-w.svg";
import couponIconk from "img/coupon-icon-k.svg";
import calendarIcon from "img/calendar-icon.svg";
import locationIcon from "img/location-icon.svg";
import { axios_instance } from "components/axios";
import PaymentSection from "templates/checkout/payment-section";
import conektaHelper from "components/conekta-helper";

export default class Summary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderV2: {},
      order: {
        orderBoxes: [],
        orderAdditionalItems: [],
        shipping: {
          postal_code: "",
          street: "",
        },
        selectedDate: {
          hour: "",
          day: "",
        },
        card: {
          number: "1234",
        },
      },
      active: props.active,
      aside: props.aside,
      prices: {
        total: 0,
        boxTotal: 0,
        additionalItemsTotal: 0,
      },
      styles: {
        aside: "aside-section",
      },
      legal: {
        terms: false,
        privacy: false,
      },
      coupon: "",
      openCouponModal: false,
      validCoupon: [
        "BOXMINOW",
        "LADYBOX",
        "LORDBOX",
        "BOXMICEDIM",
        "BOXMIWEWORK",
        "20BOXMI22!",
      ],
      isCouponValid: false,
      userData: {
        attributes: {
          subscription: {
            next_billing_date: "",
            summary: {},
          },
        },
      },
      summaryTotal: 0,
      additionalCharges: {},
      loading: false,
      pricesOptions: [],
      plansOptions: [],
    };
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = true;

    document.body.appendChild(script);

    axios_instance.get("/v2/product_v2s", {}).then((response) => {
      this.setState({ pricesOptions: response.data.data });
    });
    axios_instance.get("/v2/plans", {}).then((response) => {
      this.setState({ plansOptions: response.data.data });
    });
    const order = JSON.parse(sessionStorage.getItem("orderV2"));
    if(IsUserSignedIn()){
      const user = JSON.parse(localStorage.getItem("currentUser"));
      order.user_id = user.id
      sessionStorage.setItem("orderV2", JSON.stringify(order));
    }
    if (order) {
      this.setState({ orderV2: order });
    }
  }

  address() {
    const order = { ...this.state.orderV2 };
    return `${order.suburb} ${order.street} ${order["ext_number"]}, CP: ${order["postal_code"]}`;
  }
  selectedPlan() {
    const plan = this.state.plansOptions.find(
      (plan) => plan.id === this.state.orderV2.plan_id
    );
    if (plan) {
      if (plan.attributes["monthly-interval"] === 1) return "mes";
      return `${plan.attributes["monthly-interval"]} meses`;
    }
    return "";
  }
  selectedPrice() {
    const price = this.state.pricesOptions.find(
      (price) => price.id === this.state.orderV2.product_id
    );
    if (price) {
      return `${price.attributes["m2"]}  m²`;
    }
    return "";
  }
  deliveryDate() {
    if (this.state.orderV2.shipping_date) {
      return `${this.state.orderV2.shipping_date.substring(0, 10)} - ${moment(
        this.state.orderV2.shipping_date
      ).format("hh:mm")}`;
    }
    return "";
  }
  subscriptionInfo() {
    const price = this.state.pricesOptions.find(
      (price) => price.id === this.state.orderV2.product_id
    );
    const plan = this.state.plansOptions.find(
      (plan) => plan.id === this.state.orderV2.plan_id
    );
    if (!price) return "";
    const total = this.total();
    const discount =
      price.attributes["price"] *
      (plan?.attributes["discount-percentage"] / 100);
    return (
      <div className="d-flex flex-column" style={{ fontSize: "1.2em" }}>
        <div className="d-flex justify-content-between mb-0">
          <span>Producto ({this.selectedPrice()}):</span>
          <span>$ {price.attributes.price} MXN</span>
        </div>
        <div className="d-flex justify-content-between mb-0">
          <span>Descuento: </span>
          <span>- $ {discount.toFixed(2)} MXN</span>
        </div>
        <div className="d-flex justify-content-between mb-0">
          <span>Subtotal: </span>
          <span>$ {(total - total * 0.16).toFixed(2)} MXN</span>
        </div>
        <div className="d-flex justify-content-between mb-0">
          <span>IVA: </span>
          <span>$ {(total * 0.16).toFixed(2)} MXN</span>
        </div>
        <div className="border-top"></div>
        <div className="d-flex justify-content-end mb-0">
          <span>$ {(total/ this.monthlyInterval()).toFixed(2)} MXN x {this.monthlyInterval()}</span>
        </div>
        <div
          className="d-flex justify-content-between mb-0"
          style={{ fontSize: "1.6em" }}
        >
          <span>Suscripción: </span>
          <span>$ {total} MXN</span>
        </div>
        <div className="d-flex justify-content-between mb-0 text-secondary">
          <span>Envio: </span>
          <span>$ {price.attributes["freight-price"]} MXN</span>
        </div>
      </div>
    );
  }

  total() {
    const priceOption = this.state.pricesOptions.find(
      (price) => price.id === this.state.orderV2.product_id
    );
    const price = priceOption?.attributes?.price;
    const shipping = priceOption?.attributes["freight-price"];
    const plan = this.state.plansOptions.find(
      (plan) => plan.id === this.state.orderV2.plan_id
    );

    let discountPercentage = plan?.attributes["discount-percentage"];
    if (!discountPercentage) discountPercentage = 0;
    const discount = price * (discountPercentage / 100);
    const priceWDiscount = price - discount;
    const total = priceWDiscount * plan?.attributes["monthly-interval"];
    if (total) {
      return total.toFixed(2);
    }

    return null;
  }

  shipping() {
    const priceOption = this.state.pricesOptions.find(
      (price) => price.id === this.state.orderV2.product_id
    );
    const shipping = priceOption?.attributes["freight-price"];
    return shipping;
  }
  monthlyInterval() {
    const plan = this.state.plansOptions.find(
      (plan) => plan.id === this.state.orderV2.plan_id
    );
    return plan?.attributes["monthly-interval"];  }

  checkout() {
    const order = JSON.parse(sessionStorage.getItem("orderV2"));
    // console.log(order)
    axios_instance
      .post("/v2/order_v2s", { order_v2: order })
      .then((response) => {
        this.props.history.push("/customer_storable");
      });
  }
  goBack() {
    this.props.history.push("/quoting");
  }

  setCreditCard = async (card) => {
    await conektaHelper.initConekta();
    var order = {
      orderBoxes: this.state.orderBoxes,
      orderAdditionalItems: this.state.orderAdditionalItems,
      shipping: this.state.shipping,
      selectedDate: this.state.selectedDate,
      card: card,
      paymentInfo: {
        brand: conektaHelper.getCardBrand(card.number),
        short_number: card.number.substring(
          card.number.length - 4,
          card.number.length
        ),
        name: card.name,
        expiry_month: card.exp_month,
        expiry_year: card.exp_year,
      },
    };
    // this.saveToSessionStorage(order);
  };

  onChange = (event) => {
    const target = event.target;
    const value = target.value.toUpperCase();
    const name = target.name;
    this.setState({ [name]: value });
  };

  renderProducts = () => {
    var products = this.props.orderBoxes.map((product, key) => {
      var type = product.product_type === "box" ? "CAJA" : "OBJETO";
      return (
        <div key={`product-${key}`} className="receipt-item">
          <p>
            <span className="relative">
              {type}
              <span className="new-in-subscription">NUEVO</span>
            </span>
            <br />
            <span className="red-item">{product.size.toUpperCase()}</span>
          </p>
          <p>
            {product.quantity}
            <br />
            <span className="red-item">UNIDADES</span>
          </p>
          <p className="total">
            {ToCurrency(product.total)}
            <br />
            <span className="red-item">MENSUALES</span>
          </p>
        </div>
      );
    });
    return products;
  };

  renderSubscriprionTitle = (val) => {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (val === "summary") {
      return user && user.attributes.subscription["active-status"]
        ? "MI NUEVA SUSCIRPCIÓN"
        : "MI SUSCRIPCIÓN";
    } else {
      return user && user.attributes.subscription["active-status"]
        ? "MI NUEVA SUSCIRPCIÓN"
        : "MI SUSCRIPCIÓN";
    }
  };

  renderActualSubscription = () => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let summary = Object.values(currentUser.attributes.subscription.summary);
    let products = summary.map((product, key) => {
      return (
        <div key={`product-${key}`} className="receipt-item">
          <p>
            <span className="blue-item">{product.title.toUpperCase()}</span>
          </p>
          <p>
            {product.count}
            <br />
            <span className="blue-item">UNIDADES</span>
          </p>
          <p className="total">
            {ToCurrency(product.total)}
            <br />
            <span className="blue-item">MENSUALES</span>
          </p>
        </div>
      );
    });

    return products;
  };

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div className="main-container">
        <div className="container">
          <div className="row py-3 pb-5">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <PaymentSection
                    setcreditcard={this.setCreditCard}
                  ></PaymentSection>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="checkout-container pl-3 pl-md-0">
                <div
                  className="checkout-box btn-box"
                  style={{ marginRight: "1.5em" }}
                >
                  <div className="receipt-delivery align-center">
                    <span>
                      {this.renderSubscriprionTitle("summary")}
                      <Tooltip help="En caso de que tengas activa una suscripción anterior, los artículos marcados como 'NUEVO' se estarán añadiendo para formar una nueva suscripción." />
                    </span>
                    <img src={boxIcon} alt="box" />
                  </div>
                  <div className="receipt-items ">
                    {localStorage.getItem("currentUser")
                      ? `${this.selectedPlan()} - ${this.selectedPrice()}`
                      : ""}
                  </div>
                  <hr />
                  <div className="receipt-item">
                    <p style={{ fontSize: "1.5em" }}>
                      {this.renderSubscriprionTitle("total")}
                    </p>
                    <p style={{ fontSize: "1.5em", textAlign: "right" }}>
                      ${this.total()} cada {this.selectedPlan()}
                    </p>
                  </div>
                  <div className="receipt-item">{this.subscriptionInfo()}</div>
                  {this.state.userData.attributes.subscription
                    .next_billing_date ? (
                    <p>
                      Tu nueva suscripción empieza el dia
                      <span className="red-item ml-1">
                        {moment(
                          this.state.userData.attributes.subscription
                            .next_billing_date
                        ).format("DD-MMM-YY")}
                      </span>
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {this.state.userData.attributes.subscription
                    .next_billing_date ? (
                    <div className="receipt-items">
                      {this.renderAdditionalItems()}
                      <div className="receipt-item receipt-additional">
                        <p>{this.state.additionalCharges.message}</p>
                        <p style={{ textAlign: "right" }}>
                          {ToCurrency(
                            this.state.additionalCharges.to_be_charged
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {this.state.isCouponValid ? (
                    <div
                      style={{ padding: "0 1vw" }}
                      className="receipt-item red-box"
                    >
                      <p className="receipt-item-label">
                        <span>1 MES GRATIS</span>
                      </p>
                      <p className="receipt-item-value">
                        <img src={couponIcon} alt="coupon" />
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="checkout-box btn-box">
                  <div className="receipt-delivery">
                    <span>RECOLECCION</span>
                    <img src={truckIcon} alt="truck" />
                  </div>

                  <div className="receipt-address">
                    <div>
                      <img src={locationIcon} alt="location" />
                      <span className="receipt-address-text">
                        {this.address()}
                      </span>
                    </div>
                    <div>
                      <img src={calendarIcon} alt="calendar" />
                      <span className="receipt-address-text">
                        {" "}
                        {this.deliveryDate()}
                      </span>
                    </div>
                    {this.state.isCouponValid ? "" : <hr />}
                    {this.state.isCouponValid ? (
                      <div></div>
                    ) : (
                      <div>
                        <div>
                          <img src={couponIconk} alt="coupon" />
                          <span className="receipt-address-text">
                            CUPÓN DE DESCUENTO
                          </span>
                        </div>
                        <div className="coupon">
                          <input
                            type="text"
                            name="coupon"
                            value={this.state.coupon}
                            onChange={this.onChange}
                            className="coupon-input"
                          ></input>
                          <button
                            className="coupon-btn"
                            onClick={() => this.validateCoupon()}
                          >
                            Validar
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{ padding: "0", flex: "1 1 100%" }}
                  className="receipt-box pointer btn-box complete-order"
                  onClick={() => this.goBack()}
                >
                  <p className="receipt-action">
                    <strong>REGRESAR</strong>
                  </p>
                </div>
                {IsUserSignedIn() ? (
                  <div
                    style={{ padding: "0", flex: "1 1 100%" }}
                    className="receipt-box pointer btn-box complete-order"
                    onClick={() => this.checkout()}
                  >
                    <p className="receipt-action">
                      <strong>COMPLETAR ORDEN</strong>
                    </p>
                  </div>
                ) : (
                  <div
                    style={{ padding: "0", flex: "1 1 100%" }}
                    className="receipt-box pointer btn-box"
                    onClick={this.register}
                  >
                    <p className="receipt-action">
                      <strong>REGÍSTRATE</strong> PARA CONTINUAR
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
