import { BaseUrl, axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import DashboardMenu from "templates/common/dashboard-menu";
import { NotificationManager } from "react-notifications";
import StringResources from "components/string-resources";
import DashboardUserMenu from "templates/common/dashboard-user-menu";
import React from "react";
import boxIcon from "img/box-icon.svg";
import objectIcon from "img/object-icon.svg";
import SubscriptionBox from "components/subscription-box";
export default class SubscriptionBoxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      boxes: [
        {
          object_name: "",
          title: "",
          storable_type: "Box",
          boxmi_description: "Box",
          space: "",
          width: 0,
          height: 0,
          long: 0,
          attachments: [],
        },
      ],
      boxesToDelete: [],
      showModal: false,
      attachedFile: null,
      currentUser: {},
      isSubscriptionBoxEdit: false,
    };
    this.dashboardUserMenuElement = React.createRef();
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ currentUser: currentUser });
    const { userId } = this.props.match.params;
    if (userId) {
      showLoader();
      axios_instance
        .get("/v1/subscription_boxes", {
          params: {
            by_user: userId,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          const boxes = response.data.data.map((box) => {
            return {
              // box attributes
              box_id: box.id,
              object_name: box.attributes["object-name"] || "",
              width: box.attributes.width,
              height: box.attributes.height,
              long: box.attributes.long,
              title: box.attributes["title"] || "",
              space: box.attributes["space"],
              boxmi_description: box.attributes["boxmi-description"],
              storable_type: box.attributes["storable-type"],
              user_id: userId,
              attachments: box.attributes["boxmi-attachments"].map(
                (att) => att.url
              ),
            };
          });
          this.setState({ boxes });
        })
        .catch((e) => {})
        .finally(() => {
          hideLoader();
        });
    }
  }

  handleAddBox = () => {
    this.setState((prevState) => ({
      boxes: [
        ...prevState.boxes,
        {
          object_name: "",
          title: "",
          storable_type: "Box",
          boxmi_description: "",
          space: "",
          width: 0,
          height: 0,
          long: 0,
          attachments: [],
        },
      ],
    }));
  };

  handleRemoveBox = (index) => {
    const { boxes } = this.state;
    const newBoxes = [...boxes];
    const removed = newBoxes.splice(index, 1)[0];
    if (removed.box_id) {
      this.setState((prevState) => ({
        boxesToDelete: [...prevState.boxesToDelete, removed],
      }));
    }
    this.setState({ boxes: newBoxes });
  };

  handleChange = (index, event) => {
    const { name, value, files } = event.target;
    this.setState((prevState) => {
      const newBoxes = [...prevState.boxes];
      const updatedStorable = { ...newBoxes[index] };
      if (name === "file") {
        console.log(files);
        updatedStorable["attachments"] = files;
        updatedStorable.isFileAttached = true;
      } else {
        updatedStorable[name] = value;
      }
      newBoxes[index] = updatedStorable;
      return { boxes: newBoxes };
    });
  };

  updateBox = (newBox, index) => {
    const { boxes } = this.state;
    const updatedBoxes = [...boxes];
    updatedBoxes[index] = newBox;
    this.setState({ boxes: updatedBoxes });
  };
  handleShowModal = (index) => {
    const box = this.state.boxes[index];
    if (typeof box.attachments[0] === "string") {
      const file = `${BaseUrl}${box.attachments[0]}`;
      this.setState({ attachedFile: file, showModal: true });
    } else if (typeof box.attachments[0] === "object") {
      const file = URL.createObjectURL(box.attachments[0]);
      this.setState({ attachedFile: file, showModal: true });
    }
  };
  handleCloseModal = () => {
    this.setState({ attachedFile: null, showModal: false });
  };

  createSubscriptionBox = (box) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("subscription_box[title]", box.title);
      formData.append("subscription_box[storable_type]", box.storable_type);
      formData.append("subscription_box[space]", box.space);
      formData.append("subscription_box[width]", box.width);
      formData.append("subscription_box[height]", box.height);
      formData.append("subscription_box[long]", box.long);
      formData.append("subscription_box[user_id]", box.user_id);
      formData.append(
        "subscription_box[boxmi_description]",
        box.boxmi_description
      );
      // formData.append("subscription_box[attachments]", box.attachments[0]);
      const attachments = [...box.attachments].map((file) => file);
      attachments.forEach((file, index) => {
        formData.append(`subscription_box[boxmi_attachments][]`, file);
      });
      axios_instance
        .post("/v1/subscription_boxes", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          hideLoader();
        });
    });
  };
  editSubscriptionBox = (box) => {
    console.log(box);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("subscription_box[title]", box.title);
      formData.append("subscription_box[storable_type]", box.storable_type);
      formData.append("subscription_box[space]", box.space);
      formData.append("subscription_box[width]", box.width);
      formData.append("subscription_box[height]", box.height);
      formData.append("subscription_box[long]", box.long);
      formData.append("subscription_box[user_id]", box.user_id);
      formData.append(
        "subscription_box[boxmi_description]",
        box.boxmi_description
      );
      // formData.append("subscription_box[attachments]", box.attachments[0]);
      const attachments = [...box.attachments].map((file) => file);
      attachments.forEach((file, index) => {
        formData.append(`subscription_box[boxmi_attachments][]`, file);
      });
      axios_instance
        .put(`/v1/subscription_boxes/${box.box_id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          hideLoader();
        });
    });
  };

  deleteCustomerBox = (box) => {
    return new Promise((resolve, reject) => {
      axios_instance
        .delete(`/v1/subscription_boxes/${box.box_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          hideLoader();
        });
    });
  };

  submit = () => {
    showLoader();
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const userId = this.props.match.params.userId;
    const boxesToBeCreated = this.state.boxes.map((b) => {
      const box = { ...b, user_id: userId };
      const promise = box.box_id
        ? this.editSubscriptionBox(box)
        : this.createSubscriptionBox(box);
      return promise;
      // const promise = this.createSubscriptionBox({
      //   ...s,
      //   user_id: userId,
      // });
      // return promise;
    });
    const deletePromises = this.state.boxesToDelete.map((box) => {
      return this.deleteCustomerBox(box);
    });
    Promise.all([...boxesToBeCreated, ...deletePromises])
      .then((response) => {
        NotificationManager.success(
          StringResources.common.success,
          "Informacion guardada",
          3000
        );
        axios_instance
          .put(
            `/v2/order_v2s/${this.props.match.params.orderId}/pickup_storables`
          )
          .then((response) => {
            this.props.history.push(
              `/orders_v2/${this.props.match.params.orderId}`
            );
          })
          .catch((e) => {})
          .finally(() => {
            hideLoader();
          });

        // console.log(boxesToBeCreated)
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return (
      <div className="dashboard-container">
        {currentUser.attributes.role === "client" ? (
          <DashboardUserMenu ref={this.dashboardUserMenuElement} />
        ) : (
          <DashboardMenu />
        )}
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div className="d-flex justify-content-between">
                <span className="main-title">
                  Crea la lista de objetos a recolectar
                </span>
                {this.state.currentUser?.attributes?.role !== "client" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleAddBox()}
                  >
                    Agregar objeto
                  </button>
                )}
              </div>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body pt-4">
            <div>
              {this.state.boxes.map((box, index) => (
                <div className="mb-4" key={index}>
                  <SubscriptionBox
                    key={index}
                    box={box}
                    handleRemoveBox={() => this.handleRemoveBox(index)} // Pasar la función como prop
                    handleShowModal={() => this.handleShowModal(index)} // Pasar la función como prop
                    updateBox={(newBox) => this.updateBox(newBox, index)}
                  />
                </div>
              ))}
            </div>
            {this.state.currentUser?.attributes?.role !== "client" && (
              <div>
                <button
                  className="btn btn-primary my-2 ml-2 ml-md-0"
                  type="submit"
                  onClick={this.submit}
                >
                  {" "}
                  Confirmar
                </button>
              </div>
            )}
            {this.state.attachedFile && (
              <div
                className={`modal ${
                  this.state.showModal ? "d-block" : "d-none"
                }`}
                tabIndex="-1"
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">File Preview</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={this.handleCloseModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <img
                        className="w-100"
                        src={this.state.attachedFile}
                        alt="Preview"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
