import React from "react";
import LogoBoxmiFooter from 'img/logo-boxmi-footer.svg';
import PaymentMethods from 'img/iconosTarjeta.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Whatsapp from 'templates/whatsapp';

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Whatsapp />
        <div className="footer-social-container">
          <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/boxmi.mx"><FontAwesomeIcon icon={faFacebookF}  size="2x"/></a>
          <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/boxmi.mx"><FontAwesomeIcon icon={faInstagram}  size="2x"/></a>
          <a href="tel:8110039250"><FontAwesomeIcon icon={faPhone} size="2x"/></a>
        </div>
        <div className="footer-nav-container">
            <nav>
              <ul>
                <li><a target="_blank" href="/terminos">Políticas de Uso</a></li>
                <li><a target="_blank" href="/aviso">Aviso de Privacidad</a></li>
                <li className="mt-3">
                  <img src={PaymentMethods} alt="logo" height="20"/>
                </li>
              </ul>
            </nav>
            <nav>
              <ul>
                <li><a href="/">Inicio</a></li>
                <li><a href="/features">Features</a></li>
                <li><a href="/faq">FAQ</a></li>
                <li><a href="/contact">Contacto</a></li>
                <li><a href="/quoting">Cotiza</a></li>
              </ul>
            </nav>
        </div>
        <div className="footer-logo-container">
            <a href="/"><img src={LogoBoxmiFooter} alt="logo" /></a>
            <p className="mt-4">Todos los derechos reservados</p>
        </div>
      </footer>
    )
  }
}
