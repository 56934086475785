import React from "react";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import DashboardMenu from "templates/common/dashboard-menu";

export default class ProductEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product: {
        attributes: { m1: 0, m2: 0, price: 0, freight_price: 0 },
      },
      isNew: true,
    };
  }

  componentDidMount() {
    var product =
      this.props.location.state === undefined
        ? "new"
        : this.props.location.state[0];
    if (product === "new") {
      this.setState({
        isNew: true,
      });
    } else {
      showLoader();
      axios_instance
      .get(`/v2/product_v2s/${product.id}`)
      .then((response) => {
        const product = response.data.data
        product.attributes.freight_price = product.attributes['freight-price']
        this.setState({
          product: product,
          isNew: false,
          id: response.data.data.id,
        });
        console.log(this.state.product)
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
      this.setState({
        product: product,
        isNew: false,
        space_id: product.attributes.space_id,
        active_status: product["active-status"],
        id: product.id,
        box_type: product.attributes["product-type"],
      });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "active_status") {
      var val = value === true ? "active" : "inactive";
      this.setState({ [name]: val });
    } else {
      const product = {...this.state.product}
      product.attributes[name] = value
      this.setState({ product });
    }
  };

  submit = () => {
    showLoader();
    var params = {
      product_v2: {
        m1: this.state.product.attributes.m1,
        m2: this.state.product.attributes.m2,
        price: this.state.product.attributes.price,
        freight_price: this.state.product.attributes.freight_price,
      },
    };
    if (this.state.isNew) {
      axios_instance
        .post("/v2/product_v2s", params)
        .then((response) => {
          this.props.history.push("/admin/products", [response.data.data]);
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      axios_instance
        .put("/v2/product_v2s/" + this.props.match.params.id, params)
        .then((response) => {
          this.props.history.push("/admin/products");
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
        });
    }
  };

  delete = () => {
    showLoader();
    axios_instance
      .delete("/v2/product_v2s/" + this.props.match.params.id)
      .then((response) => {
        this.props.history.push("/admin/products");
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">
                Crear Producto
              </span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="row px-2">
              {/* <div className="col-3">
                <div className="form-group d-flex" style={{ flexDirection: "column" }}>
                  <span>Medida 1:</span>
                  <input
                    type="number"
                    value={this.state.product.attributes.m1}
                    name="m1"
                    onChange={this.handleChange}
                  />
                </div>
              </div> */}
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group d-flex" style={{ flexDirection: "column" }}>
                  <span>Metros cuadrados:</span>
                  <input
                    type="text"
                    value={this.state.product.attributes.m2}
                    name="m2"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group d-flex" style={{ flexDirection: "column" }}>
                  <span>Precio:</span>
                  <input
                    type="number"
                    value={this.state.product.attributes.price}
                    name="price"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group d-flex" style={{ flexDirection: "column" }}>
                  <span>Precio de Envio:</span>
                  <input
                    type="number"
                    value={this.state.product.attributes.freight_price}
                    name="freight_price"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-primary mr-2"
                type="submit"
                onClick={this.submit}
              >
                {" "}
                Confirmar
              </button>
              {!this.state.isNew && (
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.delete}
                >
                  {" "}
                  Eliminar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
