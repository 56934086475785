import React from "react";
import ReactTable from "react-table";

export default class CustomTable extends React.Component {
  render() {
    return (
      <ReactTable {...this.props}
        previousText="Anterior"
        nextText='Siguiente'
        loadingText='Cargando...'
        noDataText='No hay registros'
        pageText='Página'
        ofText='de'
        rowsText='registros'
        pageJumpText='saltar a página'
        rowsSelectorText='registros por página'
        
      />
    )
  }
}
