import React from "react";
import { axios_instance } from  'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { NotificationManager} from 'react-notifications';

import DashboardUserMenu from 'templates/common/dashboard-user-menu';
import AddressPicker from 'templates/common/address-picker';
import DatePicker from 'templates/common/date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Wallet from 'templates/users/wallet'

const EmptyLastOrder = {
  shipping_address: {},
  payment_info: {}
};

export default class EditOrder extends React.Component {
  constructor() {
    super()

    this.state = {
      lastOrder: EmptyLastOrder,
      date: "",
      user: {},
      shippingAddresses: [],
    }
  }
  componentDidMount() {
    if (this.props.location.state.length > 0) {
      let order_type = "cancel";
      if(this.props.location.state[2] === "warehouse")
        order_type = "return";
      else if(this.props.location.state[2] === "house")
        order_type = "pickup";
      let storables = [];
      this.props.location.state[1].map((storable) => {
        if(storable.attributes.selected)
          storables.push(storable);
        return storable;
      })

      const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      const lastOrder = this.props.location.state[0] || EmptyLastOrder;
      const user = this.props.location.state[3] || currentUser

      this.setState({
        lastOrder: lastOrder,
        storables: storables,
        order_type: order_type,
        user,
      });
    } else {
      showLoader();
      var currentUser = JSON.parse(localStorage.getItem("currentUser"))
      axios_instance.get('/v1/users/' + currentUser.id).then((resp) => {
        const lastOrder = resp.data.data.attributes['last-order'] || EmptyLastOrder;
        this.setState({
          lastOrder: lastOrder,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  saveShipping = (address) => {
    this.setState({lastOrder: { ...this.state.lastOrder, shipping_address: address}});
  }

  savePaymentMethod = (method) => {
    this.setState({lastOrder: { ...this.state.lastOrder, payment_info: method.paymentInfo, token_id: method.token_id}});
  }

  saveDate = (date) => {
    this.setState({date: date});
  }

  createOrder = () => {
    showLoader();
    if (this.state.date) {
      const products = this.state.storables.map((storable) => {
        return {
          id: storable.id,
          product_type: "SubscriptionBox"
        }
      });

      var order = {
        order: {
          shipping_address: this.state.lastOrder.shipping_address,
          shipping_date: this.state.date,
          products: products,
          payment_info: this.state.lastOrder.payment_info,
          order_type: this.state.order_type,
        }
      };
      if (this.state.lastOrder.token_id) {
        order.order["token_id"] = this.state.lastOrder.token_id;
      }

      const currentUser = JSON.parse(localStorage.getItem('currentUser'))

      if (currentUser.attributes.role === 'admin') {
        order.order.user_id = this.state.user.id
        order.user_id = this.state.user.id
      } else {
        order.order.user_id = currentUser.id
        order.user_id = currentUser.id
      }

      axios_instance.post('/v1/checkout', order).then((response) => {
        this.props.history.push('/users')
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      hideLoader();
      NotificationManager.error("Favor de escoger una FECHA", "Error");
    }
  }

  render() {
    var {shipping_address} = this.state.lastOrder;
    var user = this.props.location.state[3] || JSON.parse(localStorage.getItem('currentUser'))
    return (
      <div className="dashboard-container">
        <DashboardUserMenu />
        <div className="user-dashboard-container no-margin">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <div>
            <div className="flex-column">
              <div className="flex-column">
                <span className="main-subtitle">DASHBOARD / <span className="red-item">ENVÍOS</span></span>
                <span style={{alignSelf: 'inherit'}} className="main-title">LO LLEVAMOS <br /> CUANDO QUIERAS</span>
              </div>
              <div className="">
                <AddressPicker shippingAddress={shipping_address} save={this.saveShipping} user={user}/>

                <div style={{fontFamily: 'Oswald, sans-serif', flex: '1 1 100%' }} className="flex-column">
                  <div style={{padding: '1em 0 1em 0'}} className="flex-row">
                    <span style={{fontWeight: 'bold'}} className="red-item"> 02&nbsp; </span>
                    <span style={{fontWeight: 'bold'}}> MÉTODO DE PAGO </span>
                  </div>
                  <Wallet user={this.props.location.state[3] || {}} />
                </div>
              </div>
              <div className="flex-row">
                <div style={{padding: '1em 0 1em 0'}} className="flex-row">
                  <span style={{fontWeight: 'bold'}} className="red-item"> 03&nbsp; </span>
                  <span style={{fontWeight: 'bold'}}> FECHA </span>
                </div>
              </div>
              <DatePicker save={this.saveDate}/>
              <div style={{justifyContent: 'center'}} className="flex-row">
                <div style={{padding: "0"}} className="pointer btn-box confirm-order-btn" onClick={this.createOrder}>
                  <span className="confirm-order-btn-text">ACTUALIZAR ORDEN</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
