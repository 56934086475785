import React from "react";
import { IsUserSignedIn, GoToUserDashboard } from 'components/session';
import { findGetParameter } from 'components/utils';
import { NotificationManager} from 'react-notifications';
import UserIcon from 'img/signin-user-icon.svg';
import PassIcon from 'img/signin-password-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { validations } from 'components/validations';
import { axios_instance } from 'components/axios';
import { loadHeader } from 'templates/header';
import { showLoader, hideLoader } from 'components/loading-spinner';

export default class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {}
    }
  }

  componentDidMount() {
    if(IsUserSignedIn()) {
      NotificationManager.info("Ya has iniciado sesión.", "");
      this.props.history.push(GoToUserDashboard());
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  signIn = (event) => {
    event.preventDefault();

    let params = {
      email: this.state.email,
      password: this.state.password,
    };
    var errors = validations(params)
    this.setState({errors: errors});
    errors = Object.keys(errors).some(x => errors[x])
    if (errors) {
      NotificationManager.error("", "Favor de llenar los datos para iniciar sesión");
    } else {
      showLoader();
      axios_instance.post('/auth/sign_in', params)
      .then((response) => {
        localStorage.setItem("headers", JSON.stringify(response.headers));
        localStorage.setItem("currentUser", JSON.stringify(response.data.data));
        loadHeader();

        let redirect = findGetParameter('redirect');
        if(redirect === "quotes") {
          redirect = `quotes/${findGetParameter('id')}`;
        }
        this.props.history.push(redirect || GoToUserDashboard());
      })
      .catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  goToRecover = (event) => {
    this.props.history.push('/recover_password');
  }

  goToRegister = (event) => {

      const pathname = window.location.pathname;
      let redirect = findGetParameter('redirect');
      if(redirect === null) {
        redirect = 'users';
      }
      if(pathname === '/quoting') {
        redirect = 'quoting';
      }
      if(redirect === "quotes") {
        redirect = `quotes/${findGetParameter('id')}`;
      }
      this.props.history.push(`/register?redirect=${redirect}`);
  }

  toggleTab = (tab) => {
    this.setState({
      activeTab: tab
    })
  }
  render() {
    return (
      <div className="session-container">
        <div className="flex-column">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <p className="session-title">Ingresa</p>
          <form onSubmit={this.signIn} className="flex-column">
            <div style={{alignItems: 'center'}} className="flex-column">
              <div className="form-group-sign-in">
                <img style={{height: 'fit-content', marginRight: '1em'}} src={UserIcon} alt="user" />
                <input type="email" className={(this.state.errors.email ? "form-control bigger-input error" : "form-control bigger-input")} id="email" name="email" onChange={this.handleInputChange} placeholder="Correo Electrónico" autoComplete="username" />
              </div>
              <div className="form-group-sign-in sign-in-pass">
                <img style={{height: 'fit-content', marginRight: '1em'}} src={PassIcon} alt="user" />
                <input type="password" className={(this.state.errors.password ? "form-control bigger-input error" : "form-control bigger-input")} id="password" name="password" onChange={this.handleInputChange} placeholder="Contraseña" autoComplete="current-password" />
              </div>
            </div>
            <div className="obt-legal obt-legal-sign-in">
              <label className="pointer" onClick={this.goToRegister}>
                <span style={{color: "#19083A"}}>¿Aún no tienes cuenta?</span>&nbsp;<span> Regístrate aquí</span>
              </label>
              <br />
              <label className="pointer" onClick={this.goToRecover}>
                <span style={{color: "#19083A"}}>¿Olvidaste tu contraseña?</span>&nbsp;<span> Recupérala aquí</span>
              </label>
            </div>
            <button type="submit" className="sign-in-btn" onClick={this.signIn}><strong>Ingresar</strong></button>
          </form>
        </div>
      </div>
    )
  }
};
