import React from "react";
import { IsUserSignedIn, SignOut } from "components/session";
import { findGetParameter } from "components/utils";
import QrModal from "templates/modals/qr-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import LogoBoxmi from "img/logo-boxmi-header.svg";
import LogoBoxmiMobile from "img/logo-boxmi-header-mobile.svg";
import arrowDown from "img/arrow-down.svg";
import orderIcon from "img/order-ico-01.svg";
import contactIcon from "img/faq-icon.svg";
import "css/landing-styles.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min.js";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

class LoaderManager {
  _defaultLoader = null;
  register(_ref) {
    if (!this._defaultLoader && "_id" in _ref) {
      this._defaultLoader = _ref;
    }
  }
  unregister(_ref) {
    if (!!this._defaultLoader && this._defaultLoader._id === _ref._id) {
      this._defaultLoader = null;
    }
  }
  getDefault() {
    return this._defaultLoader;
  }
}

let loaderManager = new LoaderManager();

export function loadHeader() {
  const ref = loaderManager.getDefault();
  if (!!ref) {
    ref.loadHeader();
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styles: {
        menuBar: "",
      },
      qrModal: {
        visibility: false,
        type: "search",
      },
      currentLabel: "Escanea codigo QR",
      isUserSignedIn: IsUserSignedIn(),
    };

    if (!this._id) this._id = srid();
  }

  handleScroll = () => {
    var documentScroll = $(document).scrollTop();
    if (documentScroll >= 30) {
      $(".navbar").addClass("bg-white");
    } else {
      $(".navbar").removeClass("bg-white");
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    $("#exampleModal").on("hidden.bs.modal", () => {
      const src = "https://www.youtube.com/embed/EgKy5VhGxrg";
      $("#boxmiPlayer").attr("src", "");
      $("#boxmiPlayer").attr("src", src);
    });
    $("#exampleModal").on("shown.bs.modal", () => {
      const src = "https://www.youtube.com/embed/EgKy5VhGxrg?autoplay=1";
      $("#boxmiPlayer").attr("src", src);
    });
    loaderManager.register(this);
  }

  componentWillUnmount() {
    loaderManager.unregister(this);
    window.removeEventListener("scroll", this.handleScroll);
  }

  loadHeader = () => {
    var isSignedIn = IsUserSignedIn();
    if (!isSignedIn) {
      this.props.history.push("/sign_in");
    }
    this.setState({
      isUserSignedIn: isSignedIn,
    });
  };

  GoToSignIn = () => {
    const pathname = window.location.pathname;
    let redirect = findGetParameter("redirect");
    if (redirect === null) {
      redirect = "users";
    }
    if (pathname === "/quoting") {
      redirect = "quoting";
    }
    this.props.history.push(`/sign_in?redirect=${redirect}`);
  };

  userActions = () => {
    if (IsUserSignedIn()) {
      const { subscription } = this.props;
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));

      if (
        currentUser &&
        (currentUser.attributes.role === "admin" ||
          currentUser.attributes.role === "operator")
      ) {
        return (
          <React.Fragment>
            <li className="nav-item">
              <a className="nav-link" href="/dashboard">
                Dashboard
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/store_boxes">
                Almacenar
              </a>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className="link-button nav-link"
                onClick={this.openQrModal}
              >
                Buscar
              </button>
            </li>
            <li
              className="nav-item nav-item-login"
              onClick={() => SignOut(this.props.history)}
            >
              <button className="link-button nav-link">Cerrar Sesión</button>
            </li>
            <li className="admin-dashboard-menu">
              <div>
                <a href="/dashboard" className="dashboard-menu-item">
                  <img
                    src={orderIcon}
                    alt="order"
                    className="dashboard-menu-item-icon"
                  />
                  <p>Inicio</p>
                  <div className="dashboard-menu-item-arrow">
                    <img height="10vh" src={arrowDown} alt="arrow-down" />
                  </div>
                </a>
                <a href="/orders_v2" className="dashboard-menu-item">
                  <img
                    src={orderIcon}
                    alt="order"
                    className="dashboard-menu-item-icon"
                  />
                  <p>Órdenes V2</p>
                  <div className="dashboard-menu-item-arrow">
                    <img height="10vh" src={arrowDown} alt="arrow-down" />
                  </div>
                </a>
                {currentUser.attributes.role === "admin" && (
                  <a href="/admin/products" className="dashboard-menu-item">
                    <img
                      style={{ width: "15%" }}
                      src={contactIcon}
                      alt="order"
                      className="dashboard-menu-item-icon"
                    />
                    <p>PRODUCTOS</p>
                    <div className="dashboard-menu-item-arrow">
                      <img height="10vh" src={arrowDown} alt="arrow-down" />
                    </div>
                  </a>
                )}
                {currentUser.attributes.role === "admin" && (
                  <a href="/admin/plans" className="dashboard-menu-item">
                    <img
                      style={{ width: "15%" }}
                      src={contactIcon}
                      alt="order"
                      className="dashboard-menu-item-icon"
                    />
                    <p>PLANES</p>
                    <div className="dashboard-menu-item-arrow">
                      <img height="10vh" src={arrowDown} alt="arrow-down" />
                    </div>
                  </a>
                )}
                <a href="/admin/users" className="dashboard-menu-item">
                  <img
                    src={orderIcon}
                    alt="order"
                    className="dashboard-menu-item-icon"
                  />
                  <p>Usuarios</p>
                  <div className="dashboard-menu-item-arrow">
                    <img height="10vh" src={arrowDown} alt="arrow-down" />
                  </div>
                </a>
                {/* <a href="/storage-items" className="dashboard-menu-item">
                  <img
                    src={orderIcon}
                    alt="order"
                    className="dashboard-menu-item-icon"
                  />
                  <p>OBJETOS</p>
                  <div className="dashboard-menu-item-arrow">
                    <img height="10vh" src={arrowDown} alt="arrow-down" />
                  </div>
                </a> */}
                {/* <a href="/boxes" className="dashboard-menu-item">
                  <img
                    src={orderIcon}
                    alt="order"
                    className="dashboard-menu-item-icon"
                  />
                  <p>CAJAS</p>
                  <div className="dashboard-menu-item-arrow">
                    <img height="10vh" src={arrowDown} alt="arrow-down" />
                  </div>
                </a> */}
                {/* <a href="/admin/contact-forms" className="dashboard-menu-item">
                  <img
                    style={{ width: "15%" }}
                    src={contactIcon}
                    alt="order"
                    className="dashboard-menu-item-icon"
                  />
                  <p>CONTACTOS</p>
                  <div className="dashboard-menu-item-arrow">
                    <img height="10vh" src={arrowDown} alt="arrow-down" />
                  </div>
                </a> */}
              </div>
            </li>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <li className="nav-item">
              <a className="nav-link" href="/users">
                Dashboard
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/features">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/faq">
                FAQ
              </a>
            </li>
            {!subscription?.id && (
              <li className="nav-item nav-item-quote">
                <a className="nav-link" href="/quoting">
                  Cotizar
                </a>
              </li>
            )}
            <li
              className="nav-item nav-item-login"
              onClick={() => SignOut(this.props.history)}
            >
              <button className="link-button nav-link">Cerrar Sesión</button>
            </li>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <li className="nav-item">
            <a className="nav-link" href="/features">
              Features
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/faq">
              FAQ
            </a>
          </li>
          <li className="nav-item nav-item-quote">
            <a className="nav-link" href="/quoting">
              Cotizar
            </a>
          </li>
          <li className="nav-item nav-item-login pointer">
            <span
              className="nav-link"
              href=""
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              onClick={this.GoToSignIn}
            >
              Regístrate / Ingresa
            </span>
          </li>
        </React.Fragment>
      );
    }
  };

  openQrModal = () => {
    this.setState({ qrModal: { type: "search", visibility: true } });
  };

  closeQrModal = () => {
    this.setState({ qrModal: { type: "search", visibility: false } });
  };

  search = (path) => {
    this.props.history.push(path);
  };
  render() {
    return (
      <nav className="navbar navbar-expand-sm navbar-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={LogoBoxmiMobile} alt="logo" className="showMobile" />
            <img src={LogoBoxmi} alt="logo" className="hideMobile" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  Inicio <span className="sr-only">(current)</span>
                </a>
              </li>
              {this.userActions()}
              <ul className="navbar-social m-auto">
                <li className="nav-item sn-i">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://facebook.com/boxmi.mx"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className="nav-item sn-i">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/boxmi.mx"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li className="nav-item sn-i">
                  <a className="nav-link" href="tel:8110039250">
                    <FontAwesomeIcon icon={faPhone} />{" "}
                    <span className="hideMobile ml-1">(+52) 811-003-9250</span>
                  </a>
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <QrModal
          display={this.state.qrModal}
          closeModal={this.closeQrModal}
          search={this.search}
          label={this.state.currentLabel}
        />
      </nav>
    );
  }
}

// Función para mapear el estado a las props del componente
const mapStateToProps = (state) => {
  return {
    subscription: state.subscription, // Suponiendo que la suscripción se encuentra en el estado con la propiedad "subscription"
  };
};

export default connect(mapStateToProps)(Header);
