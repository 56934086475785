import React from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

class GoBackComponent extends React.Component {
  render() {
    return (
      <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
    )
  }
}

const GoBack = withRouter(GoBackComponent);

export default GoBack;
