import React from 'react';

export default class Terms extends React.Component {
  render() {
    return(
      <div className="common-container">
        <h2>Términos y Condiciones</h2>
        <p>
        CONTRATO DE SERVICIOS DE ALMACENAMIENTO (EL “CONTRATO”) QUE CELEBRAN EL CLIENTE (SEGÚN SE DEFINE EN LA CARÁTULA) Y BOXMI, S.A.P.I. DE C.V. (“BOXMI” Y CONJUNTAMENTE CON EL CLIENTE, LAS “PARTES”); QUIENES SUJETAN ESTE CONTRATO AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS.
        </p>
        <h3>PRIMERA. DEFINICIONES. </h3>
        <p>
        Los conceptos que inicien en mayúsculas tendrán los significados atribuidos a éstos en el contenido de este Contrato y de acuerdo a las siguientes definiciones:
        </p>
        <p>
        “Artículos” significa todos y cada uno de los artículos identificados en la Sección 2 de la Carátula. Así mismo, el Cliente podrá realizar modificaciones a los artículos identificados en la Sección 2 de la Carátula de las siguientes formas: actualizando el listado a través de la Plataforma o mediante comunicación por correo electrónico (en ambos casos, previo aceptación por escrito de esto por parte de Boxmi). En esta situación, el listado actualizado de Artículos se considerarán como si fuesen insertados en la Sección 2 de la Carátula.
        </p>
        <p>
        “Aviso de Privacidad” significa el aviso de privacidad de Boxmi, el cual se puede consultar en la Plataforma.
        </p>
        <p>
        “Boxmi” se refiere a la sociedad mercantil denominada Boxmi, S.A.P.I. de C.V.
        </p>
        <p>
        “Caja Sellada” significa aquella caja que se entrega a Boxmi completamente sellada y con mecanismos de seguridad suficientes para evitar el acceso no autorizado al contenido de la misma, incluyendo aquel que pudiera tener Boxmi. En este acto, las Partes reconocen que las cajas a las que se hace referencia en este párrafo son propiedad exclusiva de Boxmi.
        </p>
        <p>
        “Carátula” significa la parte del presente Contrato identificada con el título “Carátula del Contrato”.
        </p>
        <p>
        “Clave de Acceso” significa la contraseña creada por el Cliente como parte del proceso de creación de una Cuenta de Usuario en la Plataforma.
        </p>
        <p>
        “Contrato” significa el presente Contrato.
        </p>
        <p>
        “Cuenta de Usuario” significa el registro que lleve a cabo el Cliente bajo la Plataforma, mediante el cual capturará sus Datos Personales y otra información confidencial y será utilizado para establecer comunicaciones de mensajes de datos con Boxmi.
        </p>
        <p>
        “Datos Personales” significa cualquier información concerniente a la identificación del Cliente.
        </p>
        <p>
        “Domicilio de Entrega-Recepción” significa el domicilio que señale el Cliente y que se indica en la Sección 4.1 de la Carátula en donde se deberán de recoger y entregar los Artículos, mismo que deberá de ubicarse necesariamente en el área metropolitana de Monterrey, Nuevo León. Así mismo, será considerado como el Domicilio de Entrega-Recepción alternativo aquel registrado como tal en la Plataforma.
        </p>
        <p>
        “Guía del Límite de Responsabilidad de Boxmi” significa los lineamientos a seguirse en caso de que se actualicen los supuestos bajo la Cláusula Quinta de este Contrato, según dicha guía se describe en el Anexo 1 de este Contrato.
        </p>
        <p>
        “Guía para Almacenamiento de Artículos de Boxmi” significa los lineamientos con los que deben de cumplir los Artículos, según dicha guía se describe en el Anexo 2 de este Contrato.
        </p>
        <p>
        “Intereses Moratorios” significa los intereses generados por mora en el pago en términos de la Sección 3.3 de este Contrato.
        </p>
        <p>
        “Plataforma” significa el portal web “[*]”, así como todas las páginas y direcciones “URL” derivadas o dependientes de éste y cualquier otra aplicación, software, portal, sitio web y demás medios tecnológicos por medio de los cuales Boxmi ofrezca sus servicios al público.
        </p>
        <p>
        “Sección” significa una sección en particular de la Carátula o el Contrato.
        </p>
        <p>
        “Servicios” significa aquellos servicios descritos en Sección 2.1 de este Contrato.
        </p>
        <p>
        “Sitio” significa el espacio físico en posesión de Boxmi en el cual se almacenarán los Artículos.
        </p>
        <p>
        “Subsección” significa una subsección en particular de una Sección de la Carátula o el Contrato.
        </p>
        <p>
        “Tarifa de Almacenamiento” significa la Tarifa descrita como tal en el Anexo 3 de este Contrato.
        </p>
        <p>
        “Tarifa de Transporte” significa la Tarifa descrita como tal en el Anexo 3 de este Contrato.
        </p>
        <p>
        “Tarifas” significa conjuntamente la Tarifa de Almacenamiento y la Tarifa de Transporte.
        </p>
        <p>
        “Términos y Condiciones” significa el apartado de cláusulas del Contrato.
        </p>
        <h3>SEGUNDA. SERVICIOS. </h3>
        <h4>2.1. Descripción de los Servicios</h4>
        <p>(a) Las Partes acuerdan que Boxmi prestará a favor del Cliente y sujeto al pago de las Tarifas aplicables, los servicios de almacenamiento en términos de este Contrato y con el objeto de almacenar en el Sitio los Artículos (los “Servicios”).
        </p>
        <p>
        (b) Boxmi se obliga a almacenar los Artículos en un espacio cerrado, a temperatura ambiente, con vigilancia, restringiendo el acceso al personal de Boxmi a los artículos (salvo en aquellas ocasiones que sean necesario para la ejecución del presente Contrato) [incluir otras condiciones del Sitio e.g. humedad, en contacto con otros artículos?, etc.] durante la Vigencia.
        </p>
        <p>Los Servicios no incluyen ningún tipo de mantenimiento, cuidado, refrigeración o trato especial de los Artículos, por lo que los mismos serán almacenados en términos de la presente Sección.
        </p>
        <p>
        (c) Sujeto a los horarios indicados en la Plataforma, la disponibilidad del personal de Boxmi o bien, a cualquier otro mensaje en contrario por parte de un representante de Boxmi, éste se obliga a transportar los Artículos del Domicilio de Entrega-Recepción al Sitio para su debido almacenamiento. Asimismo, sujeto a las condicionantes previamente citadas y ante la solicitud expresa del Cliente, la conclusión de la Vigencia o cualquier otra causa por la cual termine anticipadamente este Contrato, Boxmi se obliga a transportar los Artículos del Sitio hasta el Domicilio de Entrega-Recepción. Lo establecido en la presente Subsección “(c)” se encuentra sujeto al debido pago por parte del Cliente de las Tarifas aplicables.
        </p>
        <h4>2.2. Artículos</h4>
        <p>
        (a) El Cliente garantiza y se obliga a que los Artículos cumplen debidamente con la Guía para Almacenamiento de Artículos de Boxmi, cuyo texto se incluye como Anexo 2 al presente Contrato. En ese sentido, siempre y cuando se le entregue a Boxmi los Artículos mediante Caja Sellada, Boxmi no tendrá acceso a la misma, salvo por cualquier caso fortuito o fuerza mayor o bien, que exista sospecha fundada y a discreción de Boxmi, de que los Artículos no cumplen con la Guía para Almacenamiento de Artículos de Boxmi, en cuyo caso Boxmi tendrá derecho a examinar los Artículos contenidos en la Caja Sellada y en su caso proceder conforme a su derecho convenga en términos de este Contrato.
        </p>
        <p>
        (b) Boxmi se compromete a entregar los Artículos en el Domicilio de Entrega-Recepción, en su caso, en las mismas condiciones en las que fueron recibidos, salvo por el deterioro ordinario por el paso del tiempo y el almacenamiento de los Artículos. Dichas condiciones de entrega se deberán de documentar en la Sección 5 de la Carátula, así como por medio de cualquier otra representación gráfica que en su caso se pudiera capturar de la Caja Sellada o los Artículos al momento de recepción.  No obstante lo anterior, en caso de que los Artículos sean entregados en Caja Sellada, Boxmi no será responsable de cualquier daño o perjuicio a la integridad física de los Artículos contenidos dentro de la misma.
        </p>
        <p>
        (c) El Cliente no tendrá derecho a acceder al Sitio o extraer los Artículos del Sitio por su propia cuenta, a no ser que cuente con la autorización expresa de Boxmi. En caso de que el Cliente desee tener acceso a los Artículos, deberá de seguir los protocolos indicados para tal efecto en la Plataforma y/o comunicarse con Boxmi en los términos de la Cláusula Octava de este Contrato.
        </p>
        <h4>2.3. Devolución de los Artículos</h4>
        <p>
        (a) El Cliente tendrá en todo momento derecho a solicitar la devolución de todos o parte de los Artículos a través de los medios acordados en la Cláusula Octava de este Contrato y, sujeto al pago de las Tarifas aplicables y a la disponibilidad del personal de Boxmi, ésta deberá entregarlos en el Domicilio de Entrega-Recepción.
        </p>
        <p>(b) En tanto no se haga el pago de la Tarifa aplicable y se liquide cualquier otra cantidad adeudada bajo la Cláusula Tercera de este Contrato a cargo del Cliente, Boxmi no tendrá la obligación de entregar los Artículos. En el caso descrito en el presente párrafo, se continuarán generando las tarifas aplicables, así como los Intereses Moratorios y demás penas convencionales que apliquen.
        </p>
        <h4>2.4. Condiciones de Uso de la Plataforma</h4>
        <p>
        (a) El Cliente manifiesta que en todo momento quien utilizará la Plataforma bajo su Cuenta de Usuario será él mismo o su representante con facultades. En ningún supuesto permitirá el uso de la Plataforma a un menor o cualquier otra persona con incapacidad legal.
        </p>
        <p>
        (b) El Cliente se responsabiliza y manifiesta bajo protesta de decir verdad que todos y cada uno de los Datos Personales y demás información que ha ingresado a su Cuenta de Usuario son veraces.
        </p>
        <p>
        (c) El Cliente desde este momento expresa su conformidad en que Boxmi conserve en las bases de datos de la Plataforma los datos de tarjeta de crédito o débito que el Cliente ingrese y, autoriza a Boxmi para realizar cargos automáticos a dicha tarjeta de crédito o débito de las Tarifas aplicables y demás cargos o penalizaciones aplicables en términos de la Cláusula Tercera de este Contrato.
        </p>
        <p>
        (d) La Plataforma es propiedad de Boxmi por lo que el Cliente no deberá y no deberá permitir o facilitar a cualquier otra persona a copiar, descompilar, realizar ingeniería inversa, desmontar, alterar o reducir cualquier componente de la Plataforma y, no deberá proporcionar a terceros en el sentido más amplio cualquier elemento, componente o información relacionada con la Plataforma, incluyendo su Clave de Acceso. Asimismo, el Cliente se obliga notificar a Boxmi de cualquier situación que le cause o que le pueda causar perjuicio a Boxmi.
        </p>
        <p>
        (e) El Cliente creará su Clave de Acceso para que éste acceda a la Plataforma. Será responsabilidad del Cliente conservar y salvaguardar la Clave de Acceso por lo que se obliga a responder a Boxmi de cualquier daño y perjuicio que el mal uso, error o negligencia en el tratamiento de la Clave de Acceso le cause a Boxmi.
        </p>
        <h4>2.5. Naturaleza Jurídica de los Servicios</h4>
        <p>
        (a) Las Partes acuerdan que el presente es un contrato de prestación de servicios de almacenamiento y por ningún motivo se podrá considerar que Boxmi se encuentra arrendado o subarrendado el Sitio o cualquier otro espacio. En esa inteligencia, el Cliente no adquiere por este medio derecho alguno sobre el Sitio.
        </p>
        <p>
        (b) En este acto, se reconoce que Boxmi no adquiere carácter de depositario de acuerdo a los artículos 2410 al 2439 del Código Civil para el Estado de Nuevo León. Por lo mismo, Boxmi no tendrá la obligación alguna de depositario, incluyendo sin limitar: ejecutar el cobro de intereses por Artículos que los generen, realizar cualquier acto para que los Artículos conserven su valor, dar avisos al Cliente sobre cualquier afectación de los Artículos, entre otros. Por lo mismo, Boxmi únicamente adquiere las obligaciones contraídas explícitamente en este Contrato.
        </p>
        <h3>TERCERA. TARIFAS Y OTROS PAGOS.</h3>
        <h4>3.1. Tarifa de Almacenamiento</h4>
        <p>
        (a) El Cliente se obliga a pagar a Boxmi, de forma mensual y por adelantado, dentro de los primeros 5 (cinco) días naturales de cada mes, la Tarifa de Almacenamiento.
        </p>
        <p>
        (b) El Cliente autoriza a Boxmi para que realice los cargos automáticos del pago de la Tarifa de Almacenamiento a la tarjeta de crédito o débito del Cliente que se encuentre vigente y haya ingresado en la Cuenta de Usuario. Dicha autorización se hace extensiva a cualquier otra Tarifa, pena convencional, o monto adeudado a Boxmi bajo el presente Contrato.
        </p>
        <p>
        (c) El pago de la Tarifa de Almacenamiento incluye el almacenamiento mensual, así como el traslado del Objeto del domicilio del Cliente (subsección 4.2 de la Carátula) al Sitio y del Sitio al domicilio del Cliente (subsección 4.2 de la Carátula) por una sola vez.
        </p>
        <h4>3.2. Tarifa de Transporte</h4>
        <p>
        (a) El Cliente se obliga a pagar a Boxmi la Tarifa de Transporte cada vez que requiera que se transporte cualquiera de los Artículos.
        </p>
        <p>(b) El Cliente autoriza a Boxmi para que realice los cargos automáticos del pago de la Tarifa de Transporte a la tarjeta de crédito del Cliente que se encuentre vigente y haya ingresado en la Cuenta de Usuario.
        </p>
        <h4>3.3. Intereses Moratorios y Retención de Artículos</h4>
        <p>
        (a) En este acto, las Partes pactan un interés moratorio del [*]% ([*] mensual). En su caso, esta tasa se aplicará sobre saldos insolutos y será exigible a partir de que el Cliente incumpla con la obligación de pago que corresponda; lo anterior, tomando en cuenta que la mora se contará y computará desde el primer día posterior al incumplimiento sin necesidad de requerimiento o interpelación alguna por parte de Boxmi. El interés que corresponda se calculará cada mes o fracción de mes mientras dure la mora durante todo el tiempo transcurrido desde la fecha en que venció la obligación de pago hasta la fecha en la que se realice efectivamente el pago.
        </p>
        <p>
        (b) El Cliente reconoce expresamente el derecho de Boxmi de retener los Artículos en caso de que exista cualquier adeudo por cualquier concepto derivado del presente Contrato a cargo del Cliente. En este caso, los pagos de las Tarifas se seguirán generando de la misma manera, hasta que el Cliente haga el pago total de lo adeudado, incluyendo sus intereses correspondientes.
        </p>
        <h3>CUARTA. RESPONSABILIDAD DEL CLIENTE. </h3>
        <p>
        El Cliente adquiere total responsabilidad por los Artículos y por cualquier incumplimiento a la Guía para Almacenamiento de Artículos de Boxmi. En ese entendido, el Cliente se obliga a sacar en paz y a salvo e indemnizar (en caso de que sea aplicable) a Boxmi de cualquier controversia y/o situación que se suscite en su contra derivado de la ilicitud o procedencia ilícita de los Artículos, o por incumplimiento de los lineamientos establecidos en la Guía para Almacenamiento de Artículos de Boxmi. Así mismo, el Cliente se obliga a indemnizar y a sacar en paz y a salvo a Boxmi de cualquier reclamación hecha por un tercero a Boxmi que se relacione, directa o indirectamente con los Artículos.
        </p>
        <h3>QUINTA. RESPONSABILIDAD DE BOXMI. </h3>
        <p>
        (a) El Cliente, en este acto, asume de forma personal todos los riesgos y peligros de pérdida, daño, robo o cualquier otra que afecte a los Artículos, liberando a Boxmi de cualquier responsabilidad que pueda suscitarse, incluyendo sin limitar: desaparición, incendio, inundación, daño por cualquier animal, vandalismo, enmohecimiento, moldeo, entre otros.
        </p>
        <p>
        (b) Boxmi no será responsable por daños ocasionados a los Artículos en ninguna circunstancia, a excepción de que medie dolo o negligencia por parte de Boxmi. En caso de que se actualice la situación descrita al inicio de este inciso, se aplicará lo establecido en la Guía del Límite de Responsabilidad de Boxmi.
        </p>
        <p>
        (c) Boxmi no ofrece ningún tipo de garantía para la integridad y/o bienestar de los Artículos, incluyendo sin limitar de su seguridad. En este acto, se acuerda que Boxmi no realizó, ni directa ni indirectamente ninguna manifestación o recomendación al Cliente respecto a la idoneidad de los Servicios.
        </p>
        <p>
        (d) El Cliente podrá solicitar a Boxmi que asegure los Artículos con una compañía aseguradora ajena al presente Contrato, de acuerdo a la póliza de seguro que se incluye como Anexo 4 del presente Contrato. En este caso, el Cliente deberá hacer un pago adicional mensual a Boxmi, equivalente al [*]% ([*] por ciento) de la Cuota por Artículo, por cada artículo que requiera asegurar.
        </p>
        <h3>SEXTA. VIGENCIA. </h3>
        <h4>6.1. Vigencia Inicial.</h4>
        <p>
        El presente Contrato iniciará su vigencia en la fecha establecida en la sección 3.1 de la Carátula, y tendrá una vigencia indefinida  (la “Vigencia”).
        </p>
        <h4>6.2. Condiciones adicionales</h4>
        <p>
        (a) La fecha de Terminación de Vigencia referida en la subsección 3.2 de la Carátula, en todo caso, se prorrogará hasta el día en que efectivamente el Cliente haya recibido en su domicilio los Artículos en su totalidad.
        </p>
        <p>
        (b) Para la devolución de los Artículos, el Cliente deberá pagar por concepto de traslado lo establecido en la Tarifa de Transporte respectiva.
        </p>
        <h3>SÉPTIMA. DERECHOS ADICIONALES DE BOXMI. </h3>
        <p>
        En caso de que el Cliente incurra en mora en el pago de la Tarifa de Almacenamiento, o bien cualquier otra obligación de carácter económica a cargo del Cliente, o exista un adeudo de cualquier tipo a cargo del Cliente, Boxmi podrá, a su propia elección:
        </p>
        <p>
        (a) Continuar con la prestación de los Servicios, por lo cual se continuarán generando los cargos del pago de la Tarifa de Almacenamiento y de cualquier otra contraprestación económica derivada del presente Contrato a cargo del Cliente; o
        </p>
        <p>
        (b) En caso de retraso en 3 (tres) o más pagos de la Tarifa de Almacenamiento en forma consecutiva, o bien en caso de terminación del presente Contrato por causas imputables al Cliente (enlistadas en la cláusula 9.2 de este Contrato) en este acto, el Cliente autoriza de la forma más amplia de acuerdo a lo que en derecho corresponda a Boxmi para que disponga, sin responsabilidad alguna para Boxmi, de los Artículos a la manera que más le convenga de acuerdo al criterio de Boxmi. Lo anterior incluye, sin limitar a, vender, arrendar, o disponer de los Artículos de cualquier forma, incluso darle trato como desecho o basura. El Cliente se obliga a pagar a Boxmi cualquier erogación o costo en el que incurra para ejecutar los actos descritos en el presente inciso. Asimismo, en este acto, el Cliente libera de todo tipo de responsabilidad a Boxmi por ejercer el derecho comprendido en este inciso y renuncia a cualquier derecho, reclamo, demanda, denuncia o procedimiento en contra de Boxmi relacionado con la disposición de los Artículos por éste en términos de esta Subsección; o
        </p>
        <p>
        (c) Hacer entrega de los Artículos en el domicilio del Cliente señalado en la subsección 4.2 de la Carátula. El Cliente se obliga a pagar a Boxmi cualquier erogación de cualquier tipo que deba realizar por la ejecución de los actos descritos en el presente inciso, incluso si llegan a ser mayores que la Tarifa de Transporte. Así mismo, en este acto, el Cliente libera de todo tipo de responsabilidad a Boxmi por ejercer el derecho comprendido en este inciso.
        </p>
        <h3>OCTAVA. COMUNICACIONES. </h3>
        <p>
        (a) Las Partes acuerdan que utilizarán la Plataforma y los protocolos y mecanismos en ella, como una forma de comunicación válida para cualquier solicitud o petición relacionada con la operación de los Servicios.
        </p>
        <p>
        (b) Las Partes acuerdan que para cualquier cuestión relacionada con el presente Contrato, se deberá de notificar de manera fehaciente al domicilio o correo electrónico de las Partes. Para este efecto, Boxmi señala como su domicilio convencional para oír y recibir notificaciones, el siguiente:
        </p>
        <p>
        El Cliente entenderá que su domicilio para oír y recibir notificaciones es aquél señalado en la Carátula o bien en caso de que exista uno adicional en su Cuenta de Usuario, se podrá alternativamente notificar a éste.
        </p>
        <p>
        (c) Las notificaciones y comunicaciones referidas en este Contrato se podrán hacer mediante la entrega del aviso o notificación correspondiente en los domicilios señalados previamente o bien, mediante mensaje enviado al correo electrónico señalado en líneas anteriores. Las notificaciones se entenderán legalmente hechas cuando se practiquen de forma fehaciente en el domicilio señalado, o bien, en el caso del correo electrónico (que en el caso del Cliente, será aquel referido en la subsección 1.5 de la Carátula, o bien aquél registrado en la Plataforma para dicho Cliente), cuando la notificación se haya enviado a la dirección de correo electrónico previamente señalada (en el caso de las comunicaciones realizadas a Boxmi, será necesario que haya un acuse de recibo de dicha notificación), por lo que en caso de que cualquiera de las partes modifique su domicilio o correo electrónico, deberá de dar aviso de dicha circunstancia con 10 (diez) días de anticipación y, en caso de no hacerlo, se entenderán como legalmente practicadas las notificaciones hechas en el domicilio o a la dirección de correo electrónico aquí señaladas.
        </p>
        <h3>NOVENA. TERMINACIÓN. </h3>
        <h4>9.1. Terminación sin responsabilidad para ninguna de las Partes</h4>
        <p>
        El presente Contrato se dará por terminado sin responsabilidad para ninguna de las Partes en los siguientes casos:
        </p>
        <ul>
          <li>i. Por la clausura del Sitio;</li>
          <li>ii. Por así notificarlo Boxmi al Cliente con al menos 30 (treinta) días naturales de anticipación; o</li>
          <li>iii. Por así notificarlo el Cliente a Boxmi con al menos 30 (treinta) días naturales de anticipación.</li>
        </ul>
        <h4>9.2. Rescisión con responsabilidad para el Cliente</h4>
        <p>
        (a) Boxmi tendrá facultad de rescindir el presente Contrato, sin responsabilidad de ningún tipo para Boxmi, en los siguientes casos:
        </p>
        <ul>
          <li>i. Si el Cliente es omiso en cualquier pago de las Tarifas o el pago de cualquier otro tipo de contraprestación económica a su favor bajo el presente Contrato;</li>
          <li>ii. En caso de que la tarjeta de crédito registrada por el Cliente llegue a su vencimiento y éste no registre una nueva tarjeta vigente dentro de los 15 (quince) días naturales de que se le dio aviso a través de la Plataforma;</li>
          <li>iii. Si cualquier autoridad de cualquier ámbito, incluyendo sin limitar de la esfera federal, estatal o municipal, solicita a Boxmi la entrega de los Artículos. En esta situación, el Cliente autoriza ampliamente a Boxmi para que realice la entrega respectiva sin ninguna responsabilidad para Boxmi;</li>
          <li>iv. En caso de que cualquier información de las secciones 1 y/o 2 de la Carátula del Contrato resultase falsa;</li>
          <li>v. Si el Cliente incumple con cualquier obligación a su cargo derivado de este Contrato.</li>
        </ul>
        <p>
        (b) En cualquier de los casos anteriores, el Cliente deberá pagar a Boxmi la cantidad de la Tarifa de Almacenamiento total restante durante la Vigencia.
        </p>
        <p>
        (c) En caso que el Cliente no haga el pago de las Tarifas aplicables, o el pago de cualquier otro tipo de contraprestación económica, en la fecha correspondiente, y Boxmi incurriera en gastos de cobranza judiciales o extrajudiciales, incluyendo de manera enunciativa más no limitativa, gastos por honorarios legales, mediación, transporte, comunicación o cualquier otro tipo de gasto o costo en los que incurra Boxmi con motivo del retraso del Cliente (los “Gastos de Cobranza”), el Cliente se obliga a pagar a Boxmi (a la vista) aquellos Gastos de Cobranza más el Impuesto al Valor Agregado correspondiente.
        </p>
        <h3>DÉCIMA. IMPUESTOS. </h3>
        <p>Las Partes convienen en que el pago de todos y cada uno de los impuestos que con motivo del presente Contrato se causen o pudieran causarse, serán responsabilidad exclusiva de cada una de ellas, en la forma y término que al efecto señalen las leyes fiscales que resulten aplicables tanto en México como en el extranjero.
        </p>
        <h3>DÉCIMA PRIMERA. CONFIDENCIALIDAD. </h3>
        <p>
        El Cliente acuerda mantener en confidencialidad toda la información presentada en este documento y la aportada durante las operaciones de la prestación del servicio de almacenamiento referido en este Contrato. Para los propósitos del presente Contrato, se entenderá por "Información Confidencial" cualquier información de Boxmi que se encuentre, ya sea actualmente o en cualquier momento, en posesión del Cliente, la cual sea relacionada con los negocios de Boxmi, incluyendo sin limitación alguna, documentos, datos, información técnica o financiera, planos, diseños, fotografías, dibujos, especificaciones y cualquier otro material que ostente o lleve consigo cualquier información relacionada con este Contrato. También se considerará como "Información Confidencial" todos los programas, sistemas, interfaces, aplicaciones, herramientas, código fuente, código objeto, productos de cómputo, servidor de web, servidores de bases de datos relacionados con la Plataforma.
        </p>
        <p>En caso de que el Cliente incumpla con alguna de sus obligaciones de confidencialidad bajo este Contrato, el Cliente debe indemnizar a Boxmi por todos y cada uno de los daños ocasionados. De acuerdo a lo anterior, el Cliente quedará, además, sujeto a las multas o sanciones establecidas en el Código Penal de los Estados Unidos Mexicanos que resulte aplicable.
        </p>
        <h3>DÉCIMA SEGUNDA. DATOS PERSONALES.</h3>
        <h4>12.1. Aceptación de recopilación de Datos Personales</h4>
        <p>
        (a) En este acto, el Cliente está de acuerdo en que Boxmi adquiera y resguarde sus Datos Personales, mismos que el Cliente ha ingresado a la Plataforma.
        </p>
        <p>
        (b) Para efectos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás normatividad aplicable, Boxmi se obliga expresamente a que los (i) Datos Personales, y (ii) datos patrimoniales o financieros que se encuentren en poder de Boxmi por virtud de los Servicios serán protegidos y tratados de conformidad con lo que se establece en el Contrato, para dar cumplimiento a las obligaciones que le corresponden en términos de éste Contrato,.
        </p>
        <h4>12.2. Fines del uso de los Datos Personales</h4>
        <p>
        (a) Los Datos Personales del Cliente serán utilizados por Boxmi únicamente con los siguientes fines: (i) para elaborar datos estadísticos para distintos fines con la única y exclusiva condición de que no se revele la identificación directa del propietario de la información utilizada para los estadísticos (nombre especifico de la empresa, datos personales específicos), (ii) devolver al Cliente toda la información que contenga Datos Personales y que se encuentre contenida en medios físicos, electrónicos y magnéticos o mediante cualquier otro medio existente, (iii) brindar los Servicios acordados en el presente Contrato y cumplir con las obligaciones derivados del mismo y (iv) con fines mercadotécnicos, publicitarios o de prospección comercial.
        </p>
        <p>
        (b) Derivado de lo anterior, el Cliente acuerda que su información referida en esta cláusula sea tratada de acuerdo a lo contenido en el Aviso de Privacidad, en adición a lo ya acordado en esta cláusula. Así mismo, el Cliente se obliga a otorgar los mismos derechos y obligaciones en materia de cuidado de datos personales a Boxmi respecto a los datos que obtenga de Boxmi, siempre predominando las obligaciones en materia de confidencialidad.
        </p>
        <h3>DÉCIMA TERCERA. NO RELACIÓN LABORAL. </h3>
        <p>
        Queda expresamente establecido que Boxmi no se encuentra subordinada ni jerárquica, ni económicamente al Cliente, ni viceversa, por lo que ambas Partes convienen que ninguna obtiene respecto de la otra por la firma de este Contrato, responsabilidad laboral alguna para con la otra.
        </p>
        <h3>DÉCIMA CUARTA. CESIÓN. </h3>
        <p>14.1. Cesión por parte del Cliente. El Cliente no podrá ceder sus derechos y/o obligaciones derivados del presente Contrato a menos de que cuente con la autorización expresa y por escrito de Boxmi.</p>
        <p>14.2. Cesión por parte de Boxmi. Boxmi queda facultado para ceder sus derechos y/o obligaciones derivados del presente Contrato sin necesidad de autorización alguna por parte del Cliente. </p>
        <h3>DÉCIMA QUINTA. VARIOS. </h3>
        <p>15.1. Acuerdo total. Este Contrato y su Carátula representan el acuerdo total e integral entre las Partes y deja sin efectos todas las negociaciones, declaraciones o convenios anteriores, escritos o verbales, entre las Partes con respecto a dicha materia.</p>
        <p>
        15.2. Modificaciones al Contrato. Este Contrato únicamente podrá ser modificado mediante acuerdo por escrito. El acuerdo referido en esta cláusula, deberá realizarse mediante comunicación en las formas establecidas en la cláusula octava del presente Contrato.
        </p>
        <p>
        15.3. Encabezados. Los encabezados en este Contrato se insertan únicamente como referencia y no podrán ser utilizados en la interpretación del mismo.
        </p>
        <p>
        15.4. Divisiblidad de las cláusulas. En caso de cualquiera de las disposiciones contenidas en el presente Contrato fuere declarada nula o inválida por un tribunal competente, dicha disposición se tomará por no puesta, continuando en vigor el resto del contenido del presente Contrato.
        </p>
        <p>
        15.5. Términos en singular y/o plural. Los términos empleados en singular y/o plural en este Contrato, deberán entenderse aplicables también en el otro sentido (sea singular o plural), a menos disposición expresa opuesta en este Contrato
        </p>
        <h3>DÉCIMA SEXTA. VICIOS DEL CONSENTIMIENTO Y JURISDICCIÓN Y LEGISLACIÓN APLICABLE. </h3>
        <p>
        (a) Acuerdan las Partes firmar el presente Contrato en los términos en que se estipula, ya que no obraron vicios en su consentimiento, mala fe, dolo o violencia.
        </p>
        <p>
        (b) Acuerdan las Partes que en caso de sobrevenir cualquier controversia derivada de la interpretación o cumplimiento de este Contrato serán aplicables las disposiciones del Código Civil vigente en el estado de Nuevo León, y serán competentes exclusivamente los tribunales de la ciudad de Monterrey, Nuevo León para resolver cualquier conflicto, sin importar el domicilio presente o futuro que las partes llegaran a tener.
        </p>
        <p>
        Firmado en la fecha indicada en la subsección 3.1 de la Carátula, en [*], Nuevo León, México.
        </p>
        <h2>Anexo I</h2>
        <h3>Guía del límite de responsabilidad de Boxmi</h3>
        <h4>Compensación máxima por artículo</h4>
        <p>
        En el caso aplicable de acuerdo a lo contenido en el Contrato, Boxmi pagará al Cliente, como cantidad máxima por concepto de compensación, lo estipulado en la siguiente tabla:
        </p>
        <table>
          <thead>
            <tr>
              <th>Tipo de artículo (de acuerdo a lo establecido en la sección 2 de la Carátula del Contrato y sus subsecciones que correspondan)
              </th>
              <th>Compensación máxima</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
              Caja mediana
              </td>
              <td>
              $[*] ([*] y 00/100 pesos moneda nacional)
              </td>
            </tr>
            <tr>
              <td>
              Caja grande
              </td>
              <td>
              $[*] ([*] y 00/100 pesos moneda nacional)
              </td>
            </tr>
            <tr>
              <td>
              Objeto mediano
              </td>
              <td>
              $[*] ([*] y 00/100 pesos moneda nacional)
              </td>
            </tr>
            <tr>
              <td>
              Objeto grande
              </td>
              <td>
              $[*] ([*] y 00/100 pesos moneda nacional)
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Compensación máxima total/acumulativa</h4>
        <p>Independientemente de lo referido en el numeral I del presente Anexo, en todo caso, la compensación máxima total acumulativa no rebasará de $[*] ([*] y 00/100 pesos moneda nacional) durante la Vigencia total del presente Contrato.</p>
        <h2>Anexo 2</h2>
        <h3>Guía para Almacenamiento de Artículos de Boxmi</h3>
        <h4>I. Lineamientos para Empaque de los Artículos</h4>
        <h4>II. Artículos Prohibidos</h4>
        <p>
        Se prohíbe solicitar el almacenamiento a Boxmi de cualquier artículo que cuente con alguna de las siguientes características:
        </p>
        <ul>
          <li>
          Cualquier artículo, material o bien ilícito, o bien, cualquier artículo cuya posesión de cualquier forma viole alguna ley, reglamento o normatividad en los Estados Unidos Mexicanos;
          </li>
          <li>Dinero en efectivo, centenarios, lingotes de oro, [incluir otros];</li>
          <li> [Artículos perecedores, biodegradables, organismos vivientes, artículos orgánicos, aquellos que requieran de algún tipo de refrigeración o almacenamiento especial no acordado en los Términos y Condiciones]</li>
          <li>[Armas de fuego, pólvora, explosivos, dinamita, artículos pirotécnicos, armas punzocortantes, etc.]</li>
          <li>[Cualquier artículo o sustancia inflamable, peligrosa o inestable]</li>
          <li>Cualquier sustancia o material peligroso, contaminante, sustancia peligrosa, material peligroso, sustancia tóxica, sustancias radioactivas;</li>
          <li>Elementos, substancias, compuestos, o mezcla de ellos que, independientemente de su estado físico, represente un riesgo para el ambiente, la salud o los recursos naturales, por sus características corrosivas, reactivas, explosivas, tóxicas, inflamables o biológicoinfecciosas</li>
        </ul>
        <p>Se podrá solicitar a Boxmi el almacenamiento de Artículos que por sí solos tengan un valor mayor a $[*]. En este caso, en ninguna circunstancia, incluyendo aquellas pactadas en el presente Contrato, darán lugar a responsabilidad alguna de Boxmi.
        </p>
        <h4>III. Dimensiones Máximas y Mínimas</h4>
        <p>[Favor de proveer contenido]</p>
        <h4>IV. Peso Máximo</h4>
        <p>
        [Favor de proveer contenido]</p>
        <h2>Anexo 3</h2>
        <h3>Tarifas Aplicables a los Servicios</h3>
        <h4>I. Almacenamiento</h4>
        <p>La Tarifa de Almacenamiento se determinará mediante la suma de todas y cada una de las Cuotas por Artículo.</p>
        <p>La Cuota por Artículo se determinará mediante la siguiente fórmula: <code>(Cantidad) x (Precio) = Cuota por Artículo. </code>
        </p>
        <p>Las variables anteriores serán determinadas en la Sección 2 de la Carátula.
        </p>
        <p>
        La Cuota por Artículo será incrementada anualmente (de forma automática y sin notificación previa) de acuerdo al Índice Nacional de Precios al Consumidor (INPC) publicado por el Instituto Nacional de Estadística y Geografía (INEGI), en el Diario Oficial de la Federación, tomando como base la inflación de los doce últimos meses anteriores a la fecha de revisión. Las revisiones a la Cuota por Artículo serán en el mes de enero de cada año de la duración del presente Contrato y cualquiera de sus prórrogas.
        </p>
        <h4>II. Transportación</h4>
        <p>
        Por cada ocasión que Boxmi haga entrega de cualquiera de los Artículos, el Cliente pagará la siguiente tarifa, por cada Artículo: $[*] ([*] y 00/100 pesos moneda nacional) más el I.V.A. correspondiente.
        </p>
        <p>
        [favor de proveer información]
        </p>
      </div>
    )
  }
}
