import React from "react";

export default class FAQ extends React.Component {
  render() {
    return (
      <div className="common-container">
        <h2>FAQ Boxmi</h2>
        <h3>General</h3>
        <h4>¿Qué es Boxmi?</h4>
        <p>
          Boxmi es la manera más segura y eficiente de almacenar tus pertenencias y tenerlas disponibles en el momento que las necesites. Boxmi es un servicio de almacenamiento a domicilio que combina la tecnología con los servicios tradicionales para hacerte la vida más fácil.
        </p>
        <p>
          Olvídate de vueltas innecesarias a la minibodega. Cuida tu espalda y deja que nosotros nos encarguemos. Paga solo por el espacio que requieres, en Boxmi te cuidamos lo que quieres.
        </p>
        <h4>¿Cómo funciona?</h4>
        <p>Entra a <a href="/">Boxmi.mx</a> para programar tu cita y decidir cuantas cajas y objetos quieres que te cuidemos. Al terminar tu orden, un boxero te contactará para aclarar cualquier duda y entregarte nuestras cajas (sin costo) para empacar. El día de tu cita, iremos a recolectar tus cosas para llevarlas a nuestra bodega. Tendrás un inventario fotográfico en donde podrás administrar tus pertenencias.  En el momento que quieras un objeto o caja de regreso, lo podrás solicitar a través de nuestra plataforma y nosotros lo llevamos. </p>
        <p>En Boxmi la seguridad de tus cosas es nuestra prioridad, por lo que nuestra bodega cuenta con seguridad, monitoreo y control de acceso las 24hrs.</p>
        <h3>Objetos a guardar</h3>
        <h4>¿Qué puedo guardar?</h4>
        <p>Puedes guardar cosas que quepan en nuestras cajas como ropa, juguetes, utensilios de cocina, libros, adornos de navidad, artículos para bebé, colecciones, etc.  También puedes guardar objetos que no quepan en nuestras cajas como maletas, pinos de navidad, bicicletas, bastones de golf, sillas, mesas, muebles, lámparas, carriolas, periqueras, colchones, etc. </p>
        <p>Está prohibido guardar artículos ilegales (droga, explosivos o combustibles, objetos robados), armas de fuego, plantas, animales, alimentos y perecederos, líquidos que se puedan derramar (incluyendo botes de shampoo, lociones, etc.), químicos, dinero en efectivo, artículos que expidan olor, cualquier cosa que pueda dañar a personal de Boxmi, otros clientes, o nuestra bodega. </p>
        <p>No recomendamos guardar artículos frágiles debido a que la responsabilidad de empacar es de nuestros clientes, por lo que no podemos asegurar que dichos objetos no sufran daños durante el traslado. Todos los artículos electrónicos e instrumentos musicales son considerados como frágiles, incluyendo televisiones, computadoras, tablets, celulares, impresoras, guitarras, pianos, etc. Tampoco recomendamos guardar artículos de muy alto valor como joyas, relojes finos y pieles. </p>
        <p>Si quieres almacenar objetos que excedan las dimensiones establecidas en nuestra plataforma nos puedes contactar para hacerte una cotización.</p>
        <h4>¿Por cuánto tiempo tengo que guardar mis cosas?</h4>
        <p>El tiempo mínimo para guardar tus cosas con Boxmi es de un mes, las puedes pedir de regreso antes de que el mes termine pero se cobrará el mes completo.</p>
        <h4>¿Cuánto cuesta guardar mis cosas?</h4>
        <p>Tenemos dos tamaños de cajas y puedes guardar todo lo que quepa en ellas: </p>
        <ul>
          <li>Caja mediana $119 pesos al mes con capacidad de 58 litros (52cm L x 32cm A x 31cm H) con un límite de 28 kg</li>
          <li>Caja grande $149 pesos al mes con capacidad de 71 litros (52cm L x 41cm A x 30cm H) con un límite de 34 kg</li>
        </ul>
        <p>También puedes almacenar tus artículos que no quepan en nuestras cajas: </p>
        <ul>
          <li>Objetos medianos $189 pesos al mes con dimensiones máximas de 0.5m x 0.5m x 1m de altura con un límite de 35 kg de peso.</li>
          <li>Objetos grandes $279 pesos al mes con dimensiones máximas de 1m x 1m x 1m de altura con un límite de 50 kg de peso.</li>
          <li>Objetos extragrandes $449 pesos al mes con dimensiones arriba de 1m x 1.5m x 3m de altura o más de 70 kg de peso.</li>
        </ul>
        <h4>¿Cómo puedo guardar mis cosas?</h4>
        <p>Previo a la fecha de tu cita de recolección, un boxero acudirá a tu domicilio a dejarte nuestras cajas para que puedas empacar tus cosas en ellas. Adicional a las cajas tenemos bolsas para pino de navidad, papel burbuja, plástico para emplayar, cobijas para proteger muebles (estos artículos tienen costo adicional).</p>
        <p>Es importante que antes de tu cita tengas todo preparado para que cuando llegue el boxero pueda recolectar tus cosas dentro del tiempo incluido en tu servicio (1 hora). </p>
        <h4>¿Puedo quedarme con las cajas?</h4>
        <p>Sí. Tras la cancelación de un servicio, puedes quedarte con nuestras cajas por un costo de $280 pesos la caja mediana y $330 pesos la caja grande.</p>
        <h3>Entrega y Recolección</h3>
        <h4>¿Cuánto cuesta la recolección y envío de mis cosas?</h4>
        <p>En Boxmi, todas las recolecciones son gratis. Los envíos de artículos a tu domicilio tienen un costo dependiendo de la zona en la que estés.</p>
        <p>El costo de envíos a tu domicilio está limitado a lo que quepa en nuestra Boxvan (9 metros cúbicos). </p>
        <h4>¿Qué incluye mi cita de recolección o envío?</h4>
        <p>Nuestro servicio incluye un boxero durante una hora. Si requieres un boxero adicional se generará un cargo extra de $300 pesos por hora.</p>
        <p>Si tu servicio sobrepasa la hora incluida, se generará un cargo de $75 pesos por boxero por cada 15 minutos adicionales. Cumplir con nuestros clientes puntualmente es primordial para nosotros, por lo que en caso de que el tiempo adicional de tu servicio interfiera con un servicio posterior, el boxero se reservará el derecho de interrumpir el servicio y reprogramar la recolección en tu domicilio. </p>
        <p>Los boxeros tienen como límite 2 horas por servicio. Si crees que tu servicio va a tardar más de este tiempo te sugerimos contactarnos para programar una cita especial. </p>
        <h4>¿Los Boxeros me ayudan a empacar?</h4>
        <p>Nuestros Boxeros están a tu mejor disposición para ayudarte en cualquier cosa que necesites, pero recomendamos tener todo listo antes de que lleguen para evitar incurrir en cargos adicionales.</p>
        <h4>¿Puedo pedir un servicio para hoy mismo?</h4>
        <p>Si se puede, siempre y cuando tengamos disponibilidad y programes tu cita antes de las 12pm. Los servicios dentro del mismo día tienen un costo extra de $250 pesos. </p>
        <h3>Seguridad</h3>
        <h4>¿Mis cosas están en un lugar seguro?</h4>
        <p>En Boxmi la seguridad de tus cosas es lo más importante. Nuestras bodegas son 100% seguras, cuentan con monitoreo y vigilancia las 24 horas los 365 días del año. El acceso a la bodega está restringido sólo para personal autorizado de Boxmi. Lo anterior para seguridad de tus cosas y las de todos nuestros clientes. </p>
        <h4>¿Mis cosas están protegidas?</h4>
        <p>Si, nuestros servicios incluyen un paquete de seguro básico contra robo, incendio o sus efectos inmediatos, como calor o humo que cubre hasta [XXX]. Si deseas tener una cobertura más amplia la puedes contratar en <a href="/">Boxmi.mx.</a> Para más información favor de consultar nuestros <a href="/terminos">términos y condiciones.</a></p>
        <h4>¿Para qué es y cómo funciona el inventario fotográfico?</h4>
        <p>El inventario fotográfico es para que puedas tener mayor control y seguridad de tus cosas almacenadas en Boxmi. El boxero se encargará de tomar cuantas fotos sean necesarias de cada uno de tus objetos o cajas para nuestro registro. Sin embargo, te recomendamos tomar fotos de todos los artículos que tienes dentro de las cajas y utilizar nuestra plataforma para llevar tu propio inventario. De esta manera, sabrás exactamente qué te estamos cuidando. Además de agregar tus fotografías, podrás describir detalladamente con texto cada artículo o el contenido de cada caja, por ejemplo: “Adornos de Navidad”, “Utensilios de Cocina”, “Sillas de Comedor”, etc. Esto te ayudará a que en el momento que quieras un artículo de regreso, sepas exactamente cuál es.</p>
        <h3>Domicilios y cargo a tarjeta</h3>
        <h4>¿Puedo poner diferentes direcciones de recolección y envío?</h4>
        <p>Sí, a la hora de crear tu perfil en <a href="/">Boxmi.mx</a> te pediremos una dirección de envío, pero podrás modificar o agregar otros domicilios de entrega o recolección. </p>
        <h4>¿Puedo cambiar mi método de pago?</h4>
        <p>Sí, Boxmi te hará cargos automáticos a la tarjeta que indiques en la plataforma. Podrás modificarla en cualquier momento en tu perfil. El primer cargo se hará al momento de la primer recolección y para los meses posteriores, el día del mes en que inició tu servicio por primera ocasión.</p>
        <h4>¿Qué pasa si no pago o no pasa mi tarjeta?</h4>
        <p>No nos gustaría que dejaras de utilizar nuestro servicio por un problema con el método de pago. Si tu tarjeta no funciona te avisaremos en la fecha de corte para que puedas actualizar tus datos y continuar utilizando nuestro servicio. Si después de 3 meses no hemos logrado contactarte, Boxmi podrá hacer usos de tus cosas para liberar ese espacio en nuestra bodega.</p>
        <p>Para más información favor de consultar nuestros <a href="/terminos">términos y condiciones.</a></p>
      </div>
    )
  }
}
