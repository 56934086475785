import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { NotificationManager} from 'react-notifications';
import BoxIcon1 from 'img/cotiza-cajas-01.svg';
import BoxIcon2 from 'img/cotiza-cajas-02.svg';
import BoxIcon3 from 'img/cotiza-cajas-03.svg';
import { withRouter } from 'react-router-dom';

class QuoterBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      postalCode: ''
    };
  }

  goToQuoting = (e) => {
    e.preventDefault();
    if(this.state.postalCode.length < 5)
    {
      NotificationManager.info('Favor de introducir un código postal', '', 5000);
    }
    else {
      showLoader();
      axios_instance.get('/v1/validate_postal_code', {params: {postal_code: this.state.postalCode}  }).then((response) => {
        this.props.history.push(`/quoting/${this.state.postalCode}`);
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  updatePostalCode = (e) => {
    this.setState({
      postalCode: e.target.value
    });
  }

  render() {
    return (
        this.props.quoterSection === 0 ?
        (
          <section className="cotiza py-5">
            <div className="cajas02">
              <img src={BoxIcon1} alt="cotiza-caja-1" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                  <h1 className="text-center">Cotiza Ahora</h1>
                  <p className="text-center">Empecemos validando tu ubicación.</p>
                  <form onSubmit={this.goToQuoting}>
                    <div className="form-group">
                      <input type="text" placeholder="Ingresa tu Código Postal" value={this.state.postalCode} onChange={this.updatePostalCode} />
                      <div className="input-background"></div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn form-control">Iniciar Cotización</button>
                      <div className="input-background"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="cajas03"><img src={BoxIcon2} alt="cotiza-caja-2" /></div>
          </section>
        )
        :
        (
          <section className="cotiza cotiza-footer py-5">
            <div className="cajas04">
              <img src={BoxIcon3} alt="box-icon-3" />
            </div>
            <div className="cotiza-access-btn">ó comienza tu cotización</div>
            <div className="container">
              <div className="row">
                <div className="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
                  <p className="text-center">Empecemos validando tu ubicación.</p>
                  <form onSubmit={this.goToQuoting}>
                    <div className="form-group">
                      <input type="text" placeholder="Ingresa tu Código Postal" value={this.state.postalCode} onChange={this.updatePostalCode} />
                      <div className="input-background"></div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn form-control" onClick={() => this.goToQuoting()}>Iniciar Cotización</button>
                      <div className="input-background"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        )
    )
  }
}

const Quoter = withRouter(QuoterBase);
export default Quoter;
