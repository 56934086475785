import React from "react";
import { ToCurrency } from 'components/utils';
import arrowDown from 'img/arrow-down.svg';
import boxIcon from 'img/box-icon-w.svg';
import creditCardIcon from 'img/credit-card-icon-w.svg';
import warehouseIcon from 'img/warehouse-icon-w.svg';
import OngoingOrderList from 'templates/users/ongoing-order-list';

export default class DashboardMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {
        attributes: {
          full_name: "",
          "subscription-details": {
            amount: 0
          },
        }
      },
      styles: {
        sidebar: "dashboard-menu"
      }
    }
  }

  componentDidMount() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      currentUser.attributes = currentUser.attributes || {}
      currentUser.attributes['subscription-details'] = currentUser.attributes['subscription-details'] || { amount: 0 };

      this.setState({
        user: currentUser
      });
    }
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  toggleSidebar = () => {
    let styles = this.state.styles;
    if(styles.sidebar.includes('dashboard-menu-opened')) {
      styles.sidebar = 'dashboard-menu';
    } else {
      styles.sidebar = 'dashboard-menu dashboard-menu-opened';
    }
    this.setState({
      styles: styles
    });
  }

  handleClickOutside = (e) => {
    if(this.state.styles.sidebar.includes('dashboard-menu-opened')) {
      if(this.node.contains(e.target)) {
        return;
      }

      this.toggleSidebar();
    }
  }

  render() {
    var { user } = this.state;
    return (
      <div className={this.state.styles.sidebar} ref={node => this.node = node}>
        <div className="dashboard-menu-item">
          <p>
            <small>HOLA</small><br /><span className="white-item">{user.attributes['full-name']}</span>
          </p>
        </div>
        <div className="dashboard-menu-suscription-item">
          <span className="dashboard-menu-suscription-first-item">MI SUSCRIPCIÓN</span>
          <span className="dashboard-menu-suscription-big-item white-item">{ToCurrency(user.attributes['subscription-details'].amount)}</span>
          <span className="dashboard-menu-suscription-last-item">MENSUAL</span>
        </div>
        <a style={{padding: '1em'}} href="/users" className="dashboard-menu-item">
          <div className="dashboard-menu-item-title">
            <img width="40" src={warehouseIcon} alt="order" className="dashboard-menu-item-icon" />
            <p>MI<br /><span className="red-item">DASHBOARD</span></p>
          </div>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        <a style={{padding: '1em'}} href="/wallet" className="dashboard-menu-item">
          <div className="dashboard-menu-item-title">
            <img width="40" src={creditCardIcon} alt="order" className="dashboard-menu-item-icon" />
            <p>MIS<br /><span className="red-item">METODOS DE PAGO</span></p>
          </div>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        {/* <a style={{padding: '1em'}} href="/quoting" className="dashboard-menu-item">
          <div className="dashboard-menu-item-title">
            <img width="40" src={boxIcon} alt="order" className="dashboard-menu-item-icon" />
            <p>CONTRATAR<br /><span className="red-item">MÁS SERVICIOS</span></p>
          </div>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a> */}
        {/* <a style={{padding: '1em'}} href="/users/receipts" className="dashboard-menu-item">
          <div className="dashboard-menu-item-title">
            <img width="40" src={creditCardIcon} alt="order" className="dashboard-menu-item-icon" />
            <p>HISTORIAL<br /><span className="red-item">DE PAGOS</span></p>
          </div>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a> */}
        <OngoingOrderList />
      </div>
    )
  }
}
