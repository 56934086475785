import React from "react";
import creditCard from "img/credit-card-icon.svg";
import InputMask from "react-input-mask";
import { axios_instance } from "components/axios";
import { NotificationManager } from "react-notifications";
import { showLoader, hideLoader } from "components/loading-spinner";
import conektaHelper from "components/conekta-helper";
import PaymentMethods from "img/iconosTarjeta.png";

export default class PaymentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewCardForm: false,
      userData: {},
      cards: [],
      date: "",
      cvc: "",
      cardNumber: "",
      name: "",
      exp_year: "",
      exp_month: "",
      card: {
        cardNumber: "",
        name: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
      },
    };
  }

  componentWillMount() {
    var order = JSON.parse(sessionStorage.getItem("order"));
    var userData = JSON.parse(localStorage.getItem("currentUser"));
    if (Object.keys(order.card).length > 0) {
      this.setState({
        userData: userData,
        card: {
          cvc: order.card.cvc,
          cardNumber: order.card.number,
          name: order.card.name,
          exp_year: order.card.exp_year,
          exp_month: order.card.exp_month,
          date: order.card.exp_month + order.card.exp_year,
        },
      });
    } else {
      this.setState({
        userData: userData,
      });
    }
  }

  componentDidMount() {
    this.getPaymentMethods();
  }

  handleChange = (event) => {
    var params = this.state.card;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "date") {
      var month = value.split("/")[0];
      var year = value.split("/")[1] || "";
      params["exp_month"] = month;
      params["exp_year"] = year;
      this.setState({
        card: { ...this.state.card, exp_month: month, exp_year: year },
      });
    }
    params[name] = value;
    this.setState({ card: { ...this.state.card, [name]: value } });
    var card = {
      number: params.cardNumber,
      name: params.name,
      exp_year: params.exp_year,
      exp_month: params.exp_month,
      cvc: params.cvc,
    };
    this.props.setcreditcard(card);
  };

  getPaymentMethods = () => {
    if (localStorage.getItem("currentUser")) {
      axios_instance
        .get(`/v1/users/${this.state.userData.id}/get_payment_methods`)
        .then((response) => {
          this.setState({
            cards: response.data,
          });
        });
    } else {
      this.setState({ showNewCardForm: true });
    }
  };

  changeDefaultPaymentMethod = (id) => {
    showLoader();
    axios_instance
      .post(
        `/v1/users/${this.state.userData.id}/change_default_payment_method`,
        {
          payment_source_id: id,
        }
      )
      .then(() => {
        hideLoader();
        this.getPaymentMethods();
      });
  };

  addPaymentMethod = (token) => {
    axios_instance
      .post(`/v1/users/${this.state.userData.id}/add_payment_method`, {
        token_id: token,
      })
      .then(() => {
        this.getPaymentMethods();
      });
  };

  isDefaultPaymentMethod = (isDefault) => {
    if (isDefault) {
      return <div className="checkMark">✓</div>;
    }
  };

  renderCards = () => {
    let cards = Object.values(this.state.cards).map((card) => {
      return (
        <div
          onClick={() => this.changeDefaultPaymentMethod(card["id"])}
          key={`card-${card["id"]}`}
          className="card"
        >
          <div>{card["name"]}</div>
          <div>**** **** **** {card["last4"]}</div>
          <div>
            expira {card["exp_month"]}/{card["exp_year"]}
          </div>
          <div>{card["brand"]}</div>
          <div>{this.isDefaultPaymentMethod(card["default"])}</div>
        </div>
      );
    });

    return cards;
  };

  tokenizeCardAndAddItToWallet = () => {
    conektaHelper.initConekta();
    showLoader();
    let card = {
      ...this.state.card,
    };
    card.number = card.cardNumber;
    conektaHelper.tokenize(
      card,
      (response) => {
        this.addPaymentMethod(response.id);
        hideLoader();
        this.setState({
          showNewCardForm: false,
        });
      },
      (error) => {
        NotificationManager.error(error.message_to_purchaser);
        hideLoader();
      }
    );
  };

  newCardForm = () => {
    if (this.state.showNewCardForm) {
      return (
        <div className="shipping-container">
          <div className="payment-image">
            <img
              style={{ alignSelf: "baseline" }}
              src={creditCard}
              alt="arrow-down"
            />
          </div>
          <div className="flex-column" style={{ flex: "1 1 100%" }}>
            <div className="form-group">
              <InputMask
                name="cardNumber"
                mask="9999 9999 9999 9999"
                maskChar=" "
                placeholder="Numero de Tarjeta"
                value={this.state.card.cardNumber}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Nombre Completo"
                value={this.state.card.name}
                onChange={this.handleChange}
              />
            </div>
            <div style={{ flexWrap: "inherit" }} className="form-group">
              <InputMask
                style={{ flex: "1 1 50%", marginRight: "1vw" }}
                {...this.props}
                name="date"
                mask="99/99"
                maskChar=" "
                placeholder="01 / 26"
                value={this.state.card.date}
                onChange={this.handleChange}
              />
              <input
                style={{ flex: "1 1 50%" }}
                type="password"
                name="cvc"
                placeholder="CVC"
                value={this.state.card.cvc}
                onChange={this.handleChange}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {localStorage.getItem("currentUser") ? (
                <div>
                  <button onClick={() => this.tokenizeCardAndAddItToWallet()}>
                    Agregar tarjeta
                  </button>
                  <button
                    onClick={() => this.setState({ showNewCardForm: false })}
                  >
                    {" "}
                    Cancelar{" "}
                  </button>
                </div>
              ) : (
                ""
              )}
              <img src={PaymentMethods} alt="logo" height="20" />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="payment">
        {localStorage.getItem("currentUser") ? (
          <div className="wallet mb-2 mb-md-4">
            <h2>Selecciona una tarjeta</h2>
            <div className="grid p-3 p-md-0">
              {this.renderCards()}
              <div
                className="card newCard"
                onClick={() => this.setState({ showNewCardForm: true })}
              >
                <div className="plus">+</div>
                Agrega una tarjeta nueva
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.newCardForm()}
      </div>
    );
  }
}
