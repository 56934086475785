import React from "react";
import QrModal from 'templates/modals/qr-modal';
import DashboardMenu from 'templates/common/dashboard-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

export default class Store extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      qrModal:{
        visibility: false,
        products: [],
        type: "store",
      },
      currentLabel: "",
      products:[],
      order: {
        attributes: {
          products:[]
        }
      },
      storables: []
    };
  }

  openQrModal = (product) => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: true }}));
  }

  closeQrModal = () => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: false }}));
  }

  storeBox = (storable) => {
    var storables = this.state.storables;
    storables.push(storable);
    this.setState({storables})
  }


  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">Almacenar</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="recolect-item-header" >
              <h1 style={{paddingRight: "1vw"}}>Almacenar</h1>
            </div>
            <div className="upload-photo-method" onClick={this.openQrModal}>
              <FontAwesomeIcon icon={faQrcode} />
            </div>
            <div>
              <ul>
                {
                  this.state.storables.map((storable) => {
                    return (
                      <li>{storable}</li>
                  )
                })
              }
              </ul>
            </div>
            <QrModal
              display={this.state.qrModal}
              closeModal={this.closeQrModal}
              storeBox={this.storeBox}
              label={this.state.currentLabel}/>
          </div>
        </div>
      </div>
    )
  }
}
