import React from "react";
import moment from 'moment';
import DashboardMenu from 'templates/common/dashboard-menu';
import orderIconB from 'img/order-ico-02.svg';
import { LeftPad } from 'components/utils';
import { resources } from 'components/string-resources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class Orders extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      currentOrder: {
        day_label: "01",
        month_label: "Dic",
        active_orders: 0,
        complete_orders: 0,
        total_orders: 0,
        orders: []
      },
      weekDay: "Lun"
    };
  }

  componentDidMount() {
    const currentOrder = this.props.location.state.currentOrder;
    const weekDay = moment(currentOrder.orders[0].shipping_date).format("dddd");
    this.setState({
      currentOrder: currentOrder,
      weekDay: weekDay
    })
  }

  order = (order) => {
    if(order.order_status === "pending")
    {
      this.props.history.push(`/Orders/${order.id}`, [order]);
    }
  }

  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">{resources.date_time[this.state.weekDay]}</span>
              <hr />
              <div className="dashboard-content-header-body">
                <div className="dashboard-content-header-body-item">
                  <div className="dashboard-content-header-body-item-number">
                    <span>
                      {this.state.currentOrder.day_label}
                    </span>
                  </div>
                  <div className="dashboard-content-header-body-item-number-title">
                    <span>{this.state.currentOrder.month_label}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-content-header-item">
              <div className="dashboard-content-header-item">
                <span className="dashboard-content-header-title">&nbsp;</span>
                <hr />
                <div className="dashboard-content-header-body">
                  <div className="dashboard-content-header-body-item">
                    <div className="dashboard-content-header-body-item-number">{LeftPad(this.state.currentOrder.total_orders, 2)}</div>

                    <div className="dashboard-content-header-body-item-number-title">
                      <img src={orderIconB} alt="order" />
                      <span>ÓRDENES ACTIVAS</span>
                    </div>
                  </div>
                  <div className="dashboard-content-header-body-item">
                    <div className="dashboard-content-header-body-item-number blue-item">{LeftPad(this.state.currentOrder.active_orders, 2)}</div>

                    <div className="dashboard-content-header-body-item-number-title">
                      <span className="grey-item">RECEPCIÓN</span>
                    </div>
                  </div>
                  <div className="dashboard-content-header-body-item">
                    <div className="dashboard-content-header-body-item-number blue-item">{LeftPad(this.state.currentOrder.complete_orders, 2)}</div>

                    <div className="dashboard-content-header-body-item-number-title">
                      <span className="grey-item">ENVIOS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{flexDirection: 'row'}} className="dashboard-content-body">
            <div className="current-order-container">
              {
                this.state.currentOrder.orders.map((order, index) => {
                  return(
                    <div key={index} className="current-order-item" onClick={() => { this.order(order) }}>
                      <div className="current-order-item-time">
                        {resources.order.type[order.order_type]} - {moment(order.shipping_date).format("HH:mm")} HRS
                      </div>
                      {
                        resources.order.statuses[order.order_status] === resources.order.statuses.pending ?
                        (
                          <div className="current-order-item-status red-item">
                            {resources.order.statuses[order.order_status]}
                          </div>
                        )
                        :
                        (
                          <div className="current-order-item-status blue-item">
                            {resources.order.statuses[order.order_status]}
                          </div>
                        )
                      }

                      <div className="current-order-item-address">
                        <FontAwesomeIcon icon={faMapMarkerAlt}
                          style={{paddingRight:"4px", paddingTop: '0.5vh'}} />
                        {order.shipping_address.postal_code}, {order.shipping_address.suburb} <br /> {order.shipping_address.street} {order.shipping_address.ext_number} {order.shipping_address.int_number  ? ("int" + order.shipping_address.int_number) : "" }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
