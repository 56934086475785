import React from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { axios_instance } from "components/axios";
import CustomTable from "components/table";
import DashboardMenu from "templates/common/dashboard-menu";

const warehouseColumns = [
  {
    Header: "id",
    accesor: (d) => d.id,
    id: "id",
    show: false,
  },
  {
    Header: "Nombre",
    accessor: (d) => d.attributes["full-name"],
    id: "name",
    style: {
      cursor: "pointer",
    },
  },
  {
    Header: "Correo Electrónico",
    accessor: (d) => d.attributes["email"],
    id: "email",
    style: {
      cursor: "pointer",
    },
  },
  {
    Header: "Creado en",
    accessor: (d) => d.attributes["created-at"],
    id: "created-at",
    sortable: true,
    filterable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    Header: "Última actualización",
    accessor: (d) => d.attributes["updated-at-i18n"],
    id: "updated-at-i18n",
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer",
    },
  },
];

export default class Boxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
      loading: true,
      page: 1,
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize,
      created_at: "desc",
    };

    if (state.sorted.length) {
      state.sorted.forEach(function (sort) {
        let sortBy = sort.id;
        if (sortBy === "created-at") sortBy = "created_at";
        params[sortBy] = sort.desc ? "desc" : "asc";
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function (filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    params["by_active_status"] = "active";
    params["by_role"] = "client";
    axios_instance
      .get("/v1/users", { params })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            data: res.data.data,
            loading: false,
            pages: res.data.meta.pagination["total-pages"],
          });
        }
      })
      .catch(() => {});
  };

  // addWarehouse = () => {
  //   this.props.history.push('//new')
  // }

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content w-100">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <h3 className="dashboard-content-header-title">Usuarios</h3>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body w-100">
            <CustomTable
              {...this.props}
              columns={warehouseColumns}
              data={data}
              pages={pages}
              loading={loading}
              manual
              onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
              filterable
              defaultPageSize={10}
              defaultSorted={[
                {
                  id: "id",
                  desc: false,
                },
              ]}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (column.Header) {
                      this.props.history.push(
                        `/Admin/Users/${rowInfo.original.id}`,
                        [rowInfo.original]
                      );
                    } else {
                      handleOriginal();
                    }
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
