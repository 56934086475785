import React from "react";
export default class Tooltip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      helpText: 'lorem'
    }
  }
  render() {
    return (
      <div className="toolTip">
        <div className="toolTip-icon">?
          <div className="toolTip-message">
            { this.props.help }
          </div>
        </div>
      </div>
    )
  }
}
