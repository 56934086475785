import React from "react";

export default class OngoingOrderElement extends React.Component {
  render() {
    return (
      <div style={{alignItems: "center", justifyContent: "space-between", marginBottom: "0.4em"}} className="flex-row">
        <p style={{width: "40px", marginRight: "1vw", color: "#F74164", textAlign: "end"}}>0{this.props.listNumber}</p>
        <div style={{flex: "1 1 100%"}} className="flex-column">
          <h4 style={{marginBottom: "0"}}>{this.props.order.attributes.title}</h4>
          <div style={{justifyContent: 'space-between'}} className="flex-row">
            <span>{this.props.order.attributes.name}</span>
            <span>{this.props.order.attributes.order_type}</span>
          </div>
          <div className="flex-row">
            <span>{this.props.order.attributes.date}</span>
          </div>
        </div>
      </div>
    )
  }
}
