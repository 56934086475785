import React from "react";
import { SaveUserToLocalStorage, ToCurrency } from 'components/utils';
import { axios_instance } from  'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import truckIcon from 'img/truck-icon.svg';
import boxIcon from 'img/box-icon.svg';
import couponIcon from 'img/coupon-icon-w.svg';
import calendarIcon from 'img/calendar-icon.svg';
import locationIcon from 'img/location-icon.svg';

export default class UserWelcome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      additionaItemTotal: "0",
      order: {
        attributes: {
          products: [],
          'additional-items': [],
          'shipping-address': {
            postal_code: "",
            suburb: "",
          },
          user: {},
          isCouponValid: false,
          total: "0"
        }
      }
    }

  }

  componentDidMount() {
    var currentUser = JSON.parse(localStorage.getItem("currentUser"))
    var isCouponValid = sessionStorage.getItem("isCouponValid");
    if (currentUser) {
      showLoader();
      axios_instance.get('/v1/users/' + currentUser.id).then((resp) => {
        SaveUserToLocalStorage(resp.data.data);
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    }
    if(this.props.location.state) {
      var order = this.props.location.state[0];
      this.setState({ order: {attributes: {...this.state.order.attributes, ...order.attributes}, id: order.id}, isCouponValid})  ;
    } else {
      this.goToDashboard();
    }
  }

  goToDashboard = () => {
    sessionStorage.removeItem("isCouponValid");
    this.props.history.push('/users');
  }

  renderProducts = () => {
    var products = this.state.order.attributes.products.map((product, key) => {
      var type = product['product-type'] === "box" ? "CAJA" : "OBJETO"
      return (
        <div key={key} className="receipt-item">
          <p>
            {type}
            <br />
            <span className="red-item">{product.size.toUpperCase()}</span>
          </p>
          <p>
            {product.quantity}
            <br />
            <span className="red-item">UNIDADES</span>
          </p>
          <p>
            {ToCurrency(product.total)}
            <br />
            <span className="red-item">MENSUALES</span>
          </p>
        </div>
      )
    })
    return products
  }

  renderSubscriprionTitle = (val) => {
    let user = JSON.parse(localStorage.getItem('currentUser'))
    return user && user.attributes.subscription['active-status'] ? 'MI NUEVA SUSCIRPCIÓN' : 'MI SUSCRIPCIÓN'
  }

  renderCurrentSubsctription = () => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let currentSubscription = Object.values(currentUser.attributes.subscription.summary)
    var products = currentSubscription.map((product, key) => {
      var type = product['type']
      return (
        <div key={key} className="receipt-item">
          <p>
            {type}
            <br />
            <span className="">{product.size.toUpperCase()}</span>
          </p>
          <p>
            {product.count}
            <br />
            <span className="">UNIDADES</span>
          </p>
          <p>
            {ToCurrency(product.total)}
            <br />
            <span className="">MENSUALES</span>
          </p>
        </div>
      )
    })
    return products
  }

  renderAdditionalItems = () => {
    if (this.state.order.attributes['additional-items'].length > 0) {
      var price = 0;
      var additionalItems = this.state.order.attributes['additional-items'].map((item, key) => {
        price = price + item['unit-price'];
        return (
          <div key={key} className="receipt-item receipt-additional">
            <p>{item.name}</p>
            <p></p>
            <p>{ToCurrency(item['unit-price'])}</p>
          </div>
        )
      })

      return (
        <div className="receipt-items">
          { additionalItems }
          <hr style={{marginTop: "0.5em", marginBottom: "0.5em"}} />
          <div className="receipt-item receipt-additional">
            <div>
              ADICIONALES
            </div>
            <p>
            </p>
            <p>
              { ToCurrency(price) }
            </p>
          </div>

        </div>
      )
    }
  }

  render() {
    var { attributes } = this.state.order;
    var total = 0;
    this.state.order.attributes.products.forEach((product) => {
      total += product.total;
    });
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    console.log(currentUser)
    let currentSubscription = Object.values(currentUser.attributes.subscription.summary)
    currentSubscription.forEach((product) => {
      total += product.total;
    });
    return(
      <div className="dashboard-container">
        <div className="dashboard-menu">
        </div>
        <div className="user-welcome-container">
          <div className="welcome-container">
            <div className="welcome-intro">
              <span className="welcome-intro-title">
                BIENVENIDO
              </span>
              <span className="welcome-intro-user dark-blue-item">
                {attributes.user.name}
              </span>
              <span className="welcome-intro-email">
                {attributes.user.email}
              </span>
            </div>
            <p className="welcome-message dark-blue-item">
              Esta es tu nueva suscripción a Boxmi.
            </p>
            <div className="welcome-message">
              <p className="dark-blue-item">Ahora puedes ingresar a tu dashboard para:</p>
              <div className="margin-top-1 dark-blue-item small-item"><div className="welcome-message-number">1</div>Administrar o consultar tu inventario.</div>
              <div className="margin-top-1 dark-blue-item small-item"><div className="welcome-message-number">2</div>Contratar nuevos servicios.</div>
              <div className="margin-top-1 dark-blue-item small-item"><div className="welcome-message-number">3</div>Administrar tu información de pago.</div>
            </div>
          </div>
          <div className="receipt-container">
            <div className="receipt-box btn-box">
              <div className="receipt-delivery">
                <span>{ this.renderSubscriprionTitle() }</span>
                <img src={boxIcon} alt="box" />
              </div>

              <div className="receipt-items">
                { this.renderProducts() }
                { this.renderCurrentSubsctription() }
              </div>
              <hr style={{marginTop: "0.5em", marginBottom: "0.5em"}} />

              <div className="receipt-item">
                <p style={{fontSize: "1.5em"}}>TOTAL</p>
                <p></p>
                <p style={{fontSize: "1.5em"}}>{ToCurrency(total)}</p>
              </div>

              { this.renderAdditionalItems() }

              {
                this.state.isCouponValid ?
                <div style={{padding: "0 1vw"}} className="receipt-item red-box">
                  <p className="receipt-item-label"><span>1 MES GRATIS</span></p>
                  <p></p>
                  <p className="receipt-item-value">
                    <img src={couponIcon} alt="coupon" />
                  </p>
                </div> : ""
              }

            </div>

            <div className="receipt-box btn-box">
              <div className="receipt-delivery">
                <span>TU CITA</span>
                <img src={truckIcon} alt="truck" />
              </div>

              <div className="receipt-address">
                <div>
                  <img src={locationIcon} alt="location" />
                  <span className="receipt-address-text">
                    { attributes['shipping-address']['street'] }&nbsp;
                    { attributes['shipping-address']['ext-number'] },&nbsp;
                    { attributes['shipping-address']['postal-code'] }, { attributes['shipping-address'].suburb}</span>
                </div>
                <div>
                  <img src={calendarIcon} alt="calendar" />
                  <span className="receipt-address-text">{ attributes['shipping-date']}</span>
                </div>
              </div>
            </div>
            <div style={{padding: "0"}} className="receipt-box pointer btn-box" onClick={() => this.goToDashboard()}>
              <p className="receipt-action">INGRESA A TU <span className="red-item">DASHBOARD</span></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
