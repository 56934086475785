import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
export default class CustomerStorable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isStorableEdit: false,
      currentUser: {},
    };
  }

  componentDidMount() {
    const { storable } = this.props;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ currentUser: currentUser });
    if (!storable.storable_id) {
      this.setState({ isStorableEdit: true });
    }
  }

  edit(value) {
    this.setState({ isStorableEdit: value });
  }
  // setStorableType(value) {
  //   const { storable, updateStorable } = this.props;
  //   const updatedStorable = {
  //     ...storable,
  //     storable_type:
  //       storable.storable_type === "Box"
  //         ? (storable.storable_type = "StorageItem")
  //         : (storable.storable_type = "Box"),
  //   };
  //   updateStorable(updatedStorable);
  // }

  handleChange = (event) => {
    const { name, value, files } = event.target;
    const { storable, updateStorable } = this.props;

    // Crea un nuevo objeto storable con el campo actualizado
    const updatedStorable = {
      ...storable,
      [name]: name === "attachments" ? files : value,
      isFileAttached: name === "attachments",
    };
    // Llama a la función de actualización pasando el nuevo objeto storable
    updateStorable(updatedStorable);
  };
  render() {
    const { storable, handleRemoveStorable, handleShowModal } = this.props;
    return (
      <div className="mb-3">
        <div className={`row ${this.state.isStorableEdit ? "" : "d-none"}`}>
          <div className="col-12">
            <div className="showcase-card ml-3">
              <div className="w-100">
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap">
                    {/* <div
                      className="showcase-card storable-type d-flex justify-content-around align-items-center cursor-pointer"
                      onClick={() => this.setStorableType()}
                      style={{ minWidth: "115px" }}
                    >
                      <img
                        src={storable.storable_type === "Box" ? boxIcon : objectIcon}
                        width="40"
                      />
                      <span className="text-wrap">
                        {storable.storable_type === "Box" ? "Caja" : "Storable Item"}
                      </span>
                    </div> */}
                    <div className="">
                      <div className="">
                        <span className="font-weight-bold text-small">
                          Objeto:{" "}
                        </span>
                        <input
                          type="text"
                          name="object_name"
                          value={storable.object_name}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      {/* <div className="">
                        <span className="font-weight-bold text-small">
                          Descripcion:{" "}
                        </span>
                        <div>
                          <input
                            type="text"
                            name="boxmi_description"
                            value={storable.boxmi_description}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="ml-4">
                      <div>
                        <span>Archivo:</span>
                        <div>
                          <input
                            type="file"
                            name="attachments"
                            accept="image/*"
                            capture="user"
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mt-2">
                        {/* <button
                          className="btn btn-danger"
                          onClick={handleRemoveStorable}
                        >
                          Eliminar
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-column align-items-end">
                    {/* <span className="">Espacio: </span>
                    <div className="h3 font-weight-bold">
                      <input
                        type="text"
                        name="space"
                        value={storable.space}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div> */}

                    <div>
                      <div className="object-size">
                        <input
                          type="number"
                          name="width"
                          step=".1"
                          value={storable.width}
                          onChange={(e) => this.handleChange(e)}
                        />{" "}
                        m x
                        <input
                          type="number"
                          name="height"
                          step=".1"
                          value={storable.height}
                          onChange={(e) => this.handleChange(e)}
                          className="ml-1"
                        />{" "}
                        m x
                        <input
                          type="number"
                          name="long"
                          step=".1"
                          value={storable.long}
                          onChange={(e) => this.handleChange(e)}
                          className="ml-1"
                        />{" "}
                        m
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary mt-2"
                        type="submit"
                        onClick={() => this.edit(false)}
                      >
                        {" "}
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-12 col-sm-4 col-md-2">
            <div
              className="form-group d-flex"
              style={{ flexDirection: "column" }}
            >
              <span>Tipo:</span>
              <select
                name="storable_type"
                onChange={(e) => this.handleChange(e)}
                value={storable.storable_type}
              >
                <option value="Box">Box</option>
                <option value="StorageItem">Storage Item</option>
              </select>
            </div>
          </div> */}
        </div>

        <div className={`row ${!this.state.isStorableEdit ? "" : "d-none"}`}>
          <div className="col-12">
            <div className="showcase-card ml-3">
              <div className="w-100">
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap">
                    {/* <div className="showcase-card storable-type d-flex justify-content-center align-items-center">
                      <img
                        src={storable.storable_type === "Box" ? boxIcon : objectIcon}
                        width="40"
                      />
                    </div> */}
                    <div className="">
                      <div className="">
                        <span className="font-weight-bold text-small">
                          Objeto:{" "}
                        </span>
                        {storable.object_name}
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="font-weight-bold text-small mr-2">
                          Archivo:{" "}
                        </span>
                        {storable.attachments.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => handleShowModal()}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        ) : (
                          "N/D"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-column align-items-end">
                    {/* <span className="">Espacio: </span>
                    <div className="h3 font-weight-bold">
                      {storable.space || "N/D"}
                    </div> */}
                    <div>
                      <div className="pill">
                        {storable.width} m x {storable.height} m x{" "}
                        {storable.long} m
                      </div>
                    </div>
                    <div className="d-flex mt-2">
                      <button
                        className="btn btn-danger mr-2"
                        onClick={handleRemoveStorable}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => this.edit(true)}
                      >
                        {" "}
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
