import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'

export default class Whatsapp extends React.Component {
  render() {
    return (
      <a className="floating-item" target="_blank" rel="noopener noreferrer" href="https://wa.me/528110039250"><FontAwesomeIcon icon={faWhatsappSquare} /></a>
    )
  }
}
