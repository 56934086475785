import React from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { axios_instance } from "components/axios";
import { resources } from "components/string-resources";
import CustomTable from "components/table";
import DashboardMenu from "templates/common/dashboard-menu";

const orderColumns = [
  {
    Header: "id",
    accesor: (d) => d,
    id: "id",
    show: false,
  },
  {
    Header: "Nombre",
    accessor: (d) => `${d.attributes.m2} m²`,
    id: "product_name",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
    // Cell: ({value}) => ( value ? resources.order.type[value] : ""),
    // filterMethod: (filter, row) => {
    //   if (filter.value === "all") {
    //     return true;
    //   }
    //   if (filter.value === "active") {
    //     return row[filter.id] === true;
    //   }
    //   return row[filter.id] === false;
    // },
    // Filter: ({ filter, onChange }) =>
    //   <select
    //     onChange={event => onChange(event.target.value)}
    //     style={{ width: "100%" }}
    //     value={filter ? filter.value : "all"}
    //   >
    //     <option value="all">Todos</option>
    //     <option value="assign">Asignar</option>
    //     <option value="pickup">Recoger</option>
    //     <option value="return">Regresar</option>
    //     <option value="cancel">Cancelar</option>
    //     <option value="assign_quote">Cotización</option>
    //   </select>
  },
  // {
  //   Header: "M1",
  //   accessor: (d) => d.attributes["m1"],
  //   id: "m1",
  //   style: {
  //     cursor: "pointer",
  //     textAlign: "Center",
  //   },
  //   filterable: false,
  // },
  {
    Header: "Metros cuadrados",
    accessor: (d) => d.attributes["m2"],
    id: "m2",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Precio",
    accessor: (d) => d.attributes["price"],
    id: "price",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Precio de Envio",
    accessor: (d) => d.attributes["freight-price"],
    id: "freight_price",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Creado el",
    accessor: (d) => d.attributes["created-at"],
    id: "created_at",
    style: {
      cursor: "pointer",
    },
    filterable: false,
  },
];

export default class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
      loading: true,
      page: 1,
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize,
    };

    if (state.sorted.length) {
      state.sorted.forEach(function (sort) {
        let sortBy = sort.id;
        params[sortBy] = sort.desc ? "desc" : "asc";
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function (filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance
      .get("/v2/product_v2s", { params })
      .then((res) => {
        if (res.data.data) {
          this.setState({
            data: res.data.data,
            loading: false,
            // pages: res.data.meta.pagination["total-pages"],
          });
          console.log(res.data.data);
        }
      })
      .catch(() => {});
  };

  addProduct = () => {
    this.props.history.push("/admin/products/new");
  };

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content w-100">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="dashboard-content-header-title">Productos</h3>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.addProduct}
                  >
                    + Agregar Producto
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-content-body w-100">
            <div className="row" style={{ minWidth: "100%" }}>
              <div className="col-sm-12 px-0 w-100">
                <CustomTable
                  {...this.props}
                  columns={orderColumns}
                  data={data}
                  pages={pages}
                  loading={loading}
                  manual
                  onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
                  filterable
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: "shipping_date",
                      desc: true,
                    },
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (column.Header) {
                          this.props.history.push(
                            `/admin/products/${rowInfo.original.id}`,
                            [rowInfo.original]
                          );
                        } else {
                          handleOriginal();
                        }
                      },
                    };
                  }}
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
