import React from "react";
import { NotificationManager } from 'react-notifications';
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import QrModal from 'templates/modals/qr-modal';
import DashboardMenu from 'templates/common/dashboard-menu';
import Footer from 'templates/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

export default class CancelBox extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      cameraModal:{
        visibility: false,
        products: [],
        currentProduct: {
          product: "",
          id: 0,
          label: ""
        }
      },
      qrModal:{
        visibility: false,
        products: [],
        type: "cancel",
        currentProduct: {
          product: "",
          id: 0,
          label: ""
        }
      },
      products:[],
      order: {
        attributes: {
          products:[]
        }
      }
    };
  }

  componentDidMount() {
    showLoader();
    var order;
    var products = []
    var id = 0
    if (this.props.location.state === undefined) {
      axios_instance.get(`/v1/orders/${this.props.match.params.id}`).then((response) => {
        order = response.data.data
        order.attributes.products.map(product => {
          return Array.from(Array(product.quantity)).map((e, i) => {
            var label = "Producto " + product.size + " " + (i + 1);
            var picture = {product: "product-" + product.size + "-" + i, id: id += 1, label};
            products.push(picture);
            return picture
          })
        });
        this.setState({ order, products })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      order = this.props.location.state[0]
      order.attributes.products.map(product => {
        return Array.from(Array(product.quantity)).map((e, i) => {
          var label = "Producto " + product.size + " " + (i + 1);
          var picture = {product: "product-" + product.size + "-" + i, id: id += 1, label};
          products.push(picture);
          return picture
        })
      });
      this.setState({ order, products })
      hideLoader();
    }
  }

  openQrModal = (product) => {
    product = 'product-' + product.size + '-' + product.number;
    var products = this.state.products
    var currentProduct = products.filter((p) => { return p.product === product })[0]
    this.createProduct(currentProduct, "qrModal");
  }

  closeQrModal = () => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: false }}));
  }

  cancelBox = (product, productType) => {
    var type = productType.type === "boxes" ? "Box" : "StorageItem"
    this.setState((prevState) => ({
      [product.product]: { productId: productType.id, productType: type, ...prevState[product.product], },
    }));
    this.nextProduct(product, "qrModal");
  }

  createProduct = (product, modal) => {
    if (this.state[product.product]) {
      this.setState((prevState) => ({[modal]: {visibility: true, currentProduct: product, type: "pickup_pending", products: this.state.products}}))
    } else {
      this.setState((prevState) => ({[modal]: {visibility: true, currentProduct: product, type: "pickup_pending", products: this.state.products}, [product.product]: {images: [], counter: 0} }))
    }
  }

  nextProduct = (currentProduct, modal) => {
    var id = currentProduct.id + 1
    var products = this.state.products
    if (modal === "qrModal" && id > this.state.products.length) {
      return true
    } else if (modal === "cameraModal" && id > this.state.products.length) {
      id = id - this.state.products.length
    }
    var nextProduct = products.filter((p) => { return p.id === id })[0]
    this.createProduct(nextProduct, modal);
  }

  lastProduct = (currentProduct, modal) => {
    var id = currentProduct.id - 1
    if (id < 1) {
      id = this.state.products.length
    }
    var products = this.state.products
    var nextProduct = products.filter((p) => { return p.id === id })[0]
    this.createProduct(nextProduct, modal);
  }


  cancelBoxes = () => {
    showLoader();
    let formData = new FormData();
    var products = this.state.products.map((product) => {
      return product.product
    })
    formData.append('order[user_id]', this.state.order.attributes.user.id);
    products.map((product) => {
      if(this.state[product])
      {
        formData.append('order[subscription_boxes_attributes]['+ this.state[product].productId +'][storable_id]', this.state[product].productId );
        formData.append('order[subscription_boxes_attributes]['+ this.state[product].productId +'][storable_type]', this.state[product].productType );
      }
      return product;
    })
    axios_instance.post('/v1/orders/' + this.state.order.id + '/cancel_box_subscription', formData)
    .then((response) => {
      this.props.history.push('/dashboard')
      NotificationManager.success("Orden Completada", "", 3000);
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  render() {
    return(
      <div className="scrollable-content">
        <div className="dashboard-container">
          <DashboardMenu />
          <div className="dashboard-content">
            <div className="dashboard-content-header">
              <div className="dashboard-content-header-item">
                <span className="dashboard-content-header-title">Orden #{this.state.order.id}</span>
                <hr />
              </div>
            </div>
            <div className="dashboard-content-body">
              {
                this.state.order.attributes.products.map((product) => {
                  return(
                    Array.from(Array(product.quantity), (e, i) => {
                      const picture = "product-" + product.size + "-" + i;
                      return (
                        <div key={i} className="recolect-item">
                          <div className="recolect-item-header" onClick={() => {this.openQrModal({size: product.size, number: i })}}>
                            <h1 style={{paddingRight: "1vw"}}>Caja {product.size} #{i + 1}</h1>
                            {
                              this.state[picture] ? (
                                this.state[picture].productId ?
                                <FontAwesomeIcon icon={faQrcode} size="4x" style={{color: "#5E3DFC"}}/> :
                                  <FontAwesomeIcon icon={faQrcode} size="4x"/>
                              ) : <FontAwesomeIcon icon={faQrcode} size="4x"/>
                            }
                          </div>
                        </div>
                      )
                    })
                  )
                })
              }
              <button onClick={() => { this.cancelBoxes() }}>Submit</button>
              <QrModal
                display={this.state.qrModal}
                closeModal={this.closeQrModal}
                pickupBoxes={this.cancelBox}
                label={this.state.qrModal.currentProduct.label}/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
