import React from "react";
import DashboardUserMenu from 'templates/common/dashboard-user-menu';
import Wallet from 'templates/users/wallet'

export default class Receipts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showNewCardForm: false,
      userData: JSON.parse(localStorage.getItem('currentUser')),
      cards: [],
      date: "",
      cvc: "",
      cardNumber: "",
      name: "",
      exp_year: "",
      exp_month: "",
      card: {},
    };
  }

  componentDidMount() {

  }


  render() {
    return (
      <div className="dashboard-container dashboard-margin-top">
        <DashboardUserMenu />
        <div className="user-dashboard-container">
          <div className="user-dashboard-banner">
            <div className="flex-column">
              <div style={{marginLeft: '1em'}} className="flex-row">
                <span style={{fontSize: "2em"}} className="main-title red-item">MIS METODOS DE PAGO</span>
              </div>
            </div>
          </div>
          <div className="receipts-container">
          <div>
            <Wallet />
          </div>

          </div>
        </div>
      </div>
    )
  }
}
