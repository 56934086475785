import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from "templates/common/dashboard-menu";

export default class Warehouses extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      warehouse: {},
      isNew: false,
      name: "",
      active_status: true,
      title: ""
    };
  }

  async componentDidMount() {
    showLoader();
    var warehouseId = this.props.match.params.id
    var isNew = false
    let title = "Editar Almacén"
    let active_status = true;
    let name = "";
    if (warehouseId) {
      showLoader();
      await axios_instance.get(`/v1/warehouses/${warehouseId}`).then((response) => {
        let toEditWarehouse = response.data.data
        active_status = toEditWarehouse.attributes["active-status"]
        name = toEditWarehouse.attributes.name
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        hideLoader();
      })
    } else {
      isNew = true
      title = "Nuevo Almacén"
    }
    this.setState({
      active_status: active_status,
      name: name,
      title: title,
      isNew: isNew
    }, hideLoader())
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  submit = () => {
    showLoader();
    var warehouse = {
      warehouse: {
        name: this.state.name,
        active_status: this.state.active_status ? "active" : "inactive"
      }
    }
    if (this.state.isNew) {
      axios_instance.post('/v1/warehouses', warehouse).then((response) => {
        this.props.history.push(`/warehouses/${response.data.data.id}`)
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    } else {
      axios_instance.put('/v1/warehouses/' + this.props.match.params.id, warehouse).then(() => {
        this.props.history.push(`/warehouses/${this.props.match.params.id}`)
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <h3 className="dashboard-content-header-title">{this.state.title}</h3>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div>
              <span>Activo? </span>
              <input
                type="checkbox"
                name="active_status"
                onChange={this.handleChange}
                checked={this.state.active_status}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Nombre: </label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <button className="btn btn-primary" type="submit" onClick={this.submit}> Submit</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
