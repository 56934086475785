import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from 'templates/common/dashboard-menu';

export default class SpaceEdit extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      space: {
        name: "TBD"
      },
      warehouses: [],
      isNew: false,
      quantity: 0,
      active_status: "active",
      checked: true
    };
  }

  componentDidMount() {
    showLoader();
    var space = this.props.location.state === undefined ? "new" : this.props.location.state[0]
    if (space === "new") {
      axios_instance.get('/v1/warehouses')
      .then((response) => {
        this.setState({
          isNew: true,
          space: space,
          warehouses: response.data.data,
          active_status: false,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      axios_instance.get('/v1/warehouses')
      .then((response) => {
        const warehouseResponse = response.data.data
        axios_instance.get(`/v1/spaces/${this.props.match.params.id}`).then((response) => {
          space = response.data.data
          space.name = response.data.data.attributes.name
          this.setState({
            isNew: false,
            space: response.data.data,
            warehouses: warehouseResponse,
            warehouse_id: space.attributes["warehouse-id"],
            active_status: space.attributes["active-status"]
          })
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader()
      })
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(name === "name")
    {
      var space = this.state.space;
      space.name = value;
      this.setState({
        space: space
      })
    }
    this.setState({[name]: value});
  }

  submit = () => {
    showLoader();
    var active_status = this.state.active_status ? "active" : "inactive";
    var params = {
      quantity: this.state.quantity,
      space: {
        id: this.state.space.id,
        warehouse_id: this.state.warehouse_id,
        active_status: active_status,
        name: this.state.space.name
      }
    }
    if (this.state.isNew) {
      axios_instance.post('/v1/spaces', params).then((response) => {
        this.props.history.push('/qr_generator', [response.data.data])
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    } else {
      axios_instance.put('/v1/spaces/' + this.props.match.params.id, params).then(() => {
        this.props.history.push(`/warehouses/${this.state.warehouse_id}`)
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  render() {
    var warehouses = this.state.warehouses.map((warehouse, index) => {
      return <option key={index} value={warehouse.id}>Bodega {warehouse.id}</option>
    });
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="" style={{display: "flex", flexDirection:"row"}}>
            <span>Activo? </span>
            <input type="checkbox" name="active_status" onChange={this.handleChange} checked={this.state.active_status} />
          </div>
          <div className="form-group">
            <select
              style={{padding: "4vh 2vw"}}
              name="warehouse_id"
              id=""
              value={this.state.warehouse_id}
              onChange={this.handleChange}>
              <option value="">Selecciona una Bodega</option>
              { warehouses }
            </select>
          </div>
          {
            this.state.isNew ? "" :
            <div className="form-group" style={{flexDirection:"column"}}>
              <span>Nombre:</span>
              <input type="text" value={this.state.space.name} name="name" onChange={this.handleChange} />
            </div>
          }
          
          {
            this.state.isNew ?
            <div className="form-group" style={{flexDirection:"column"}}>
              <span>Cantidad:</span>
              <input type="number" value={this.state.quantity} name="quantity" onChange={this.handleChange} />
            </div> : ""
          }
          <div>
            <button type="submit" onClick={this.submit}> Submit</button>
          </div>
        </div>
      </div>
    )
  }
}
