import React from "react";
import moment from "moment";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import arrowDownK from "img/arrow-down-k.svg";

export default class CalendarSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: {},
      dateSelected: "",
      selectedHour: "",
      availableHours: ["00:00"],
      key_value: "",
    };
  }
  async componentDidMount() {
    var date, hours, hour;
    var order = JSON.parse(sessionStorage.getItem("order"));
    const { data } = await axios_instance.get("/v1/available_dates", {});
    const availableDates = data;
    var choosenDate = Object.keys(availableDates).filter((date) => {
      return availableDates[date].available === true;
    })[0];
    date = availableDates[choosenDate];
    hours = Object.keys(date).filter((hour) => {
      if (date[hour].available) {
        return date[hour].label;
      }
      return null;
    });
    hours.unshift("00:00");
    hour = "Selecciona tu horario";
    this.setState();
    if (order) {
      if (Object.keys(order.selectedDate).length > 0) {
        hour = order.selectedDate.hour;
        date = order.selectedDate.date;
        hours = order.selectedDate.availableHours;
        if (hours[0] !== "00:00") hours.unshift("00:00");
      }
    }

    this.setState({
      dates: availableDates,
      dateSelected: date,
      availableHours: hours,
      selectedHour: hour,
    });

    var selectedDate = {
      hour: hour,
      date: date,
      day: date.label,
      key_value: date.key_value,
      availableHours: hours,
    };
    this.props.selectDate(selectedDate);
  }

  selectDate = (date) => {
    if (date.available) {
      var hours = Object.keys(date).filter((hour) => {
        if (date[hour].available) {
          return date[hour].label;
        }
        return null;
      });
      hours.unshift("00:00");
      var hour = "Selecciona tu horario";
      this.setState({
        dateSelected: date,
        availableHours: hours,
        selectedHour: hour,
      });
      var selectedDate = {
        hour: hour,
        date: date,
        day: date.label,
        key_value: date.key_value,
        availableHours: hours,
      };
      this.props.selectDate(selectedDate);
    }
  };

  selectHour = (event) => {
    const target = event.target;
    const value = target.value;
    this.setState({ selectedHour: value });
    var selectedDate = {
      hour: value,
      day: this.state.dateSelected.label,
      date: this.state.dateSelected,
      availableHours: this.state.availableHours,
      key_value: this.state.dateSelected.key_value,
    };
    this.props.selectDate(selectedDate);
  };
  getMoreDates = () => {
    showLoader();
    var checkoutInit = JSON.parse(sessionStorage.getItem("checkoutInit"));
    var lastElement = Object.keys(this.state.dates)[
      Object.keys(this.state.dates).length - 1
    ];
    var newDate = moment(lastElement, "YYYY-MM-DD");
    axios_instance
      .get("/v1/next_available_weeks", { params: { date: newDate.day(7) } })
      .then((response) => {
        var data = response.data;
        var dates = { ...this.state.dates, ...data };
        checkoutInit.availableDates = { ...this.state.dates, ...data };
        sessionStorage.setItem("checkoutInit", JSON.stringify(checkoutInit));
        this.setState({ dates: dates });
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    var availableHours = this.state.availableHours.map((hour, index) => {
      if (hour !== "00:00") {
        if (this.state.dateSelected[hour].available) {
          return (
            <option key={index} value={this.state.dateSelected[hour].label}>
              {this.state.dateSelected[hour].label} hrs
            </option>
          );
        }
      } else {
        return (
          <option key={index} value={""}>
            Selecciona tu horario
          </option>
        );
      }
    });
    return (
      <div className="calendar">
        <div className="calendar-hour-selection p-2 p-md-0">
          <div style={{ marginBottom: "0.5em" }}>
            <div style={{ flex: "1 1 100%", alignItems: "center" }}>
              <span>Selecciona una hora para recibirnos </span>
            </div>
            <div
              style={{ flex: "1 1 100%", marginBottom: "0" }}
              className="form-group"
            >
              <select
                name="selectedHour"
                id=""
                value={this.state.selectedHour}
                onChange={this.selectHour}
              >
                {availableHours}
              </select>
            </div>
          </div>
          <div
            style={{ flex: "1 1 100%", fontSize: "0.8em", color: "#c3baba" }}
          >
            <span>
              Las horas disponibles pueden variar de acuerdo al dia seleccionado{" "}
            </span>
          </div>
        </div>
        <div className="cal-week-days">
          <p>D</p>
          <p>L</p>
          <p>M</p>
          <p>M</p>
          <p>J</p>
          <p>V</p>
          <p>S</p>
        </div>
        <div className="cal-day-items">
          {Object.keys(this.state.dates).map((date, index) => {
            return (
              <div
                key={index}
                className={
                  "day-item " +
                  (this.state.dates[date].available ? "" : "day-off ") +
                  (this.state.dateSelected.label ===
                  this.state.dates[date].label
                    ? "day-sel"
                    : "")
                }
                onClick={() => this.selectDate(this.state.dates[date])}
              >
                <p className="day-num">
                  {/* {JSON.stringify(this.state.dates[date].available)} */}
                  {this.state.dates[date].label.split(" ")[0]}
                </p>
                <p className="month">
                  {this.state.dates[date].label.split(" ")[1]}
                </p>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <img onClick={this.getMoreDates} src={arrowDownK} alt="boxPhoto" />
        </div>
      </div>
    );
  }
}
