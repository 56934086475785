import React from "react";
import moment from 'moment'
import { axios_instance } from  'components/axios';
import { resources } from 'components/string-resources';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { ToCurrency } from 'components/utils';
import DashboardUserMenu from 'templates/common/dashboard-user-menu';
import OrderIcon from 'img/order-ico-02.svg';

export default class Receipts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      subscription_next_billing_cycle: "",
      receipts: [
        {
          attributes:{
            amount: "",
            "billing-cycle-start": "",
            month: "",
            "event-type": "",
            "payment-method": {}
          }
        }
      ]
    }
  }

  componentDidMount() {
    showLoader();
    axios_instance.get('/v1/receipts').then((resp) => {
      var receipts = resp.data.data.map((receipt) => {
        receipt.attributes["month"] = resources.months[`${moment(receipt).format("MMMM")}`]
        receipt.attributes["billing-cycle-start"] = moment(receipt).format("YYYY/MM/DD")
        receipt.attributes["period"] = resources.months[`${moment(receipt).format("MMMM")}`]+" - "+resources.months[`${moment(receipt).add(1, 'months').format("MMMM")}`]
        return receipt
      })
      let subscription_next_billing_cycle = "Aún no se ha activado la suscripción";
      let currentUser = JSON.parse(localStorage.getItem('currentUser'))
      let billingDate = currentUser.attributes.subscription.next_billing_date
      if(receipts.length > 0)
      {
        subscription_next_billing_cycle = `Siguiente Mensualidad: ${moment(billingDate).format("YYYY/MMM/DD")}`
      }
      this.setState({receipts: receipts, subscription_next_billing_cycle: subscription_next_billing_cycle})
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }



  render() {
    return (
      <div className="dashboard-container dashboard-margin-top">
        <DashboardUserMenu />
        <div className="user-dashboard-container">
          <div className="user-dashboard-banner">
            <div className="flex-column">
              <div style={{marginLeft: '1em'}} className="flex-row">
                <span style={{fontSize: "2em"}} className="main-title red-item">HISTORIAL DE PAGOS</span>
              </div>
            </div>
          </div>
          <div className="">
            <div className="receipts-item">
              <div className="1 padding-2 dashboard-action">
                <img style={{marginRight: '1em'}} src={OrderIcon} alt="receipt" />
                <span className="dashboard-action-title">
                  {this.state.subscription_next_billing_cycle}
                </span>
              </div>
            </div>
            <table className="receipts-table btn-box">
              <tr>
                <th>
                  Estatus
                </th>
                <th>
                  Periodo
                </th>
                <th>
                  Metodo de pago
                </th>
                <th>
                  Cantidad
                </th>
              </tr>
              {
                this.state.receipts.map((receipt) => {
                  return(
                    <tr className="">
                      <td>
                        {receipt.attributes.status}
                      </td>
                      <td >
                        <span className="blue-item">{receipt.attributes["event-type"]}</span>
                        {
                          receipt.attributes["event-type"] === "order.paid" ?
                            <span>{receipt.attributes.concept}</span> : <span>Periodo {receipt.attributes["period"]}</span>
                        }
                      </td>
                      <td>
                        <div>
                          {receipt.attributes['payment-method'].name}
                        </div>
                        <div>Tarjeta <span style={{fontWeight: 'bold', color: '#7800F4'}}>{receipt.attributes['payment-method']['account-type']}</span> terminación <span style={{fontWeight: 'bold', color: '#7800F4'}}>{receipt.attributes['payment-method'].last4}</span> </div>
                        <div></div>
                      </td>
                      <td>
                        <div style={{fontWeight: 'bold', fontSize:'1.1rem', color: '#F74164'}}>{ToCurrency(receipt.attributes.amount)}</div>
                        <div style={{fontWeight: 'bold'}}>{moment(receipt.attributes['paid-at']).format('YYYY/MMM/DD - HH:mm')}</div>
                      </td>
                    </tr>
                  )
                })
              }
            </table>
          </div>
        </div>
      </div>
    )
  }
}
