import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import Geocode from "react-geocode";
import ShippingSection from "templates/checkout/shipping";
import CalendarSection from "templates/checkout/calendar";
import MapGoogle from "components/map";
import moment from "moment";

export default class EditOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imgKeys: [],
      images: [],
      currentWindowHeight: 0,
      planNumber: 0,
      planType: null,
      pricesOptions: [],
      plansOptions: [],
      shippingDate: null,
      selectedDate: {},
      shippingAddress: null,
      shippingErrors: false,
      showShippingErrors: false,
      availableDates: {},
      suburbs: null,
      orderV2: {
        product_id: "",
        plan_id: "",
        user_id: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        suburb: "",
        latitude: "",
        longitude: "",
        order_status: "",
        shipping_date: "",
      },
      center: {
        lat: 25.6866142,
        lng: -100.3161126,
      },
      zoom: 10,
      isMarkerShown: false,
    };
  }

  async componentDidMount() {
    showLoader();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const images = this.importAll(
      require.context("img/products-images", false, /\.(png|jpe?g|svg)$/)
    );
    const imgKeys = Object.keys(images);
    this.setState({ imgKeys, images });
    if (!currentUser) {
      this.props.history.push("/sign_in?redirect=quoting");
      return;
    }
    this.setState({
      currentWindowHeight: window.innerHeight,
    });
    window.addEventListener("resize", this.updateDimensions);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    // set response language. Defaults to english.
    Geocode.setLanguage("es");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("mx");

    // TODO: GET ORDER
    const orderV2 = {};
    await axios_instance.get("/v2/product_v2s", {}).then((response) => {
      let products = response.data.data;
      products = products.sort((a, b) => +a.attributes.m2 - b.attributes.m2);
      this.setState({ pricesOptions: products });
      if (orderV2) {
        const selectedPrice = response.data.data.findIndex(
          (price) => price.id === orderV2.product_id
        );
        if (selectedPrice > -1) {
          this.setState({
            orderV2,
            planType: orderV2.plan_id,
            planNumber: selectedPrice,
          });
        }
      }
    });
    await axios_instance.get("/v2/plans", {}).then((response) => {
      this.setState({ plansOptions: response.data.data });
    });
    const response = await axios_instance.get(
      `/v2/order_v2s/${this.props.match.params.id}`
    );
    const order = response.data.data;
    const iPrice = this.state.pricesOptions.findIndex(
      (price) =>
        price.attributes.m2.toString() === order.attributes["product-name"]
    );
    const plan = this.state.plansOptions.find(
      (plan) =>
        plan.attributes["discount-percentage"] ===
        order.attributes["discount-percentage"]
    );
    const stateOrder = {
      ...this.state.orderV2,
      product_id: this.state.pricesOptions[+iPrice].id,
      plan_id: plan.id,
      user_id: order.attributes.user.id,
      street: order.attributes.street,
      ext_number: order.attributes["ext-number"],
      int_number: order.attributes["int-number"],
      postal_code: order.attributes["postal-code"],
      suburb: order.attributes["suburb"],
      latitude: order.attributes["latitude"],
      longitude: order.attributes["longitude"],
      order_status: "",
      shipping_date: order.attributes["shipping-date"],
    };
    this.setState({
      planNumber: +iPrice,
      planType: plan.id,
      orderV2: stateOrder,
    });
    hideLoader();
  }

  selectedPriceOption() {
    return this.state.pricesOptions[this.state.planNumber];
  }

  importAll(r) {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  selectedPriceName() {
    const name =
      this.state.pricesOptions[this.state.planNumber]?.attributes["m2"];
    if (name) {
      return <span>{name} m²</span>;
    }
    return null;
  }
  incrementPlan = () => {
    if (this.state.planNumber < this.state.pricesOptions.length - 1) {
      const value = +this.state.planNumber + 1;
      this.setState({ planNumber: value });
      const orderV2 = {
        ...this.state.orderV2,
        product_id: this.state.pricesOptions[value].id,
      };
      this.setState({ orderV2 });
    }
  };
  decrementPlan = () => {
    if (this.state.planNumber > 0) {
      const value = +this.state.planNumber - 1;
      this.setState({ planNumber: value });
      const orderV2 = {
        ...this.state.orderV2,
        product_id: this.state.pricesOptions[value].id,
      };
      this.setState({ orderV2 });
    }
  };

  selectedPrice() {
    const price =
      this.state.pricesOptions[this.state.planNumber]?.attributes?.price;
    const plan = this.selectedPlanOption();
    let discountPercentage = plan?.attributes["discount-percentage"];
    if (!discountPercentage) discountPercentage = 0;
    const discount = price * (discountPercentage / 100);
    const priceWDiscount = price - discount;
    if (priceWDiscount) {
      return <span>{priceWDiscount}</span>;
    }
    return null;
  }

  discountInfo() {
    const price =
      this.state.pricesOptions[this.state.planNumber]?.attributes?.price;
    const plan = this.selectedPlanOption();
    let discountPercentage = plan?.attributes["discount-percentage"];
    if (!discountPercentage) discountPercentage = 0;
    const discount = price * (discountPercentage / 100);
    if (discount) {
      return (
        <div
          className="card-header text-center text-white py-1"
          style={{ backgroundColor: "#fa3e61" }}
        >
          Estas ahorrando ${discount}/mes
        </div>
      );
    }
    return <span></span>;
  }

  selectedPriceImage() {
    if (this.state.imgKeys.length) {
      const m2 = this.state.pricesOptions.find(
        (opt) => opt.id === this.selectedPriceOption().id
      )?.attributes?.m2;
      const imgKey = `alpha_${m2}.png`;
      // this.state.imgKeys[+this.state.planNumber % +this.state.imgKeys.length];
      return this.state.images[imgKey];
    }
    return "error";
  }

  selectedPlanOption() {
    return this.state.plansOptions.find((p) => p.id === this.state.planType);
  }

  selectedFreightPrice() {
    const price =
      this.state.pricesOptions[this.state.planNumber]?.attributes[
        "freight-price"
      ];
    if (price) {
      return <span>{price}</span>;
    }
    return <span>{0}</span>;
  }

  selectPlanType = (type) => {
    const order = { ...this.state.orderV2, plan_id: type };
    this.setState({ planType: type, orderV2: order });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  setShippingAddress = (shipping) => {
    const formatedAddress = `${shipping.postal_code} ${shipping.suburb} ${shipping.street} ${shipping.ext_number}`;
    Geocode.fromAddress(formatedAddress).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ center: { lat, lng }, isMarkerShown: true, zoom: 16 });
        const orderV2 = {
          ...this.state.orderV2,
          street: shipping.street,
          ext_number: shipping.ext_number,
          int_number: shipping.int_number,
          postal_code: shipping.postal_code,
          latitude: lat,
          longitude: lng,
        };
        this.setState({ orderV2 });
      },
      (error) => {}
    );
    this.setState({ shippingAddress: shipping });
    this.setState({ shippingErrors: false });
  };

  shippingErrors = () => {
    this.setState({ shippingErrors: true });
  };

  selectDate = (date) => {
    var d = moment(date.key_value);
    if (date.hour !== "Selecciona tu horario" && date.hour.length > 0) {
      d.add(parseInt(date.hour.split(":")[0]), "hour");
      d.set("minute", parseInt(date.hour.split(":")[1]));
    }
    // 'YYYY-MM-DDT-h:mm'
    this.setState({ shippingDate: d.format() });
    var order = {
      shipping: this.state.shipping,
      selectedDate: date,
      card: this.state.card,
    };
  };

  async updatePlan() {
    const { data } = await axios_instance.post(`/v2/order_v2s/`, {
      order_v2: {
        plan_id: this.state.orderV2.plan_id,
        product_id: this.state.orderV2.product_id,
        user_id: this.state.orderV2.user_id,
        street: this.state.orderV2.street,
        ext_number: this.state.orderV2.ext_number,
        int_number: this.state.orderV2.int_number,
        postal_code: this.state.orderV2.postal_code,
        suburb: this.state.orderV2.suburb,
        latitude: this.state.orderV2.latitude,
        longitude: this.state.orderV2.longitude,
        order_status: this.state.orderV2.order_status,
        shipping_date: this.state.shippingDate,
      },
    });
    const userId = data.data.attributes.user.id;
    this.props.history.push(`/admin/users/${userId}`);
  }

  render() {
    return (
      <div className="main-container">
        <div className="container">
          <div className="row my-4">
            <div className="col-12 col-md-6">
              <h4 className="font-weight-bold mt-2 text-uppercase">
                Elige el tamaño de almacenamiento
              </h4>
              <div className="quote-card mb-4">
                <div>
                  Puedes seleccionar el tamaño navegando con las flechas o
                  deslizando la barra de abajo.
                </div>
                <div className="quote-selector">
                  <button className="prev-btn btn" onClick={this.decrementPlan}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <h1 className="value-area">{this.selectedPriceName()}</h1>
                  <button className="next-btn btn" onClick={this.incrementPlan}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
                <div>Tamaño Mediano</div>

                <div className="price my-2">${this.selectedPrice()}/mes</div>
                <div className="w-100 px-2">
                  <input
                    type="range"
                    step="1"
                    min="0"
                    max={this.state.pricesOptions.length - 1}
                    className="slider"
                    id="myRange"
                    value={this.state.planNumber}
                    name="planNumber"
                    onChange={this.handleChange}
                    style={{ backgroundColor: "#f7f5f6" }}
                  />
                </div>
                <div className="d-flex w-100">
                  <span className="mr-1">
                    Si deseas cotizar menos de 1 mt² o más de 25 mts²
                    contactanos
                  </span>
                  <Link
                    style={{ color: "#5e3dfc" }}
                    to={{ pathname: "https://wa.me/528110039250" }}
                    target="_blank"
                  >
                    {" "}
                    aqui
                  </Link>
                  .
                </div>
              </div>

              <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                Selecciona tu plan
              </h4>
              <div className="row my-4">
                {this.state.plansOptions.map((plan) => {
                  return (
                    <div
                      className="col btn"
                      onClick={() => this.selectPlanType(plan.id)}
                      key={plan.id}
                    >
                      <div
                        className="quote-card h-100"
                        style={{
                          border: "3px solid",
                          borderColor:
                            this.state.planType === plan.id
                              ? "black"
                              : "transparent",
                        }}
                      >
                        <h4 className="font-weight-bold mb-0">
                          {plan.attributes.title}
                        </h4>
                        <div>{plan.attributes.description}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className={this.state.planType !== null ? "" : "notSelectable"}
              >
                <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                  Dirección de recolección
                </h4>
                <div className="mb-4">¿A donde vamos?</div>
                <ShippingSection
                  setShippingAddress={this.setShippingAddress}
                  setShippingErrors={this.shippingErrors}
                  showShippingErrors={this.state.showShippingErrors}
                  suburbs={this.state.suburbs}
                  icon={false}
                />
              </div>

              <div
                className={this.state.planType !== null ? "" : "notSelectable"}
              >
                <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                  Agenda tu cita de recolección
                </h4>
                <div>¿Cuando vamos?</div>
                <CalendarSection
                  availableDates={this.state.availableDates}
                  selectDate={this.selectDate}
                ></CalendarSection>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="showcase-card">
                <div className="d-flex justify-content-between w-100">
                  <h1>{this.selectedPriceName()}</h1>
                  <h1>${this.selectedPrice()}/mes</h1>
                </div>
                <div
                  className="my-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <img
                    alt=""
                    src={this.selectedPriceImage()}
                    className=""
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      transform: "scale(2)",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between w-100">
                  *Reduciremos su tarifa mensual si necesita menos espacio.
                </div>
              </div>

              <div
                className="mt-4"
                style={{ top: "140px", position: "sticky" }}
              >
                <div className="card mt-4 p-0" style={{ boxShadow: "none" }}>
                {this.discountInfo()}
                  <div className="p-3 d-flex justify-content-between">
                    <div className="font-weight-bold">
                      ALMACENAMIENTO DE {this.selectedPriceName()}
                    </div>
                    <div className="font-weight-bold">
                      ${this.selectedPrice()}/mes
                    </div>
                  </div>

                  <div
                    className="d-flex flex-row justify-content-between p-3"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="d-flex flex-column">
                      <span className="font-weight-bold text-uppercase">
                        Costo de transporte
                      </span>
                      Costo de transporte apartir de 6x6
                    </div>
                    <span className="font-weight-bold">
                      ${this.selectedFreightPrice()} MXN
                    </span>
                  </div>

                  <div className="p-3 d-flex justify-content-between">
                    <div className="" style={{ fontSize: "11px" }}>
                      Tu almacenamiento incluye: seguridad 24/7, espacio
                      climatizado, y un inventario en linea con fotogragfias.
                    </div>
                    <div className="font-weight-bold text-secondary">
                      {/* <strike>$500/mes</strike> */}
                    </div>
                  </div>
                </div>

                <div
                  className="card mt-4 p-0 d-none d-md-block"
                  style={{
                    height: "300px",
                    maxWidth: "540px",
                    backgroundColor: "grey",
                    boxShadow: "none",
                  }}
                >
                  <MapGoogle
                    center={this.state.center}
                    zoom={this.state.zoom}
                    isMarkerShown={this.state.isMarkerShown}
                  ></MapGoogle>
                  {/* <img
                    style={{ height: "300px", maxWidth: "540px" }}
                    src={googleMap}
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div
                className="insurance-card mt-4 btn"
                style={{ backgroundColor: "#fa3f62" }}
                onClick={() => this.updatePlan()}
              >
                <div className="d-flex justify-content-center w-100">
                  <h4
                    className="text-white mb-0 font-weight-light"
                    style={{ textTransform: "none" }}
                  >
                    Actualizar
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
