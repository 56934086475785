import React from "react";
import boxIcon from "img/box-icon.svg";
import objectIcon from "img/object-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

export default class SubscriptionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubscriptionBoxEdit: false,
      currentUser: {},
    };
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ currentUser: currentUser });
  }

  edit(value) {
    this.setState({ isSubscriptionBoxEdit: value });
  }
  setStorableType(value) {
    const { box, updateBox } = this.props;
    const updatedBox = {
      ...box,
      storable_type:
        box.storable_type === "Box"
          ? (box.storable_type = "StorageItem")
          : (box.storable_type = "Box"),
    };
    updateBox(updatedBox);
  }

  handleChange = (event) => {
    const { name, value, files } = event.target;
    const { box, updateBox } = this.props;

    // Crea un nuevo objeto box con el campo actualizado
    const updatedBox = {
      ...box,
      [name]: name === "attachments" ? files : value,
      isFileAttached: name === "attachments",
    };
    // Llama a la función de actualización pasando el nuevo objeto storable
    updateBox(updatedBox);
  };
  render() {
    const { box, handleRemoveBox, handleShowModal } = this.props;
    return (
      <div>
        <div
          className={`row ${this.state.isSubscriptionBoxEdit ? "" : "d-none"}`}
        >
          <div className="col-12">
            <div className="showcase-card ml-3">
              <div className="w-100">
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap">
                    <div
                      className="showcase-card storable-type d-flex justify-content-around align-items-center cursor-pointer"
                      onClick={() => this.setStorableType()}
                      style={{ minWidth: "115px" }}
                    >
                      <img
                        src={box.storable_type === "Box" ? boxIcon : objectIcon}
                        width="40"
                      />
                      <span className="text-wrap">
                        {box.storable_type === "Box" ? "Caja" : "Storable Item"}
                      </span>
                    </div>
                    <div className="">
                      <div className="">
                        <span className="font-weight-bold text-small">
                          Objeto:{" "}
                        </span>
                        <input
                          type="text"
                          name="title"
                          value={box.title}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </div>
                      <div className="">
                        <span className="font-weight-bold text-small">
                          Descripcion:{" "}
                        </span>
                        <div>
                          <input
                            type="text"
                            name="boxmi_description"
                            value={box.boxmi_description}
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ml-4">
                      <div>
                        <span>Archivo:</span>
                        <div>
                          <input
                            type="file"
                            name="attachments"
                            accept="image/*"
                            capture="user"
                            onChange={(e) => this.handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-md-3 d-flex align-items-center justify-content-center mt-2">
                        {/* <button
                          className="btn btn-danger"
                          onClick={handleRemoveBox}
                        >
                          Eliminar
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-column align-items-end">
                    <span className="">Espacio: </span>
                    <div className="h3 font-weight-bold">
                      <input
                        type="text"
                        name="space"
                        value={box.space}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>

                    <div>
                      <div className="object-size">
                        <input
                          type="number"
                          name="width"
                          step=".1"
                          value={box.width}
                          onChange={(e) => this.handleChange(e)}
                        />{" "}
                        m x
                        <input
                          type="number"
                          name="height"
                          step=".1"
                          value={box.height}
                          onChange={(e) => this.handleChange(e)}
                          className="ml-1"
                        />{" "}
                        m x
                        <input
                          type="number"
                          name="long"
                          step=".1"
                          value={box.long}
                          onChange={(e) => this.handleChange(e)}
                          className="ml-1"
                        />{" "}
                        m
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary mt-2"
                        type="submit"
                        onClick={() => this.edit(false)}
                      >
                        {" "}
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-12 col-sm-4 col-md-2">
            <div
              className="form-group d-flex"
              style={{ flexDirection: "column" }}
            >
              <span>Tipo:</span>
              <select
                name="storable_type"
                onChange={(e) => this.handleChange(e)}
                value={box.storable_type}
              >
                <option value="Box">Box</option>
                <option value="StorageItem">Storage Item</option>
              </select>
            </div>
          </div> */}
        </div>

        <div
          className={`row ${!this.state.isSubscriptionBoxEdit ? "" : "d-none"}`}
        >
          <div className="col-12">
            <div className="showcase-card ml-3">
              <div className="w-100">
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex flex-wrap">
                    <div className="showcase-card storable-type d-flex justify-content-center align-items-center">
                      <img
                        src={box.storable_type === "Box" ? boxIcon : objectIcon}
                        width="40"
                      />
                    </div>
                    <div className="">
                      <div className="">
                        <span className="font-weight-bold text-small">
                          Objeto:{" "}
                        </span>
                        {box.title}
                      </div>
                      <div className="">
                        <span className="font-weight-bold text-small">
                          Descripcion:{" "}
                        </span>
                        <div>{box.boxmi_description || "N/D"}</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="font-weight-bold text-small mr-2">
                          Archivo:{" "}
                        </span>
                        {box.attachments.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => handleShowModal()}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        ) : (
                          "N/D"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-column align-items-end">
                    <span className="">Espacio: </span>
                    <div className="h3 font-weight-bold">
                      {box.space || "N/D"}
                    </div>
                    <div>
                      <div className="pill">
                        {box.width} m x {box.height} m x {box.long} m
                      </div>
                    </div>
                    <div>
                      {(this.state.currentUser?.attributes?.role === "admin" ||
                        this.state.currentUser?.attributes?.role ===
                          "operator") && (
                        <div className="mt-2">
                          <button
                            className="btn btn-danger mr-2"
                            onClick={handleRemoveBox}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => this.edit(true)}
                          >
                            {" "}
                            Editar
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
