import React from "react";
import arrowDown from "img/arrow-down.svg";

export default class UserStorable extends React.Component {
  componentDidMount() {}

  address() {
    const order = this.props.order;
    return `${order.suburb} ${order.street} ${order["ext-number"]}, CP: ${order["postal-code"]}`;
  }

  render() {
    return (
      <div className="user-dashboard-content-box p-3">
        <div className="user-dashboard-content-box-title d-flex flex-column">
          <div style={{ minHeight: "48px" }}>
            <h3>{this.props.order["product-name"]}</h3>
          </div>
          <div>
            <span>${this.props.order.price} MXN </span>
            <span style={{ fontSize: "14px" }}>
              + Envio (${this.props.order["freight-price"]} MXN)
            </span>
          </div>
          <div>Total: ${this.props.order.total} MXN</div>
          <div>Envio: {this.address()}</div>
        </div>
      </div>
    );
  }
}
