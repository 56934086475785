import React from "react";
import Modal from 'react-modal';

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay : {
    zIndex                : '999'
  }
};

export default class QuoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: null,
      size: null,
      additional_amount: 0,
      additional_concept: '',
      message: ''
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.postQuote({
      price: this.state.price,
      size: this.state.size,
      message: this.state.message,
      additional_amount: this.state.additional_amount,
      additional_concept: this.state.additional_concept
    });
  }

  render() {
    return(
      <Modal
        isOpen={this.props.modalVisibility}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Quote Modal">
          <form>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="size">Tamaño en M2 (Solo número)*</label>
                  <input type="number" name="size" id="size" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="price">Monto Mensual* (En Centavos)</label>
                  <input type="text" name="price" id="price" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="additional_concept">¿Algún Adicional? (Concepto, en centavos)</label>
                  <input type="text" name="additional_concept" id="additional_concept" className="form-control" placeholder="Segundo Boxero" onChange={this.handleChange} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="additional_amount">Cargo Único (En Centavos)</label>
                  <input type="text" name="additional_amount" id="additional_amount" className="form-control" onChange={this.handleChange} value={this.state.additional_amount} />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="message">Mensaje (OPCIONAL)</label>
                  <textarea rows="3" id="message" name="message" className="form-control" onChange={this.handleChange} />
                </div>
              </div>
              <div className="col-sm-12">
                <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Envíar</button>
                <button type="button" onClick={this.props.closeModal} className="btn btn-error">Cancelar</button>
              </div>
            </div>
          </form>
      </Modal>
    )
  }
}
