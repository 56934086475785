import React from 'react';

export default class Privacy extends React.Component {
  render() {
    return(
      <div className="common-container">
        <h2>Aviso de Privacidad</h2>
        <p>
          A fin de dar cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “Ley”) y con fundamento en los Artículos 8, 15, 16, 17, 18 y 36; BOXMI S.A.P.I de C.V. (“BOXMI”) con domicilio en Río Tigris 1039 Sur Int.C302 Colonia Jerónimo Siller, San Pedro Garza García, Nuevo León, México C.P. 66250 (el “Domicilio”), es responsable de la confidencialidad, uso y protección de la información personal que nos llegare a proporcionar a través de nuestras plataformas electrónicas.
        </p>
        <p>
          Por lo anterior su información personal será utilizada para fines de identificación en cualquier tipo de relación jurídica o de negocios que realice con nosotros, incluyendo, invitarle a participar en eventos, para fines mercadológicos, de prospección comercial y para dar cumplimiento a las obligaciones contraídas con usted.
        </p>
        <p>
          Para las finalidades anteriores, podríamos requerirle su nombre, dirección, teléfono, correo electrónico, estado civil, RFC, CURP, fecha y lugar de nacimiento, sexo, nacionalidad, edad, información crediticia y patrimonial ya que en caso de no contar con esta información no estaríamos en posibilidad de informarle sobre lo anterior.
        </p>
        <p>
          Usted tiene el derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o instruirnos cancelarlos cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al tratamiento de los mismos para fines específicos.
        </p>
        <p>
          Los mecanismos que se han implementado para el ejercicio de dichos derechos son a través de la presentación de la solicitud respectiva, la cual deberá incluir los datos que establece el Artículo 29 de la Ley, ya sea en:
        </p>
        <p>
          nuestras oficinas ubicadas en el Domicilio ante el Responsable del Tratamiento de los Datos Personales o
          en la siguiente dirección electrónica info@boxmi.mx
        </p>
        <p>
          Tendremos un plazo máximo de 5 días hábiles para atender su petición y le informaremos sobre la procedencia vía electrónica.
        </p>
        <p>
          Le informamos que sus datos personales no serán transferidos a terceros para fines distintos a los necesarios para brindarle oportunamente nuestros servicios salvaguardando su protección y confidencialidad, sin que para ello sea necesario obtener su autorización en términos del artículo 37 de la Ley.  Es importante informarle que no compartiremos su información personal con terceros si no son nuestras empresas afiliadas y/o subsidiarias, o algún tercero debidamente contratado con cláusulas de confidencialidad de información y de protección de datos personales.
        </p>
        <p>
          Si usted no manifiesta su oposición para que sus datos personales sean transferidos dentro de los primeros 3 días naturales de haber recibido el presente, se entenderá que ha otorgado su consentimiento para ello.
        </p>
        <p>
          El Responsable del Tratamiento de sus Datos Personales, el Director de Recursos Humanos, a su vez podrá nombrar a una o varias personas físicas terceras para que lo asistan, ambos ubicados en el Domicilio. Si desea mayor información, favor de comunicarse con los anteriores al teléfono [*] o enviar un correo electrónico a info@boxmi.mx.
        </p>
        <p>
          Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, las cuales les haremos llegar al último correo electrónico que nos haya proporcionado.
        </p>
        <p>
          Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a la siguiente dirección: <a href="mailto: info@boxmi.mx">info@boxmi.mx.</a>
        </p>
      </div>
    )
  }
}
