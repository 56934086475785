import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "css/dashboard.css";
import "css/user-dashboard.css";
import "css/custom-checkbox.css";
import "css/user-receipts.css";
import "css/notifications.css";
import "css/tooltip.css";
import "css/session.css";
import "css/qr-codes.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-notifications/lib/notifications.css";
import WebFont from "webfontloader";
import "react-table/react-table.css";
import store from "./redux/store";
import { Provider } from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

WebFont.load({
  google: {
    families: [
      "Montserrat:300,400,500,600",
      "Oswald:400,700",
      "Lato:100,300,400,900",
      "sans-serif",
    ],
  },
});
