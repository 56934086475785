import React from 'react';
import { axios_instance, SetAuthorization, BaseUrl } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import StringResources from 'components/string-resources';
import { validations } from 'components/validations';
import { NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: "",
      passwordConfirmation: "",
      errors: []
    }
  }

  componentDidMount() {
    const location = this.props.location;
    if (location.search.includes("reset_password=true")) {
      const re = /%40/;
      var url = new URL(BaseUrl + location.pathname + location.search);
      const accessToken = url.searchParams.get('access-token');
      let uid = url.searchParams.get('uid');
      uid = uid.replace(re, "@");
      const client = url.searchParams.get('client');
      const headers = {
        uid: uid,
        client: client,
        "access-token": accessToken,
        expiry: null
      };
      SetAuthorization(headers)
    } else {
      NotificationManager.info("Vuelva a intentarlo", "Liga inválida", 3000);
      this.props.history.push('/recover_password');
    }
  }

  resetPassword = (values) => {
    showLoader();
    return axios_instance.put('/auth/password',{
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation
    }).then((response) => {
      NotificationManager.success(StringResources.common.success, response.data.message, 3000);
      this.props.history.push('/sign_in');
      localStorage.clear();
      sessionStorage.clear();
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value}, this.validateShippingAddress)
  }

  handleSubmit = () => {
    const fields = {
      password: this.state.password,
      passwordConfirmation: this.state.passwordConfirmation
    };

    var errors = validations(fields)
    this.setState({errors: errors})
    errors = Object.keys(errors).some(x => errors[x])
    if (errors) {
      NotificationManager.error(StringResources.common.missing_fields, "", 3000);
    } else {
      this.resetPassword(fields);
    }
  }

  render() {
    return (
      <div className="session-container">
        <div className="flex-column">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <p className="session-title">Recupera tu Contraseña</p>
          <div style={{alignItems: 'center'}} className="flex-column">
            <div className="form-group">
              <input type="password" className={(this.state.errors.password ? "form-control bigger-input error" : "form-control bigger-input")} id="password" name="password" placeholder={StringResources.register.password} onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <input type="password" className={(this.state.errors.passwordConfirmation ? "form-control bigger-input error" : "form-control bigger-input")} id="passwordConfirmation" name="passwordConfirmation" placeholder={StringResources.register.password_confirmation} onChange={this.handleChange} />
            </div>
            <button className="sign-in-btn" onClick={this.handleSubmit}><strong>Cambiar Contraseña</strong></button>
          </div>
        </div>
      </div>
    )
  }
}
