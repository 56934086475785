import React from "react";
import { axios_instance } from  'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { NotificationManager} from 'react-notifications';
import AddressPicker from 'templates/common/address-picker';
import DatePicker from 'templates/common/date-picker';
import { ToCurrency } from 'components/utils';
import Wallet from 'templates/users/wallet'
export default class Quote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastOrder: {
        shipping_address : {},
        payment_info: {},
      },
      date: "",
      quote: {
        id: 0,
        attributes: {
          size: 0,
          price: 0,
        }
      }
    }
  }

  componentDidMount() {
    showLoader();
    const maxLoader = 2;
    let loaderCounter = 0;
    axios_instance.get(`/v1/quotes/${this.props.match.params.id}`).then((res) => {
      this.setState({
        quote: res.data.data
      });
    }).catch(() => {
      hideLoader();
      this.props.history.push(`/sign_in?redirect=quotes&id=${this.props.match.params.id}`);
    }).finally(() => {
      loaderCounter++;
      if(loaderCounter >= maxLoader)
        hideLoader();
    });

    axios_instance.get(`/v1/last_order_details`).then((res) => {
      const lastOrder = res.data;
      this.setState({
        lastOrder: lastOrder
      });
    }).catch(() => {
      this.props.history.push(`/sign_in?redirect=quotes&id=${this.props.match.params.id}`);
    }).finally(() => {
      loaderCounter++;
      if(loaderCounter >= maxLoader)
        hideLoader();
    });
  }

  saveShipping = (address) => {
    this.setState({lastOrder: { ...this.state.lastOrder, shipping_address: address}});
  }

  savePaymentMethod = (method) => {
    this.setState({lastOrder: { ...this.state.lastOrder, payment_info: method.paymentInfo, token_id: method.token_id}});
  }

  saveDate = (date) => {
    this.setState({date: date});
  }

  createOrder = () => {
    const paymentInfo = this.state.lastOrder.payment_info;
    const shippingAddress = this.state.lastOrder.shipping_address;
    if(shippingAddress && Object.keys(shippingAddress).length === 0 && shippingAddress.constructor === Object) {
      NotificationManager.error("Favor de guardar la dirección");
      return
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    
    showLoader();
    if (this.state.date) {
      let order = {
        order: {
          user_id: currentUser.id,
          shipping_address: shippingAddress,
          shipping_date: this.state.date,
          products: [
            {
              id: this.state.quote.id,
              product_type: "Quote"
            }
          ],
          payment_info: paymentInfo,
          order_type: "assign_quote"
        }
      };
      if (this.state.lastOrder.token_id) {
        order.order["token_id"] = this.state.lastOrder.token_id;
      }

      axios_instance.post('/v1/checkout', order).then((response) => {
        this.props.history.push('/users')
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    } else {
      hideLoader();
      NotificationManager.error("Favor de escoger una FECHA", "Error");
    }
  }

  render() {
    var user = JSON.parse(localStorage.getItem('currentUser'))
    return (
      <div className="dashboard-container margin-mobile">
        <div className="user-dashboard-container container">
          <div className="flex-column">
            <div className="flex-column">
              <span style={{alignSelf: 'inherit'}} className="main-title">TU COTIZACIÓN</span>
            </div>
            <div className="flex-column">
              <div style={{padding: '1em 0 1em 0'}} className="flex-row">
                <span style={{fontWeight: 'bold'}} className="red-item"> 00&nbsp; </span>
                <span style={{fontWeight: 'bold'}}> RESUMEN </span>
              </div>
              <div className="row">
                <div style={{whiteSpace: 'pre-line'}} className="col-sm-12">
                  {this.state.quote.attributes.message}
                </div>
              </div>
              <div className="row">
                <div style={{flex: 1, marginLeft: '15px'}}><b>Concepto</b></div>
                <div style={{flex: 1}}><b>Monto</b></div>
              </div>
              <div className="row">
                <div style={{flex: 1, marginLeft: '15px'}}>{this.state.quote.attributes.size}&nbsp;M<sup>2</sup></div>
                <div style={{flex: 1}}>(Mensualidad) {ToCurrency(this.state.quote.attributes.price)}</div>
              </div>
              {
                this.state.quote.attributes['additional-amount'] > 0 ?
                (
                  <div className="row">
                    <div className="col-sm-6">{this.state.quote.attributes['additional-concept']}</div>
                    <div className="col-sm-6">(Pago único) {ToCurrency(this.state.quote.attributes['additional-amount'])}</div>
                  </div>
                )
                :
                ""
              }
            </div>
            <div className="flex-column flex-column-mobile">
              <AddressPicker shippingAddress={this.state.lastOrder.shipping_address} save={this.saveShipping} user={user} />

              <div style={{fontFamily: 'Oswald, sans-serif', flex: '1 1 100%' }} className="flex-column">
                <div style={{padding: '1em 0 1em 0'}} className="flex-row">
                  <span style={{fontWeight: 'bold'}} className="red-item"> 02&nbsp; </span>
                  <span style={{fontWeight: 'bold'}}> MÉTODO DE PAGO </span>
                </div>

                <Wallet user={user}/>
              </div>
            </div>
            <div className="flex-row">
              <div style={{padding: '1em 0 1em 0'}} className="flex-row">
                <span style={{fontWeight: 'bold'}} className="red-item"> 03&nbsp; </span>
                <span style={{fontWeight: 'bold'}}> FECHA </span>
              </div>
            </div>
            <DatePicker save={this.saveDate}/>
            <div style={{justifyContent: 'center'}} className="flex-row">
              <div style={{padding: "0"}} className="pointer btn-box confirm-order-btn" onClick={() => this.createOrder()}>
                <span className="confirm-order-btn-text">CONFIRMAR</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
