import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import addressIcon from 'img/address-icon.svg';
import editIcon from 'img/edit-icon.svg';

export default class AddressPicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      suburbs: [],
      editMode: true,
      shipping: {
        postal_code: "",
        suburb: "",
        street: "",
        ext_number: "",
        int_number: "",
        references: "",
      },
      shippingAddresses: [],
      errors: {}
    }
  }

  componentDidMount() {
    if(this.props.editMode) {
      this.setState({
        editMode: this.props.editMode
      });
    }
    this.getUserShippingAddresses(this.props.user.id)
  }

  getUserShippingAddresses(userId) {
    showLoader();
    axios_instance.get(`/v1/addresses/?by_user=${userId}`)
      .then((response) => {
        var data = response.data.data
        this.setState({shippingAddresses: data})
        if(data[0]) {
          this.setAddress(data[0])
          this.setState({editMode: false})
        }

      })
    .catch(() => {

    })
    .finally(() => {
      hideLoader();
    });
  }

  setAddress = (data) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    this.setState({
      shipping: {
        postal_code: data.attributes['postal-code'],
        suburb: data.attributes.suburb,
        street: data.attributes.street,
        ext_number: data.attributes['ext-number'],
        int_number: data.attributes['int-number'],
      }
    })
    this.props.save({
      postal_code: data.attributes['postal-code'],
      suburb: data.attributes.suburb,
      street: data.attributes.street,
      ext_number: data.attributes['ext-number'],
      int_number: data.attributes['int-number'],
    })
  }

  edit = () => {
    var { postal_code, ext_number, int_number, street, suburb } = this.props.shippingAddress;

    if(this.state.suburbs.length === 0)
      this.getSuburbs(postal_code);

    this.setState({
      editMode: true,
      shipping: {
        postal_code,
        suburb,
        street,
        ext_number,
        int_number,
      }
    });
  }

  cancelEdit = () => {
    this.setState({
      editMode: false
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({shipping: {...this.state.shipping, [name]: value}}, this.validateShippingAddress)
  }

  save = () => {
    var { postal_code, ext_number, int_number, street, suburb, references } = this.state.shipping;
    var address = {
      postal_code,
      ext_number,
      int_number,
      street,
      suburb,
      references
    }
    this.props.save(address)
    this.setState({editMode: false})
  }

  getSuburbs = (postal_code) => {
    if(postal_code) {
      showLoader();
      axios_instance.get('/v1/validate_postal_code', {params: {postal_code: postal_code}}).then((response) => {
        var data = response.data.data
        this.setState({
          suburbs: data
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  renderAddress = () => {
    var suburbs = this.state.suburbs.map((suburb, index) => {
      return <option key={index} value={suburb.attributes.suburb}>{suburb.attributes.suburb}</option>
    });
    var { postal_code, ext_number, int_number, street, suburb } = this.props.shippingAddress;
    return (this.state.editMode) ?
    (
      <div className="pickers" style={{flexDirection:"column"}}>
        <FontAwesomeIcon icon={faWindowClose} size="2x" style={{alignSelf: "flex-end"}} onClick={() => this.cancelEdit()} />
        <div className="shipping" style={{padding: "1em"}}>
          <div className="shipping-container">
            <div style={{flexDirection: "column", flex: "1 1 100%"}}>
              <div className="form-group">
                <input style={{flexBasis: "40%", flexGrow: "0"}} type="number" className={(this.state.errors.postal_code && this.props.showShippingErrors ? "error" : "")} value={this.state.shipping.postal_code} placeholder="CÓDIGO POSTAL *" name="postal_code" onChange={this.handleChange} onBlur={() => this.getSuburbs(this.state.shipping.postal_code)}></input>
              </div>
              <div className="form-group">
                <select className={(this.state.errors.suburb && this.props.showShippingErrors ? "error" : "")} name="suburb" id="" value={this.state.shipping.suburb} onChange={this.handleChange}>
                  <option value="">Selecciona una colonia *</option>
                  { suburbs }
                </select>
              </div>
              <div className="form-group">
                <input type="text" className={(this.state.errors.street && this.props.showShippingErrors ? "error" : "")} name="street" placeholder="Calle *" value={this.state.shipping.street} onChange={this.handleChange}/>
              </div>
              <div className="form-group">
                <input type="text" className={(this.state.errors.ext_number && this.props.showShippingErrors ? "error" : "picker-jump-00")} name="ext_number" placeholder="No. Ext *" value={this.state.shipping.ext_number} onChange={this.handleChange}/>
                <input type="text" name="int_number" placeholder="No. Int" value={this.state.shipping.int_number} onChange={this.handleChange}/>
              </div>
              <div className="form-group">
                <input type="text" className="input-lg" name="references" placeholder="Referencias" value={this.state.shipping.references} onChange={this.handleChange}/>
              </div>
            </div>
          </div>
        </div>

        <div className="edit-button" onClick={this.save}>
          <span style={{color:"#bbf6e0"}}>GUARDAR</span>
        </div>
      </div>
    )
    :
    (
      <div className="pickers">
        <div className="flex-column picker-icon">
          <img src={addressIcon} alt="location" />
        </div>
        <div className="flex-column picker-text">
          <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>{suburb}, <span className="red-item">{postal_code}</span></span>
          <br />
          <span style={{fontFamily: 'Lato, sans-serif'}}>{street} {ext_number} {int_number}</span>
        </div>
        <div className="flex-column picker-button">
          <div className="edit-button" onClick={this.edit}>
            <img src={editIcon} alt="edit" />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{fontFamily: 'Oswald, sans-serif', flex: '1 1 100%' }} className="flex-column">
          <div style={{padding: '1em 0 1em 0'}} className="flex-row">
            <span style={{fontWeight: 'bold'}} className="red-item"> 01&nbsp; </span>
            <span style={{fontWeight: 'bold'}}> UBICACIÓN </span>
          </div>
          {this.renderAddress()}
        </div>

        <div className="d-flex flex-column mt-5">
          {
            this.state.shippingAddresses.map(address => {
              return (
                <div key={address.id} className="addressCard w-100" onClick={() => this.setAddress(address)}>
                  <div className="addressInfoArea">
                    <div>
                      {address.attributes.street}
                    </div>
                    <div>
                      {address.attributes['suburb']}
                    </div>
                    <div>
                      {address.attributes['postal-code']}
                    </div>
                  </div>
                  <div className="selectAddress">

                  </div>
                </div>
              )
            })
          }
          <div className="newAddress w-100 cursor-pointer" onClick={() => this.setState({editMode: true})}>
              Agregar nueva dirección
          </div>
        </div>
      </div>
    )
  }
}
