import React from "react";
import QrModal from 'templates/modals/qr-modal';
import DashboardMenu from 'templates/common/dashboard-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class Return extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      qrModal:{
        visibility: false,
        products: [],
        type: "return",
        ids: [],
        isReturn: false
      },
      currentLabel: "",
      products:[],
      order: {
        attributes: {
          products:[],
          "subscription-boxes": {
            data: []
          }
        }
      },
      storables: []
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const order = this.props.location.state[0];
      let storableIds = [];
      let isReturn = false;
      order.attributes['subscription-boxes'].map((storable) => {
        storableIds.push(storable.storable_id);
        if (storable.storable_status === "in_transit") {
          isReturn = true;
        } else {
          isReturn = false;
        }
        return {};
      });

      let qrModal = this.state.qrModal;
      qrModal.ids = storableIds;
      qrModal.isReturn = isReturn;
      this.setState({
        qrModal: qrModal,
        order: order
      });
    }
  }

  openQrModal = (product) => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: true }}));
  }

  closeQrModal = () => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: false }}));
  }

  returnBox = (storable) => {
    let storables = this.state.storables;
    storables.push(storable);
    this.setState({storables})
  }


  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">{this.state.qrModal.isReturn ? "Regresar" : "Recoger"}</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="recolect-item-header" onClick={this.openQrModal}>
              {
                this.state.qrModal.isReturn ?
                <h1 style={{paddingRight: "1vw"}}>Tap para registrar el regreso al client</h1> : <h1 style={{paddingRight: "1vw"}}>Tap para Recoger de Almacen</h1>
              }
              <FontAwesomeIcon icon={faQrcode} size="4x"/>
            </div>
            <div>
              <ul>
                {
                  this.state.storables.map((storable) => {
                    return (
                      <li>{storable}</li>
                  )
                })
              }
              </ul>
            </div>
            <QrModal
              display={this.state.qrModal}
              closeModal={this.closeQrModal}
              returnBox={this.returnBox}
              label={this.state.currentLabel}
              orderId={this.state.order.id}/>
          </div>
        </div>
      </div>
    )
  }
}
