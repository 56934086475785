import React from "react";
import { validations } from "components/validations";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import mapIcon from "img/map-location-icon.svg";

export default class ShippingSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingAddresses: [],
      postal_code: "",
      suburbs: [],
      suburb: "",
      street: "",
      ext_number: "",
      int_number: "",
      references: "",
      shipping: {},
      errors: {},
      isNewAddress: true,
    };
  }
  componentDidMount() {
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      // this.setState({isNewAddress: false})
      this.getUserShippingAddresses();
    }

    var order = JSON.parse(sessionStorage.getItem("order"));
    if (!order) return;
    if (Object.keys(order.shipping).length > 0) {
      this.setState(
        {
          shipping: {
            postal_code: order.shipping.postal_code,
            suburb: order.shipping.suburb,
            street: order.shipping.street,
            ext_number: order.shipping.ext_number,
            int_number: order.shipping.int_number,
            references: order.shipping.references,
          },
          suburbs: order.shipping.suburbs,
        },
        this.validateShippingAddress
      );
    } else {
      this.validateShippingAddress();
    }
  }

  handleChange = (event) => {
    var shipping = this.state.shipping;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    shipping[name] = value;
    this.setState(
      { shipping: { ...this.state.shipping, [name]: value } },
      this.validateShippingAddress(shipping)
    );
  };

  getSuburbs = () => {
    if (this.state.shipping.postal_code) {
      showLoader();
      axios_instance
        .get("/v1/validate_postal_code", {
          params: { postal_code: this.state.shipping.postal_code },
        })
        .then((response) => {
          var data = response.data.data;
          this.setState({
            suburbs: data,
          });
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
        });
    }
  };

  getUserShippingAddresses(userId = null) {
    showLoader();
    axios_instance
      .get("/v1/addresses")
      .then((response) => {
        var data = response.data.data;
        console.log(data);

        this.setState({ shippingAddresses: data });
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  }

  selectShippingAddress(address) {
    this.setState(
      {
        shipping: {
          postal_code: address.attributes["postal-code"],
          suburb: address.attributes["suburb"],
          street: address.attributes["street"],
          ext_number: address.attributes["ext-number"],
          int_number: address.attributes["int-number"],
          references: address.attributes["references"],
        },
        suburbs: [],
      },
      this.validateShippingAddress
    );
  }

  handleSelectAddress(address) {
    let order = JSON.parse(sessionStorage.getItem("order"));

    if (
      order.shipping["street"] === address.attributes["street"] &&
      order.shipping["postal_code"] === address.attributes["postal-code"] &&
      order.shipping["suburb"] === address.attributes["suburb"]
    ) {
      return (
        <button
          className="btn btn-success"
          onClick={() => this.selectShippingAddress(address)}
        >
          Seleccionada
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-primary"
          onClick={() => this.selectShippingAddress(address)}
        >
          Seleccionar dirección
        </button>
      );
    }
  }

  validateShippingAddress = (params) => {
    params = params === undefined ? this.state.shipping : params;
    var shipping = {
      postal_code: params.postal_code,
      street: params.street,
      ext_number: params.ext_number,
      suburb: params.suburb,
    };
    var errors = validations(shipping);
    this.setState({ errors: errors });
    errors = Object.keys(errors).some((x) => errors[x]);
    if (errors) {
      this.props.setShippingErrors();
    } else {
      shipping["suburbs"] = this.state.suburbs;
      shipping["int_number"] = params.int_number;
      shipping["references"] = params.references;
      this.props.setShippingAddress(shipping);
    }
  };

  renderMapIcon = () => {
    if (this.props.icon) {
      return (
        <div className="shipping-image">
          <img
            style={{ alignSelf: "baseline" }}
            src={mapIcon}
            alt="arrow-down"
          />
        </div>
      );
    }
  };

  render() {
    var suburbs = this.state.suburbs.map((suburb, index) => {
      return (
        <option key={index} value={suburb.attributes.suburb}>
          {suburb.attributes.suburb}
        </option>
      );
    });
    return (
      <div className="shipping">
        {this.state.isNewAddress ? (
          <div className="shipping-container">
            {this.renderMapIcon()}
            <div
              className="w-100"
              style={{ flexDirection: "column", flexGrow: 2 }}
            >
              <div className="form-group">
                <input
                  style={{ flexBasis: "40%", flexGrow: "0" }}
                  type="number"
                  className={
                    this.state.errors.postal_code &&
                    this.props.showShippingErrors
                      ? "error"
                      : ""
                  }
                  value={this.state.shipping.postal_code}
                  placeholder="CÓDIGO POSTAL *"
                  name="postal_code"
                  onChange={this.handleChange}
                  onBlur={() => this.getSuburbs()}
                ></input>
              </div>
              <div className="form-group">
                <select
                  className={
                    this.state.errors.suburb && this.props.showShippingErrors
                      ? "error"
                      : ""
                  }
                  name="suburb"
                  id=""
                  value={this.state.shipping.suburb}
                  onChange={this.handleChange}
                >
                  <option value="">Selecciona una colonia *</option>
                  {suburbs}
                </select>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={
                    this.state.errors.street && this.props.showShippingErrors
                      ? "error"
                      : ""
                  }
                  name="street"
                  placeholder="Avenida Lazaro Cardenas"
                  value={this.state.shipping.street}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group" style={{ flexWrap: "inherit" }}>
                <input
                  type="text"
                  style={{ flex: "1 1 50%", marginRight: "1vw", width: "50%" }}
                  className={
                    this.state.errors.ext_number &&
                    this.props.showShippingErrors
                      ? "error"
                      : ""
                  }
                  name="ext_number"
                  placeholder="No. Ext *"
                  value={this.state.shipping.ext_number}
                  onChange={this.handleChange}
                />
                <input
                  style={{ flex: "1 1 50%", width: "50%" }}
                  type="text"
                  name="int_number"
                  placeholder="No. Int"
                  value={this.state.shipping.int_number}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="input-lg"
                  name="references"
                  placeholder="Ayudanos a llegar lo ams preparados para tu servicion con informacion adicional"
                  value={this.state.shipping.references}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            {this.state.shippingAddresses.map((address) => {
              return (
                <div key={address.id} className="addressCard w-100">
                  <div className="addressInfoArea">
                    <div>{address.attributes.street}</div>
                    <div>{address.attributes["suburb"]}</div>
                    <div>{address.attributes["postal-code"]}</div>
                  </div>
                  <div className="selectAddress">
                    {this.handleSelectAddress(address)}
                  </div>
                </div>
              );
            })}
            <div
              className="newAddress w-100 cursor-pointer"
              onClick={() => this.setState({ isNewAddress: true })}
            >
              Agregar nueva dirección
            </div>
          </div>
        )}
      </div>
    );
  }
}
