import React from 'react';
import { axios_instance, BaseUrl } from 'components/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class ConfirmAccount extends React.Component {
  componentDidMount() {
    const location = this.props.location;
    if (location.search.includes("confirmation_token")) {
      var url = new URL(BaseUrl + location.pathname + location.search);
      const confirmationToken = url.searchParams.get('confirmation_token');
      axios_instance.get(`/auth/confirmation?config=default&user[confirmation_token]=${confirmationToken}`).then((response) => {
      }).catch(() => {
      }).finally(() => {
        this.props.history.push('/sign_in');
      })
    } else {
      this.props.history.push('/sign_in');
    }
  }

  render() {
    return (
      <div className="session-container">
        <div className="flex-column">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <p className="session-title">Tu cuenta ha sido confirmada.</p>
          <div style={{alignItems: 'center'}} className="flex-column">
          </div>
        </div>
      </div>
    );
  }
}
