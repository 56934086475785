import React from "react";
import QRCode from 'qrcode.react';
import * as jsPDF from 'jspdf'
import DashboardMenu from "templates/common/dashboard-menu";
import { showLoader, hideLoader } from 'components/loading-spinner';

export default class QrCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      qr_codes: []
    }
  }
  componentDidMount() {
    var qr_codes = this.props.location.state ? this.props.location.state[0] : []
    this.setState({qr_codes});
  }

  qrsToPdf = () => {
    showLoader();
    const pdf = new jsPDF();
    this.state.qr_codes.map((qr_code, index) => {
      const canvas = document.querySelector('.HpQrcode_' + qr_code.id + ' > canvas');
      const canvasUrl = canvas.toDataURL('image/png');
      const object_type = qr_code.type === "storage-items" ? "Objeto" : qr_code.type === "spaces" ? "Espacio" : "Caja";
      const object_size = qr_code.attributes.hasOwnProperty('box-type') ? qr_code.attributes['box-type'] : "";
      const boxName = `${object_type} ${object_size} ${qr_code.id}`;
      this.addQRToPdf(boxName, canvasUrl, pdf);
      if ((this.state.qr_codes.length - 1) > index) {
        pdf.addPage();
      }
      return true;
    })
    pdf.save("qr_codes.pdf");
    hideLoader();
  }

  addQRToPdf = (boxName, canvasUrl, pdf) => {
    pdf.text(boxName, 20, 20);
    pdf.text(boxName, 100, 20);
    pdf.text(boxName, 20, 130);
    pdf.text(boxName, 100, 130);
    pdf.addImage(canvasUrl, 'PNG', 20, 30);
    pdf.addImage(canvasUrl, 'PNG', 100, 30);
    pdf.addImage(canvasUrl, 'PNG', 20, 140);
    pdf.addImage(canvasUrl, 'PNG', 100, 140);
  }

  qrToPdf = (qr) => {
    const pdf = new jsPDF();
    const canvas = document.querySelector('.HpQrcode_' + qr.id + ' > canvas');
    const canvasUrl = canvas.toDataURL('image/png');
    const object_type = qr.type === "storage-items" ? "Objeto" : qr.type === "spaces" ? "Espacio" : "Caja";
    const object_size = qr.attributes.hasOwnProperty('box-type') ? qr.attributes['box-type'] : "";
    const boxName = `${object_type} ${object_size} ${qr.id}`;
    this.addQRToPdf(boxName, canvasUrl, pdf);
    pdf.save(boxName);
  }

  render() {
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <button onClick={this.qrsToPdf}>Descargar PDF</button>
          <div id="qr-generator" className="qr-generator">
            {
              this.state.qr_codes.map((qr_code, key) => {
                console.log(qr_code);
                var code = {
                  type: qr_code.type,
                  id: qr_code.id,
                  url: 'https://www.boxmi.mx'
                }
                const object_type = qr_code.type === "storage-items" ? "Objeto" : qr_code.type === "spaces" ? "Espacio" : "Caja";
                const object_size = qr_code.attributes.hasOwnProperty('box-type') ? qr_code.attributes['box-type'] : "";
                code = JSON.stringify(code);
                return (
                  <div key={key} className={("pointer HpQrcode_" + qr_code.id)} style={{display:"flex", padding:"10px", flex:"1", flexDirection: "column" }}>
                    <h3>{object_type} {object_size} {qr_code.id} </h3>
                    <QRCode
                      value={code}
                      size={128}
                      level={'H'}
                      onClick={() => {this.qrToPdf(qr_code)}}
                      />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
