import React from "react";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { axios_instance } from 'components/axios';
import CustomTable from "components/table";
import DashboardMenu from "templates/common/dashboard-menu";

const objectColumns = [
  {
    Header: "id",
    accesor: d => d,
    id: "id",
    show: false
  },
  {
    Header: "Nombre de Contacto",
    accessor: d => d.attributes.name,
    id: 'name',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "Correo Electrónico",
    accessor: d => d.attributes["email"],
    id: 'email',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "¿Es Cotización?",
    accessor: d => d.attributes["is-quote-t"],
    id: 'is-quote-t',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "¿Revisado?",
    accessor: d => d.attributes["is-reviewed-t"],
    id: 'is-reviewed-t',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "¿Terminado?",
    accessor: d => d.attributes["is-completed-t"],
    id: 'is-completed-t',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "Creado en",
    accessor: d => d.attributes["created-at"],
    id: 'created-at',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "Última actualización",
    accessor: d => d.attributes["updated-at-i18n"],
    id: 'updated-at-i18n',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  }
];

export default class ContactForms extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: [],
      pages: null,
      loading: true,
      page: 1,
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize
    }

    if(state.sorted.length) {
      state.sorted.forEach(function(sort) {
        params[sort.id] = sort.desc ? "desc" : "asc"
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function(filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance.get("/v1/contact_forms", {params})
    .then((res) => {
      if(res.data.data) {
        this.setState({
          data: res.data.data,
          loading: false,
          pages: res.data.meta.pagination["total-pages"],
        });
      }
    }).catch(() => {
    });
  }

  render() {
    const { data, pages, loading } = this.state;
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">Contactos</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <CustomTable {...this.props}
              columns={objectColumns}
              data={data}
              pages={pages}
              loading={loading}
              manual
              onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
              filterable
              defaultPageSize={10}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if(column.Header) {
                      this.props.history.push(`/admin/contact-forms/${rowInfo.original.id}`, [rowInfo.original])
                    } else {
                      handleOriginal();
                    }
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
