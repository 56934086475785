import React from 'react';
import CameraModal from 'templates/modals/camera-modal';
import InventoryModal from 'templates/modals/inventory-modal';
import { axios_instance, BaseUrl } from  'components/axios';
import DashboardUserMenu from 'templates/common/dashboard-user-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { faChevronLeft, faCamera, faFolder } from '@fortawesome/free-solid-svg-icons';
import StringResources from 'components/string-resources';
import boxmiImg from 'img/proceso-03-almacenamos.svg';

export default class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraModal:{
        visibility: false,
      },
      inWarehouse: false,
      inventoryModal: false,
      boxmiImages: [],
      boxmiDescription: "",
      images: [],
      counter: 0,
      title: '',
      description: '',
      reload: false,
      editMode: false,
      subscriptionBox: {
        attributes: {
          "user-attachments": [],
          "boxmi-attachments": [],
          "boxmi-description": "",
          "user-description": "",
          title: "",
        }
      }
    };
  }

  componentDidMount() {
    showLoader();
    const uri = `/v1/subscription_boxes/${this.props.match.params.id}`;
    axios_instance.get(uri).then((res) => {
      const subscriptionBox = res.data.data
      let editMode = true;


      if (subscriptionBox.attributes["user-attachments"].length > 0) {
        editMode = false;
      } else {
        setTimeout(() => {
          this.setState({title: this.state.subscriptionBox.attributes["title"]})
          this.setState({description: this.state.subscriptionBox.attributes["user-description"]})
          if(document.getElementById('name-input') && document.getElementById('description-input')) {
            document.getElementById('name-input').value = this.state.subscriptionBox.attributes["title"]
            document.getElementById('description-input').value = this.state.subscriptionBox.attributes["user-description"]
          }
        }, 10)
      }
      let boxmiImages = res.data.data.attributes['boxmi-attachments'];
      let boxmiDescription = res.data.data.attributes['boxmi-description'];

      let currentUser = JSON.parse(localStorage.getItem('currentUser'))
      let inWarehouse = false
      currentUser.attributes.subscription.in_warehouse.data.forEach(box => {
        if(box.id === res.data.data.id) {
          editMode = false
          inWarehouse = true
        }
      })

      this.setState({subscriptionBox, editMode, boxmiImages, boxmiDescription, inWarehouse});
    }).catch(() => {
    }).finally(()=>{
      hideLoader();
    })
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value})
  }

  onChange = (event) => {
    let files = event.target.files;
    let images = this.state.images;
    let id = this.state.count + 1;
    for(let i = 0; i < files.length; i++) {
      let file = files[i];
      if(file.type && file.type.split('/')[0] === 'image')
      {
        images.push({image: file, id: id});
        id += 1;
      }
    }
    this.setState({
      images: images,
      counter: id
    });
  }

  takePicture = (image) => {
    let images = this.state.images;
    let id = this.state.counter + 1;
    images.push({image: image, id: id})
    this.setState({
      images: images,
      counter: id
    });
  }

  componentDidUpdate() {
    if (this.state.reload) {
      this.props.history.push('/users');
    }
  }

  removeImage = (image) => {
    var images = this.state.images
    var newImages = images.filter((attachment) => { return attachment.id !== image.id});
    this.setState({ images: newImages } )
  }

  closeCameraModal = () => {
    this.setState({cameraModal: {visibility: false}});
  }

  openModal = (product) => {
    this.setState({cameraModal: {visibility: true}})
  }

  closeInventoryModal = () => {
    this.setState({inventoryModal: false});
  }

  openInventoryModal = () => {
    this.setState({inventoryModal: true});
  }

  saveSubscriptionBox = () => {
    showLoader()
    let formData = new FormData();
    formData.append('subscription_box[user_description]', this.state.description)
    formData.append('subscription_box[title]', this.state.title)
    this.state.images.map((p) => {
      formData.append('subscription_box[user_attachments][]', p.image);
      return "";
    })

    axios_instance.put('/v1/subscription_boxes/' + this.props.match.params.id, formData).then((res) =>{
      this.setState({editMode:false, reload: true})

    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  selectFile = () => {
    document.getElementById("file-input").click()
  }
  imageGallery = () => {
    return (
      <div className="recolect-item-photos">
        {
          this.state.images.map((image, index) => {
            return(
              <img key={index} className="recolect-item-photo" src={URL.createObjectURL(image.image)} alt="description" width="200px" onClick={() => { this.removeImage(image) }}/>
            )
          })
        }
      </div>
    )
  }

  editBoxContent() {
   this.setState({editMode:true}, )
    setTimeout(() => {
      this.setState({title: this.state.subscriptionBox.attributes["title"]})
      this.setState({description: this.state.subscriptionBox.attributes["user-description"]})
      document.getElementById('name-input').value = this.state.subscriptionBox.attributes["title"]
      document.getElementById('description-input').value = this.state.subscriptionBox.attributes["user-description"]
    }, 10)
  }

  render() {
    return (
      <div className="dashboard-container dashboard-margin-top">
        <DashboardUserMenu />
        <div className="user-dashboard-container">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <div className="flex-column">
            <div className='d-flex justify-content-between'>
              <span style={{ paddingLeft: '1em' }} className="main-subtitle">DASHBOARD / <span className="red-item">INVENTARIO</span></span>
              {
                !this.state.inWarehouse ?
                !this.state.editMode ? (
                  <button
                    className='btn btn-primary'
                    onClick={() => this.editBoxContent()}
                  >
                    Editar
                  </button>
                ) : (
                  <button className='btn btn-primary'
                    onClick={() => this.setState({editMode: false})}
                  >
                    Cancelar
                  </button>
                ) : ''
              }
            </div>
            {
              this.state.editMode ? (
                <div className="flex-column container">
                  <label style={{ marginTop: '1em' }} htmlFor="description">IMAGENES:</label>
                  <div className="select-upload-photo-method">
                    <div className="upload-photo-method file-btn" onClick={this.selectFile}>
                      <FontAwesomeIcon icon={faFolder} />
                      <span className='btn-label'>Subir <br/> Archivo</span>
                    </div>
                    <div className="upload-photo-method photo-btn" onClick={this.openModal}>
                      <FontAwesomeIcon icon={faCamera} />
                      <span className='btn-label'>Tomar <br/> Foto</span>
                    </div>
                  </div>
                  <input id="file-input" type="file" onChange={this.onChange} multiple="multiple" style={{ display: "none"}} />
                  <div>
                    {
                      this.state.images.length ? this.imageGallery() : ''
                    }
                  </div>

                  <div style={{alignItems: 'center'}} className="flex-column">
                    <div className="form-group w-100">
                      <input
                        id='name-input'
                        type="text"
                        className="form-control"
                        name="title"
                        placeholder="Nombre"
                        onChange={this.handleChange}/>
                    </div>
                    <textarea
                      id='description-input'
                      className="form-control"
                      name="description"
                      rows="4"
                      placeholder={StringResources.register.message}
                      onChange={this.handleChange}/>
                  </div>
                  <button className="link-button nav-link btn" style={{backgroundColor: "#5E3DFC", color: "white", marginBottom: "1em", marginTop: "1em"}} onClick={this.saveSubscriptionBox}>Guardar</button>
                </div>
              ) : (
                <div className="flex-column">
                  <span style={{paddingLeft: '1em', paddingRight: '1em'}}><strong>Nombre:</strong><br />{this.state.subscriptionBox.attributes["title"]}</span>
                  <span style={{paddingLeft: '1em', paddingRight: '1em', whiteSpace: "pre-line"}}><strong>Contenido:</strong><br />{this.state.subscriptionBox.attributes["user-description"]}</span>
                  <div style={{paddingLeft: '1em', paddingRight: '1em'}}>
                    <div className="recolect-item-photos" >
                      {
                        this.state.subscriptionBox.attributes["user-attachments"].map((image, index) => {
                          return(
                            <img key={index} className="recolect-item-photo" src={`${BaseUrl}/${image.url}`} alt="user-img" width="200px"/>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            }
            <div onClick={() => this.openInventoryModal()} className="btn-box-1" style={{margin: '1em', cursor: 'pointer'}}>
              <img style={{width: '45px'}} src={boxmiImg} alt="Boxmi"></img>
              <span className="dashboard-action-title">Ver contenido de Boxmi</span>
            </div>
          </div>
        </div>
        <CameraModal
          display={this.state.cameraModal}
          closeModal={this.closeCameraModal}
          takePicture={this.takePicture} />

        <InventoryModal
          display={this.state.inventoryModal}
          closeModal={this.closeInventoryModal}
          images={this.state.boxmiImages}
          description={this.state.boxmiDescription} />
      </div>
    )
  }
}
