import React from "react";
import { axios_instance, BaseUrl } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from "templates/common/dashboard-menu";
import QRCode from 'qrcode.react';
import * as jsPDF from 'jspdf'

export default class StorageItem extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      box: {
        attributes: {
          "active-status": false,
          "boxmi-attachments": [],
          "user-attachments": [],
          "active-user": {},
        },
        id: 0
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.match.params.id !== prevState.box.id){
       return { box_id: nextProps.match.params.id};
    }
    else {
       return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.id !== this.state.box_id){
      showLoader();
      axios_instance.get('/v1/boxes/' + this.state.box_id).then((response) => {
        this.setState({
          box: response.data.data,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    }
  }

  componentDidMount() {
    var box = this.props.location.state === undefined ? this.props.match.params.id : this.props.location.state[0]
    if (typeof(box) === "string") {
      showLoader();
      axios_instance.get('/v1/storage_items/' + box).then((response) => {
        this.setState({
          box: response.data.data,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      this.setState({
        box: box,
      })
    }
  }

  goBack = () => {
    this.props.history.push('/storage-items')
  }

  renderQr = () => {
    var code = {
      type: "storage-items",
      id: this.state.box.id,
      url: 'https://www.boxmi.mx'
    }
    const object_type = "Objeto";
    const object_size = this.state.box.attributes['storage-item-type'];
    code = JSON.stringify(code);
    return (
      <div className={("pointer HpQrcode_" + this.state.box.id)} style={{display:"flex", padding:"10px", flex:"1", flexDirection: "column" }}>
        <h3>{object_type} {object_size} {this.state.box.id} </h3>
        <QRCode
          value={code}
          size={128}
          level={'H'}
          onClick={() => {this.qrToPdf()}}
          />
      </div>
    )
  }

  addQRToPdf = (boxName, canvasUrl, pdf) => {
    pdf.text(boxName, 20, 20);
    pdf.text(boxName, 100, 20);
    pdf.text(boxName, 20, 130);
    pdf.text(boxName, 100, 130);
    pdf.addImage(canvasUrl, 'PNG', 20, 30);
    pdf.addImage(canvasUrl, 'PNG', 100, 30);
    pdf.addImage(canvasUrl, 'PNG', 20, 140);
    pdf.addImage(canvasUrl, 'PNG', 100, 140);
  }

  qrToPdf = () => {
    const pdf = new jsPDF();
    const canvas = document.querySelector('.HpQrcode_' + this.state.box.id + ' > canvas');
    const canvasUrl = canvas.toDataURL('image/png');
    const object_type = "Objeto"
    const object_size = this.state.box.attributes['storage-item-type']
    const boxName = `${object_type} ${object_size} ${this.state.box.id}`;
    this.addQRToPdf(boxName, canvasUrl, pdf);
    pdf.save(boxName);
  }

  renderName = () => {
    var { attributes } = this.state.box
    if (attributes.title) {
      return (
        <div style={{marginBottom: '1rem'}}>
          <div class="box-info-label">Nombre: </div>
          {attributes.title}
        </div>
      )
    }
  }

  render() {
    var { attributes } = this.state.box
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content card">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3 className="dashboard-content-header-title">Objeto #{this.state.box.id}</h3>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <button onClick={this.goBack} className="btn btn-back">Regresar</button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body" style={{fontSize: "1.5em"}}>
            <div className="box-info-grid">
              <div>
                { this.renderName() }
                <div>
                  <div class="box-info-label">Estatus: </div>
                  {attributes["active-status"] ? "Activo" : "Inactivo"}
                </div>
                <div style={{marginTop: '1rem'}}>
                  <div class="box-info-label">Espacio Asignado: </div>
                  <span >{attributes["space-id"] ? `Espacio ${attributes["space-id"]}` : "No esta ligada"}</span>
                </div>
                <div style={{marginTop: '1rem'}}>
                  <div class="box-info-label">Usuario: </div>
                  <span >{attributes["active-user"] ? attributes["active-user"].name : "No tiene un usuario por el momento"}</span>
                </div>
                <div style={{marginTop: '1rem'}}>
                  <div class="box-info-label">Imagenes Boxmi: </div>
                  {
                    attributes["boxmi-attachments"].length > 0 ?
                    attributes["boxmi-attachments"].map((attachment, index) => {
                      return(
                        <img key={index} src={`${BaseUrl}${attachment}`} alt="img"></img>
                      )
                    }) : <span >Todavia no tiene images</span>
                  }
                </div>
                <div style={{marginTop: '1rem'}}>
                  <div class="box-info-label">Imagenes Usuario: </div>
                  {
                    attributes["user-attachments"].length > 0 ?
                    attributes["user-attachments"].map((attachment, index) => {
                      return(
                        <img key={index} src={`${BaseUrl}${attachment}`} alt="img"></img>
                      )
                    }) : <span>Todavia no tiene images</span>
                  }
                </div>
              </div>
              <div>
                <span style={{paddingLeft: "5px"}}>
                  {this.renderQr()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
