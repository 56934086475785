import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { loadHeader } from 'templates/header';

let headers = JSON.parse(localStorage.getItem("headers")) || { "access-token": "", client: "", expiry: "", uid: ""};
var isPost = false
export const BaseUrl = "https://api.boxmi.mx";

export const axios_instance = axios.create({
  baseURL: BaseUrl,
  timeout: 30000,
  headers: {
    "access-token": headers['access-token'],
    client: headers.client,
    expiry: headers.expiry,
    uid: headers.uid,
  }
});


export const SetAuthorization = (headers) => {
  if(headers["access-token"]) {
    axios_instance.defaults.headers['access-token'] = headers["access-token"];
    axios_instance.defaults.headers['client'] = headers["client"];
    axios_instance.defaults.headers['expiry'] = headers["expiry"];
    axios_instance.defaults.headers['uid'] = headers["uid"];
    localStorage.setItem("headers", JSON.stringify(headers));
  }
}

axios_instance.interceptors.request.use((config) => {
  if(config.method === "post" || config.method === "put" || config.method === "delete")
    isPost = true;
  return config;
}, (error) => {
  isPost = false;
  return Promise.reject(error);
});

// Add a response interceptor
axios_instance.interceptors.response.use((response) => {
  // Do something with response data
  if(isPost)
  {
    // NotificationManager.success("Operación Exitosa", "", 3000);
    isPost = false;
  }
  SetAuthorization(response.headers);
  return response;
}, (error) => {
  // Do something with response error
  isPost = false;
  if(error.code === "ECONNABORTED")
  {
    NotificationManager.error("No se pudo contactar al servidor. Vuelva a intentar", "Timeout", 3000);
    loadHeader();
  }
  if(error.response)
  {
    if(error.response.status === 500)
    {
      // Server error
      NotificationManager.error("Hubo un error de nuestra parte. Vuelta a intentar si el error persiste, favor de contactar a Boxmi", "Error Servidor", 5000);
    }
    if(error.response.status === 401)
    {
      // Unauthenticated
      if(error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach((error) => {
          NotificationManager.warning('', error, 3000);
        });
      } else {
        NotificationManager.warning("Favor de iniciar sesión.", "Sesión inválida", 3000);
      }
      // localStorage.removeItem("headers");
      // loadHeader();
    }
    if(error.response.status === 403)
    {
      // Unauthorized
      NotificationManager.error(error.response.data.message, "Sin Autorización", 3000);
      loadHeader();
    }
    if(error.response.status === 404)
    {
      //Not found
      SetAuthorization(error.response.headers);
      if(error.response.data && error.response.data.message) {
        NotificationManager.error(error.response.data.message, "No Encontrado", 3000);
      } else {
        NotificationManager.error("", "No Encontrado", 3000);
      }
    }
    if(error.response.status === 422)
    {
      SetAuthorization(error.response.headers);
      if(error.response.data.errors)
      {
        if (error.response.data.errors.full_messages) {
          error.response.data.errors.full_messages.map((error) => {
            NotificationManager.error(error, 'Error', 3000);
            return true;
          })
        } else {
          console.log(error.response.data.errors);
          error.response.data.errors.map((error) => {
            NotificationManager.error(error, 'Error', 3000);
            return true;
          })
        }
      }
    }
  }
  return Promise.reject(error);
});

export default axios_instance;
