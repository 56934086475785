import React from "react";
import Modal from 'react-modal';
import { BaseUrl } from 'components/axios';
import remove from "img/remove-button.svg";

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay : {
    backgroundColor       : 'rgba(0, 0, 0, 0.75)',
    zIndex                : '999'
  }
};

export default class InventoryModal extends React.Component {
  closeModal = () => {
    this.props.closeModal();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.display}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        className="camera-modal"
        style={customStyles}
        contentLabel="Example Modal">
        <div className="camera-modal-close" onClick={this.closeModal}>
          <img src={remove} alt="remove"></img>
        </div>
        <div className="recolect-item-photos">
          {
            this.props.images.map((image, index) => {
              return (
                <img key={index} src={`${BaseUrl}/${image.url}`} alt="boxmi" className="recolect-item-photo" />
              )
            })
          }
        </div>
        <p style={{color: 'white', marginLeft: '1em', marginRight: '1em', fontSize: '1.5em'}}>
          <strong>Contenido:</strong><br/>{this.props.description}
        </p>

      </Modal>
    )
  }
}
