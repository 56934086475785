import React from "react";
import moment from "moment";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import { resources } from "components/string-resources";
import { NotificationManager } from "react-notifications";
import DashboardMenu from "templates/common/dashboard-menu";
import OrderIcon from "img/order-ico-02.svg";
import warehouseIcon from "img/warehouse-icon-w.svg";
import boxIcon from "img/box-icon.svg";
import objectIcon from "img/object-icon.svg";
import UserStorable from "templates/common/user-storable";
import truckIcon from "img/truck-icon.svg";
import cancelIcon from "img/cancel-icon.svg";
import boxCancelIcon from "img/box-cancel-icon.svg";
import CustomTable from "components/table";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const storableTypeItem = "item";
const storableTypeBox = "box";

function shippingDate(date) {
  if (date) {
    return `${date.substring(0, 10)} - ${moment.parseZone(date).format("hh:mm a")}`;
  }
  return "";
}

const orderColumns = [
  {
    Header: "# Folio",
    accessor: (d) => d.id,
    id: "id",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Cliente",
    accessor: (d) => `${d.attributes.user.name} ${d.attributes.user.last_name}`,
    id: "name",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Producto",
    accessor: (d) => d.attributes["product-name"],
    id: "product",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
    // Cell: ({value}) => ( value ? resources.order.type[value] : ""),
    // filterMethod: (filter, row) => {
    //   if (filter.value === "all") {
    //     return true;
    //   }
    //   if (filter.value === "active") {
    //     return row[filter.id] === true;
    //   }
    //   return row[filter.id] === false;
    // },
    // Filter: ({ filter, onChange }) =>
    //   <select
    //     onChange={event => onChange(event.target.value)}
    //     style={{ width: "100%" }}
    //     value={filter ? filter.value : "all"}
    //   >
    //     <option value="all">Todos</option>
    //     <option value="assign">Asignar</option>
    //     <option value="pickup">Recoger</option>
    //     <option value="return">Regresar</option>
    //     <option value="cancel">Cancelar</option>
    //     <option value="assign_quote">Cotización</option>
    //   </select>
  },
  {
    Header: "Precio",
    accessor: (d) => d.attributes["price"],
    id: "price",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Intervalo mensual",
    accessor: (d) => d.attributes["monthly-interval"],
    id: "monthly-interval",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Descuento %",
    accessor: (d) => d.attributes["discount-percentage"],
    id: "discount-percentage",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Recoleccion",
    accessor: (d) => shippingDate(d.attributes["shipping-date"]),
    id: "shipping-date",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Creado el",
    accessor: (d) => d.attributes["created-at"],
    id: "created_at",
    style: {
      cursor: "pointer",
    },
    filterable: false,
  },
];

export default class Space extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        attributes: {
          subscription: {},
        },
        id: 0,
      },
      orders: [],
      pages: null,
      loading: true,
      page: 1,
      warehouseItems: [],
      warehouseBoxes: [],
      warehouseStorables: [],
      houseStorables: [],
      houseItems: [],
      houseBoxes: [],
      storableActions: {
        cancellingWarehouse: false,
        warehouse: false,
        house: false,
        cancellingHouse: false,
      },
      storables: {},
    };
  }

  initStorables(storables, storableType) {
    return storables.map((storable) => {
      storable.storableType = storableType;
      return storable;
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.user.id) {
      return { user_id: nextProps.match.params.id };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.state.user_id) {
      showLoader();
      axios_instance
        .get("/v1/boxes/" + this.state.user_id)
        .then((response) => {
          response.data.data.attributes[
            "subscription-next-billing-cycle"
          ] = `Siguiente Mensualidad: ${moment(
            response.data.data.attributes["subscription-next-billing-cycle"]
          ).format("YYYY/MM/DD")}`;
          var storables = this.setStorables(
            response.data.data.attributes["subscription"]
          );
          var { warehouseItems, warehouseBoxes, houseItems, houseBoxes } =
            storables;
          this.setState({
            user: response.data.data,
            warehouseItems: this.initStorables(
              warehouseItems,
              storableTypeItem
            ),
            warehouseBoxes: this.initStorables(warehouseBoxes, storableTypeBox),
            houseItems: this.initStorables(houseItems, storableTypeItem),
            houseBoxes: this.initStorables(houseBoxes, storableTypeBox),
          });
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
        });
    }
  }

  componentDidMount() {
    var user =
      this.props.location.state === undefined
        ? this.props.match.params.id
        : this.props.location.state[0];
    if (typeof user === "string") {
      showLoader();
      axios_instance
        .get("/v1/users/" + user)
        .then((response) => {
          console.log(response.data.data.attributes["subscription"]);
          response.data.data.attributes[
            "subscription-next-billing-cycle"
          ] = `Siguiente Mensualidad: ${moment(
            response.data.data.attributes["subscription-next-billing-cycle"]
          ).format("YYYY/MM/DD")}`;
          var storables = this.setStorables(
            response.data.data.attributes["subscription"]
          );
          var { warehouseItems, warehouseBoxes, houseItems, houseBoxes } =
            storables;
          this.setState({
            user: response.data.data,
            warehouseItems: this.initStorables(
              warehouseItems,
              storableTypeItem
            ),
            warehouseBoxes: this.initStorables(warehouseBoxes, storableTypeBox),
            houseItems: this.initStorables(houseItems, storableTypeItem),
            houseBoxes: this.initStorables(houseBoxes, storableTypeBox),
          });
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
        });
    } else {
      user.attributes[
        "subscription-next-billing-cycle"
      ] = `Siguiente Mensualidad: ${moment(
        user.attributes["subscription-next-billing-cycle"]
      ).format("YYYY/MM/DD")}`;
      var storables = this.setStorables(user.attributes["subscription"]);
      var { warehouseItems, warehouseBoxes, houseItems, houseBoxes } =
        storables;

      var warehouseStorables =
        user.attributes["subscription"]["in_warehouse"].data || [];
      var houseStorables =
        user.attributes["subscription"]["in_house"].data || [];

      this.setState({
        user: user,
        warehouseItems: this.initStorables(warehouseItems, storableTypeItem),
        warehouseBoxes: this.initStorables(warehouseBoxes, storableTypeBox),
        houseItems: this.initStorables(houseItems, storableTypeItem),
        houseBoxes: this.initStorables(houseBoxes, storableTypeBox),
        warehouseStorables,
        houseStorables,
      });
    }
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize,
      by_user: this.props.match.params.id,
    };

    if (state.sorted.length) {
      state.sorted.forEach(function (sort) {
        let sortBy = sort.id;
        params[sortBy] = sort.desc ? "desc" : "asc";
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function (filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance
      .get("/v2/order_v2s", { params })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          this.setState({
            orders: res.data.data,
            loading: false,
            // pages: res.data.meta.pagination["total-pages"],
          });
        }
      })
      .catch(() => {});
  };

  setStorables = (subscription) => {
    var warehouseItems, warehouseBoxes, houseItems, houseBoxes;
    if (subscription) {
      warehouseItems = subscription.in_warehouse.data
        ? subscription.in_warehouse.data.filter((storable) => {
            return storable.attributes &&
              storable.attributes["storable-type"] === "StorageItem"
              ? storable
              : null;
          })
        : [];
      warehouseBoxes = subscription.in_warehouse.data
        ? subscription.in_warehouse.data.filter((storable) => {
            return storable.attributes &&
              storable.attributes["storable-type"] !== "StorageItem"
              ? storable
              : null;
          })
        : [];
      houseItems = subscription.in_house.data
        ? subscription.in_house.data.filter((storable) => {
            return storable.attributes &&
              storable.attributes["storable-type"] === "StorageItem"
              ? storable
              : null;
          })
        : [];
      houseBoxes = subscription.in_house.data
        ? subscription.in_house.data.filter((storable) => {
            return storable.attributes &&
              storable.attributes["storable-type"] !== "StorageItem"
              ? storable
              : null;
          })
        : [];
    } else {
      warehouseItems = [];
      warehouseBoxes = [];
      houseItems = [];
      houseBoxes = [];
    }
    var storables = { warehouseItems, warehouseBoxes, houseItems, houseBoxes };
    return storables;
  };

  goBack = () => {
    this.props.history.push("/admin/users");
  };

  renderProducts = (storables, storablesName) => {
    let storablesHtml = storables.map((storable, index) => {
      let storableIcon = objectIcon;
      if (storable.storableType === storableTypeBox) {
        storableIcon = boxIcon;
        return (
          <UserStorable
            key={index}
            storableType={resources.items.labels.box}
            storableIcon={storableIcon}
            title={storable.attributes.title}
            selected={storable.attributes.selected}
            selectable={true}
            toggleStorableSelected={this.toggleStorableSelected}
            storableIndex={index}
            storablesName={storablesName}
            isAdmin={true}
            storable={storable}
            goToStorable={() => this.goToStorable(storable)}
            goToInventory={() => this.goToInventory(storable.id)}
          />
        );
      } else {
        return (
          <UserStorable
            key={index}
            storableType={resources.items.labels.object}
            storableIcon={storableIcon}
            title={storable.attributes.title}
            selected={storable.attributes.selected}
            selectable={true}
            toggleStorableSelected={this.toggleStorableSelected}
            storableIndex={index}
            storablesName={storablesName}
            isAdmin={true}
            storable={storable}
            goToStorable={() => this.goToStorable(storable)}
            goToInventory={() => this.goToInventory(storable.id)}
          />
        );
      }
    });
    return storablesHtml;
  };

  toggleStorableSelected = (selectable, key, storableName) => {
    let storables = {};
    storables[storableName] = this.state[storableName];
    storables[storableName][key].attributes.selected =
      !storables[storableName][key].attributes.selected;
    this.setState({
      storables,
    });
  };

  goToInventory = (storableId) => {
    this.props.history.push(`boxes/${storableId}/inventory`);
  };

  goToStorable = (storable) => {
    if (storable.attributes.storable["storable-id"]) {
      if (storable.storableType === "box") {
        this.props.history.push(
          `/Boxes/${storable.attributes.storable["storable-id"]}`
        );
      } else {
        this.props.history.push(
          `/storage-items/${storable.attributes.storable["storable-id"]}`
        );
      }
    }
  };

  goToReceipts = (userId) => {
    this.props.history.push(`/admin/users/${userId}/receipts`);
  };

  enableSelectable = (storableName1, selectableType) => {
    if (this.state.storableActions[selectableType]) {
      if (this.atLeastOneSelected(storableName1)) {
        console.log("pushed");
        console.log([
          this.state.lastOrder,
          this.state[storableName1],
          selectableType,
        ]);
        this.props.history.push("/users/order", [
          this.state.lastOrder,
          this.state[storableName1],
          selectableType,
          this.state.user,
        ]);
      } else {
        NotificationManager.warning(
          "Favor de seleccionar al menos 1 OBJETO o CAJA",
          ""
        );
      }
    } else {
      let storables = {};
      storables[storableName1] = this.state[storableName1];
      storables.storableActions = this.state.storableActions;
      Object.keys(storables.storableActions).forEach((key) => {
        return (storables.storableActions[key] = key === selectableType);
      });
      if (storables[storableName1]) {
        storables[storableName1].map((storable) => {
          return (storable.attributes.selectable = true);
        });
        this.setState({
          storables,
        });
      }
    }
  };

  disableSelectable = (storableName1, e) => {
    if (e) e.stopPropagation();
    let storables = {};
    storables[storableName1] = this.state[storableName1];
    storables.storableActions = this.state.storableActions;
    Object.keys(storables.storableActions).forEach((key) => {
      return (storables.storableActions[key] = false);
    });
    if (storables[storableName1]) {
      storables[storableName1].map((storable) => {
        storable.attributes.selectable = false;
        storable.attributes.selected = false;
        return storable;
      });

      this.setState({
        storables,
      });
    }
  };

  atLeastOneSelected = (storableName1) => {
    for (let i = 0; i < this.state[storableName1].length; i++) {
      if (this.state[storableName1][i].attributes.selected) return true;
    }
    return false;
  };

  getTrProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.index === 0) {
      return {
        style: {
          background: "#5eba7d", // Cambia este valor al color de fondo deseado
        },
      };
    } else if (rowInfo) {
      return {
        style: {
          background: "#f29192", // Cambia este valor al color de fondo deseado
        },
      };
    }
    return {};
  };
  render() {
    var { attributes } = this.state.user;
    const cancellingWarehouse = this.state.storableActions.cancellingWarehouse;
    const warehouse = this.state.storableActions.warehouse;
    const cancellingHouse = this.state.storableActions.cancellingHouse;
    const house = this.state.storableActions.house;

    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content w-100">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div className="flex-row">
                <h3
                  className="dashboard-content-header-title"
                  style={{ alignSelf: "center" }}
                >
                  {attributes["full-name"]}
                </h3>
                <div
                  className="btn-box-1 padding-2 dashboard-action"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.goToReceipts(this.state.user.id);
                  }}
                >
                  <img
                    style={{ marginRight: "1em" }}
                    src={OrderIcon}
                    alt="receipt"
                  />
                  <span className="dashboard-action-title">
                    {`Siguiente Mensualidad: ${moment(
                      attributes.subscription.next_billing_date
                    ).format("DD/MMM/YYYY")}`}
                  </span>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body w-100">
            <div
              className="mb-2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <button onClick={this.goBack} className="btn btn-back">
                Regresar
              </button>
              <br />
            </div>

            <div className="row" style={{ minWidth: "100%" }}>
              <div className="col-sm-12 px-0 w-100">
                <CustomTable
                  {...this.props}
                  columns={orderColumns}
                  data={this.state.orders}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  manual
                  onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: "created-at",
                      desc: true,
                    },
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (column.Header && rowInfo) {
                          this.props.history.push(
                            `/orders_v2/${rowInfo.original.id}`,
                            [rowInfo.original]
                          );
                        } else {
                          // handleOriginal();
                        }
                      },
                    };
                  }}
                  getTrProps={this.getTrProps}
                />
                <br />
              </div>
            </div>

            {/* <div className="flex-row">
              <div className="user-dashboard-container">
                <div className="user-dashboard-banner">
                  <div className="flex-column">
                    <div className="flex-row">
                      <div className="img-box img-box-purple">
                        <img src={warehouseIcon} alt="warehouse" height="30" />
                        <span className="img-box-span">EN BODEGA</span>
                      </div>
                      <span className="main-title">LO QUE ESTAMOS<br /> CUIDANDO POR TI</span>
                    </div>
                  </div>
                  <div className="flex-column">
                    <div className="d-flex ">
                      <div className="btn-box back-red cancel-action pointer" onClick={() => this.enableSelectable('warehouseStorables', 'cancellingWarehouse')}>
                        <img className={cancellingWarehouse ? "dashboard-action-active-left" : "hide-node" } onClick={(e) => this.disableSelectable('warehouseStorables', e)} src={cancelIcon} alt="cancel" />
                        <img height="30" src={boxCancelIcon} alt="cancel" />
                        <span className="cancel-box-span">CANCELAR</span>
                      </div>
                      <div className="btn-box padding-2 dashboard-action pointer" onClick={() => this.enableSelectable('warehouseStorables', 'warehouse')}>
                        <img className={warehouse ? "dashboard-action-active" : "hide-node" } onClick={(e) => this.disableSelectable('warehouseStorables', e)} src={cancelIcon} alt="cancel" />
                        <img height="30" style={{marginRight: '1vw'}} src={truckIcon} alt="action" />
                        <span className="dashboard-action-title">
                          {
                            warehouse ? "PROCESAR" : "AGENDAR"
                          }
                          <br />
                          <span className="blue-item">RECUPERACIÓN</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"flex-row dashboard-action-instructions"}>

                </div>
                <div className="user-dashboard-content-grid">
                  { this.renderProducts(this.state.warehouseBoxes, 'warehouseBoxes') }
                  { this.renderProducts(this.state.warehouseItems, 'warehouseItems') }
                </div>
              </div>
            </div> */}

            {/* <div className="flex-row">
              <div className="user-dashboard-container">
                <div className="user-dashboard-banner">
                  <div className="flex-column">
                    <div className="flex-row">
                      <div className="img-box img-box-purple">
                        <img src={warehouseIcon} alt="warehouse" height="30" />
                        <span className="img-box-span">EN CASA</span>
                      </div>
                      <span className="main-title">TEMPORALMENTE</span>
                    </div>
                  </div>
                  <div className="flex-column">
                  <div className="flex-row">
                      <div className="btn-box back-red cancel-action pointer" onClick={() => this.enableSelectable('houseStorables', 'cancellingHouse')}>
                        <img className={cancellingHouse ? "dashboard-action-active-left" : "hide-node" } onClick={(e) => this.disableSelectable('houseStorables', e)} src={cancelIcon} alt="cancel" />
                        <img src={boxCancelIcon} alt="cancel" />
                      </div>
                      <div className="btn-box padding-2 dashboard-action pointer" onClick={() => this.enableSelectable('houseStorables', 'house')}>
                        <img className={house ? "dashboard-action-active" : "hide-node" } onClick={(e) => this.disableSelectable('houseStorables', e)} src={cancelIcon} alt="cancel" />
                        <img style={{marginRight: '1vw'}} src={truckIcon} alt="cancel" />
                        <span className="dashboard-action-title">
                          {
                            house ? "PROCESAR" : "AGENDAR"
                          }
                          <br />
                          <span className="blue-item">RECOLECCIÓN</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"flex-row dashboard-action-instructions"}>

                </div>
                <div className="user-dashboard-content-grid">
                  { this.renderProducts(this.state.houseBoxes, 'houseBoxes') }
                  { this.renderProducts(this.state.houseItems, 'houseItems') }
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
