import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import moment from 'moment';

export const SignOut = (history) => {
  showLoader();
  axios_instance.delete('/auth/sign_out').then(function() {
  }).then(function() {
  }).finally(function() {
    localStorage.clear();
    sessionStorage.clear();
    hideLoader();
    history.push('/');
  })
};


export const IsUserSignedIn = (history) => {
  let headers = JSON.parse(localStorage.getItem("headers"));
  let user = JSON.parse(localStorage.getItem('currentUser'))
  if (headers && user) {
    let momentExpiry = moment.unix(parseInt(headers.expiry));
    var now = moment();
    if(headers["access-token"] && now < momentExpiry) {
      return true;
    }
    return false;
  }
  return false;
};


export const IsUserClient = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if(currentUser) {
    const role = currentUser.attributes.role;
    return role === "client";
  }

  return false;
}

export const GoToUserDashboard = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if(currentUser) {
    const role = currentUser.attributes.role;
    if(role === "client") {
      return "/users";
    } else {
      return "/dashboard";
    }
  }
  return "/";
}

export const GetAuthToken = () => {
  return localStorage.getItem("access-token");
}

export default IsUserSignedIn;
