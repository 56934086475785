import React from "react";
import arrowDown from "img/arrow-down.svg";
import orderIcon from "img/order-ico-01.svg";
import contactIcon from "img/faq-icon.svg";

export default class DashboardMenu extends React.Component {
  render() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return (
      <div className="dashboard-menu">
        <a href="/dashboard" className="dashboard-menu-item">
          <img
            src={orderIcon}
            alt="order"
            className="dashboard-menu-item-icon"
          />
          <p>Inicio</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        {/* <a href="/orders" className="dashboard-menu-item">
          <img src={orderIcon} alt="order" className="dashboard-menu-item-icon" />
          <p>Órdenes</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a> */}
        <a href="/orders_v2" className="dashboard-menu-item">
          <img
            src={orderIcon}
            alt="order"
            className="dashboard-menu-item-icon"
          />
          <p>Órdenes V2</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        {currentUser.attributes.role === "admin" && (
          <a href="/admin/products" className="dashboard-menu-item">
            <img
              style={{ width: "15%" }}
              src={contactIcon}
              alt="order"
              className="dashboard-menu-item-icon"
            />
            <p>PRODUCTOS</p>
            <div className="dashboard-menu-item-arrow">
              <img height="10vh" src={arrowDown} alt="arrow-down" />
            </div>
          </a>
        )}
        {currentUser.attributes.role === "admin" && (
          <a href="/admin/plans" className="dashboard-menu-item">
            <img
              style={{ width: "15%" }}
              src={contactIcon}
              alt="order"
              className="dashboard-menu-item-icon"
            />
            <p>PLANES</p>
            <div className="dashboard-menu-item-arrow">
              <img height="10vh" src={arrowDown} alt="arrow-down" />
            </div>
          </a>
        )}
        <a href="/admin/users" className="dashboard-menu-item">
          <img
            src={orderIcon}
            alt="order"
            className="dashboard-menu-item-icon"
          />
          <p>Usuarios</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        {/* <a href="/warehouses" className="dashboard-menu-item">
          <img src={orderIcon} alt="order" className="dashboard-menu-item-icon" />
          <p>ALMÁCEN</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        <a href="/storage-items" className="dashboard-menu-item">
          <img src={orderIcon} alt="order" className="dashboard-menu-item-icon" />
          <p>OBJETOS</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
        <a href="/boxes" className="dashboard-menu-item">
          <img src={orderIcon} alt="order" className="dashboard-menu-item-icon" />
          <p>CAJAS</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a> */}
        <a href="/admin/contact-forms" className="dashboard-menu-item">
          <img style={{width: '15%'}} src={contactIcon} alt="order" className="dashboard-menu-item-icon" />
          <p>CONTACTOS</p>
          <div className="dashboard-menu-item-arrow">
            <img height="10vh" src={arrowDown} alt="arrow-down" />
          </div>
        </a>
      </div>
    );
  }
}
