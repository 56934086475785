import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from "templates/common/dashboard-menu";
import { NotificationManager } from 'react-notifications';
import QuoteModal from 'templates/modals/quote-modal';
import Quote from 'templates/admin/contact_forms/quote';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactForm: {
        id: 0,
        attributes: {
          quotes: []
        }
      },
      modalVisibility: false
    }
  }

  componentDidMount() {
    showLoader();
    axios_instance.get(`/v1/contact_forms/${this.props.match.params.id}`).then((res) => {
      let contactForm = res.data.data;
      contactForm.attributes.quotes = contactForm.attributes.quotes || [];
      this.setState({
        contactForm: res.data.data
      });
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  goBack = () => {
    this.props.history.push('/admin/contact-forms');
  }

  openQuoteModal = () => {
    this.setState({
      modalVisibility: true
    });
  }

  postQuote = (params) => {
    const quoteParams = {
      quote: {
        price: params.price,
        size: params.size,
        message: params.message,
        contact_form_id: this.state.contactForm.id,
        additional_concept: params.additional_concept,
        additional_amount: params.additional_amount
      }
    };
    showLoader();
    axios_instance.post('/v1/quotes', quoteParams).then((res) => {
      NotificationManager.success("Se ha enviado la cotización.");
      this.setState({
        modalVisibility: false
      }, this.goBack());
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  closeQuoteModal = () => {
    this.setState({
      modalVisibility: false
    });
  }

  complete = () => {
    showLoader();
    axios_instance.put(`/v1/contact_forms/${this.props.match.params.id}`, {
      contact_form: {is_completed: true}
    }).then((res) => {
      NotificationManager.success('Se ha cerrado este mensaje');
      this.props.history.goBack();
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    });
  }

  render() {
    const quotes = this.state.contactForm.attributes.quotes.slice(0).reverse().map((quote, index) => {
      return (
        <Quote key={index} quote={quote} />
      );
    });
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">CONTACTO #{this.state.contactForm.id}</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <button onClick={this.goBack} className="btn btn-back">Regresar</button>
              {
                this.state.contactForm.attributes['is-quote'] ?
                (
                  <button onClick={this.openQuoteModal} className="btn btn-primary">Cotizar</button>
                )
                :
                (
                  <button onClick={this.complete} className="btn btn-primary">Cerrar</button>
                )
              }
            </div>
            <div>
              <span><b>Nombre: </b> {this.state.contactForm.attributes.name}</span>
            </div>
            <div>
              <span><b>Email: </b> {this.state.contactForm.attributes.email}</span>
            </div>
            <div>
              <span><b>¿Es cotización?: </b> {this.state.contactForm.attributes['is-quote-t']}</span>
            </div>
            <div>
              <span style={{whiteSpace: "pre-line"}}><b>Mensaje: </b><br/> {this.state.contactForm.attributes.message}</span>
            </div>
          </div>
          {quotes}
        </div>
        <QuoteModal
          modalVisibility={this.state.modalVisibility}
          closeModal={this.closeQuoteModal}
          postQuote={this.postQuote}
        />
      </div>
    )
  }
}
