import React from "react";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import DashboardMenu from "templates/common/dashboard-menu";

export default class PlanEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: {
        attributes: {
          discount_percentage: 0,
          monthly_interval: 0,
          description: "",
          title: "",
        },
      },
      isNew: true,
    };
  }

  componentDidMount() {
    var plan =
      this.props.location.state === undefined
        ? "new"
        : this.props.location.state[0];
    if (plan === "new") {
      this.setState({
        isNew: true,
      });
    } else {
      showLoader();
      axios_instance
        .get(`/v2/plans/${plan.id}`)
        .then((response) => {
          const plan = response.data.data;
          plan.attributes.discount_percentage =
            plan.attributes["discount-percentage"];
          plan.attributes.monthly_interval =
            plan.attributes["monthly-interval"];
          this.setState({
            plan: plan,
            isNew: false,
            id: response.data.data.id,
          });
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
        });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "active_status") {
      var val = value === true ? "active" : "inactive";
      this.setState({ [name]: val });
    } else {
      const plan = { ...this.state.plan };
      plan.attributes[name] = value;
      this.setState({ plan });
    }
  };

  submit = () => {
    showLoader();
    var params = {
      plan: {
        discount_percentage: this.state.plan.attributes.discount_percentage,
        monthly_interval: this.state.plan.attributes.monthly_interval,
        description: this.state.plan.attributes.description,
        title: this.state.plan.attributes.title,
      },
    };
    if (this.state.isNew) {
      axios_instance
        .post("/v2/plans", params)
        .then((response) => {
          this.props.history.push("/admin/plans", [response.data.data]);
        })
        .finally(() => {
          hideLoader();
        });
    } else {
      axios_instance
        .put("/v2/plans/" + this.props.match.params.id, params)
        .then((response) => {
          this.props.history.push("/admin/plans", [response.data.data]);
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
        });
    }
  };
  delete = () => {
    showLoader();
    axios_instance
      .delete("/v2/plans/" + this.props.match.params.id)
      .then((response) => {
        this.props.history.push("/admin/plans");
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">Crear Plan</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="form-group d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <span>Titulo:</span>
                  <input
                    type="text"
                    value={this.state.plan.attributes.title}
                    name="title"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="form-group d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <span>Descuento %:</span>
                  <input
                    type="number"
                    value={this.state.plan.attributes.discount_percentage}
                    name="discount_percentage"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div
                  className="form-group d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <span>Intervalo Mensual:</span>
                  <input
                    type="number"
                    value={this.state.plan.attributes.monthly_interval}
                    name="monthly_interval"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div
                  className="form-group d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <span>Descripcion:</span>
                  <input
                    type="text"
                    value={this.state.plan.attributes.description}
                    name="description"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-primary mr-2"
                type="submit"
                onClick={this.submit}
              >
                {" "}
                Confirmar
              </button>
              {!this.state.isNew && (
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.delete}
                >
                  {" "}
                  Eliminar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
