import React from "react";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from "templates/common/dashboard-menu";
import CustomTable from "components/table";

const objectColumns = [
  {
    Header: "id",
    accesor: d => d.id,
    id: "id",
    show: false
  },
  {
    Header: "Id",
    accessor: d => d.id,
    id: 'id',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => ("Espacio " + value)
  },
  {
    Header: "Nombre",
    accessor: d => d.attributes.name,
    id: 'nombre',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "Almacenados",
    accessor: d => d.attributes["storable-count"],
    id: 'storable_count',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "Activo?",
    accessor: d => d.attributes['active-status'],
    id: "active_status",
    style: {
      cursor: "pointer"
    },
    Cell: ({ value }) => (value === true ? "Sí" : "No"),
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "active") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false;
    },
    Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Todos</option>
        <option value="true">Activo</option>
        <option value="false">Inactivo</option>
      </select>
  }
];

export default class Warehouses extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      warehouse: {
        attributes: {
          "active-status": false,
        },
        id: 0
      },
      data: [],
      pages: 0,
      loading: false
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize,
      by_warehouse_id: this.state.warehouse.id
    }

    if(state.sorted.length) {
      state.sorted.forEach(function(sort) {
        params[sort.id] = sort.desc ? "desc" : "asc"
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function(filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance.get("/v1/spaces?by_stored_count=1", {params})
    .then((res) => {
      if(res.data.data) {
        this.setState({
          data: res.data.data,
          loading:false,
          pages: res.data.meta.pagination["total-pages"],
        });
      }
    }).catch(() => {
    });
  }

  addSpace = () => {
    this.props.history.push('/Spaces/new')
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.match.params.id !== prevState.warehouse.id){
       return { warehouse_id: nextProps.match.params.id};
    }
    else {
       return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.id !== this.state.warehouse_id){
      axios_instance.get('/v1/warehouses/' + this.state.warehouse_id).then((response) => {
        this.setState({
          warehouse: response.data.data,
        })
      }).catch(() => {
      })
    }
  }

  componentDidMount() {
    showLoader();
    var warehouse = this.props.location.state === undefined ? this.props.match.params.id : this.props.location.state[0]
    if (typeof(warehouse) === "string") {
      axios_instance.get('/v1/warehouses/' + warehouse).then((response) => {
        this.setState({
          warehouse: response.data.data,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      this.setState({
        warehouse: warehouse,
      }, hideLoader());
    }
  }

  goBack = () => {
    this.props.history.push('/warehouses');
  }

  edit = () => {
    this.props.history.push('/warehouses/' + this.props.match.params.id + '/edit');
  }

  render() {
    var { attributes } = this.state.warehouse
    const { data, pages, loading } = this.state;
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3 className="dashboard-content-header-title">Almacen #{this.state.warehouse.id}</h3>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <button type="button" className="btn btn-primary" onClick={this.addSpace}>
                    + Agregar Espacio
                  </button>
                  <button className="btn btn-primary ml-2" onClick={this.edit}>Editar</button>
                  <button onClick={this.goBack} className="btn btn-back ml-2">Regresar</button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="box-info-grid">
              <div>
                <div>
                  <div className="box-info-label">Nombre: </div>
                  {attributes["name"]}
                </div>
                <div>
                  <div className="box-info-label">Estatus: </div>
                  {attributes["active-status"] ? "Activo" : "Inactivo"}
                </div>
                {/* Aqui va la tabla */}
                <div>
                <CustomTable {...this.props}
                  columns={objectColumns}
                  data={data}
                  pages={pages}
                  loading={loading}
                  manual
                  onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
                  filterable
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: "id",
                      desc: false
                    }
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if(column.Header) {
                          this.props.history.push(`/spaces/${rowInfo.original.id}`, [rowInfo.original])
                        } else {
                          handleOriginal();
                        }
                      }
                    }
                  }}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
