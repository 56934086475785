import React from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { axios_instance } from "components/axios";
import { resources } from "components/string-resources";
import CustomTable from "components/table";
import DashboardMenu from "templates/common/dashboard-menu";
import moment from "moment";

function shippingDate(date) {
  if (date) {
    return `${date.substring(0, 10)} - ${moment.parseZone(date).format("hh:mm a")}`;
  }
  return "";
}

const orderColumns = [
  {
    Header: "# Folio",
    accessor: (d) => d.id,
    id: "id",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Cliente",
    accessor: (d) =>
      `${d.attributes.user.name} ${d.attributes.user.last_name}`,
    id: "name",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Producto",
    accessor: (d) => d.attributes["product-name"],
    id: "product",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
    // Cell: ({value}) => ( value ? resources.order.type[value] : ""),
    // filterMethod: (filter, row) => {
    //   if (filter.value === "all") {
    //     return true;
    //   }
    //   if (filter.value === "active") {
    //     return row[filter.id] === true;
    //   }
    //   return row[filter.id] === false;
    // },
    // Filter: ({ filter, onChange }) =>
    //   <select
    //     onChange={event => onChange(event.target.value)}
    //     style={{ width: "100%" }}
    //     value={filter ? filter.value : "all"}
    //   >
    //     <option value="all">Todos</option>
    //     <option value="assign">Asignar</option>
    //     <option value="pickup">Recoger</option>
    //     <option value="return">Regresar</option>
    //     <option value="cancel">Cancelar</option>
    //     <option value="assign_quote">Cotización</option>
    //   </select>
  },
  {
    Header: "Precio",
    accessor: (d) => d.attributes["price"],
    id: "price",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Intervalo mensual",
    accessor: (d) => d.attributes["monthly-interval"],
    id: "monthly-interval",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Descuento %",
    accessor: (d) => d.attributes["discount-percentage"],
    id: "discount-percentage",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Recoleccion",
    accessor: (d) => shippingDate(d.attributes["shipping-date"]),
    id: "shipping-date",
    style: {
      cursor: "pointer",
      textAlign: "Center",
    },
    filterable: false,
  },
  {
    Header: "Creado el",
    accessor: (d) => d.attributes["created-at"],
    id: "created_at",
    style: {
      cursor: "pointer",
    },
    filterable: false,
  },
];

export default class Boxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
      loading: true,
      page: 1,
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize,
    };

    if (state.sorted.length) {
      state.sorted.forEach(function (sort) {
        let sortBy = sort.id;
        params[sortBy] = sort.desc ? "desc" : "asc";
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function (filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance
      .get("/v2/order_v2s", { params })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data) {
          this.setState({
            data: res.data.data,
            loading: false,
            pages: res.data.meta.pagination["total-pages"],
          });
        }
      })
      .catch(() => {});
  };

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content w-100">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <h3 className="dashboard-content-header-title">Órdenes</h3>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body w-100">
            <div className="row w-100" >
              <div className="col-12 px-0" style={{maxWidth:'100%'}}>
                <CustomTable
                  {...this.props}
                  columns={orderColumns}
                  data={data}
                  pages={pages}
                  loading={loading}
                  manual
                  onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
                  filterable
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: "shipping_date",
                      desc: true,
                    },
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (column.Header) {
                          this.props.history.push(
                            `/orders_v2/${rowInfo.original.id}`,
                            [rowInfo.original]
                          );
                        } else {
                          handleOriginal();
                        }
                      },
                    };
                  }}
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
