import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from "templates/common/dashboard-menu";

export default class BoxEdit extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      box: {
        attributes: {
          "box-types": {
            medium: "Mediana",
            large: "Grande",
            "extra-large": "Extra Grande"
          }
        }
      },
      quantity: 0,
      isNew: true
    };
  }

  componentDidMount() {
    var box = this.props.location.state === undefined ? "new" : this.props.location.state[0]
    if (box === "new") {
      showLoader();
      axios_instance.get('/v1/spaces')
      .then((response) => {
        this.setState({
          isNew: true,
          spaces: response.data.data,
          active_status: false,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      this.setState({
        box: box,
        isNew: false,
        space_id: box.attributes.space_id,
        active_status: box["active-status"],
        id: box.id,
        box_type: box.attributes["box-type"]
      })
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === "active_status") {
      var val = value === true ? "active" : "inactive";
      this.setState({[name]: val})
    } else {
      this.setState({[name]: value});
    }
  }

  submit = () => {
    showLoader();
    var params = {
      quantity: this.state.quantity,
      box: {
        id: this.state.box.id,
        space_id: this.state.space_id,
        box_type: this.state.box_type,
        active_status: true
      }
    }
    if (this.state.isNew) {
      axios_instance.post('/v1/boxes', params).then((response) => {
        this.props.history.push('/qr_generator', [response.data.data])
      }).finally(() => {
        hideLoader();
      })
    } else {
      axios_instance.put('/v1/boxes/' + this.props.match.params.id, params).then((response) => {
        this.props.history.push('/boxes')
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    }
  }

  render() {
    var box_types = Object.keys(this.state.box.attributes["box-types"]).map((box_type, index) => {
      return <option key={index} value={box_type}>{this.state.box.attributes["box-types"][box_type]}</option>
    })
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">Crear caja</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="" style={{display: "flex", flexDirection:"row"}}>
              <span>Activo? </span>&nbsp;
              <input type="checkbox" name="active_status" checked onChange={this.handleChange} value={this.state.active_status}/>
            </div>
            <div className="form-group">
              <select style={{ flex: '1 1 100%', marginRight: "1em" }} name="box_type" id="" value={this.state.box_type} onChange={this.handleChange}>
                <option value="">Selecciona el tamaño</option>
                { box_types }
              </select>
            </div>
            {
              this.state.isNew ?
              <div className="form-group" style={{flexDirection:"column"}}>
                <span>Cantidad:</span>
                <input type="number" value={this.state.quantity} name="quantity" onChange={this.handleChange} />
              </div> : ""
            }
            <div>
              <button className="btn btn-primary" type="submit" onClick={this.submit}> Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
