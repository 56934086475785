import React from "react";
import { NotificationManager } from 'react-notifications';
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import CameraModal from 'templates/modals/camera-modal';
import QrModal from 'templates/modals/qr-modal';
import DashboardMenu from 'templates/common/dashboard-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faCamera } from '@fortawesome/free-solid-svg-icons'
import GoBack from 'components/go-back';

export default class Recolect extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      cameraModal:{
        visibility: false,
        products: [],
        currentProduct: {
          product: "",
          id: 0,
          label: ""
        }
      },
      qrModal:{
        visibility: false,
        products: [],
        type: "pickup_pending",
        currentProduct: {
          product: "",
          id: 0,
          label: ""
        }
      },
      products: [],
      order: {
        attributes: {
          products:[],
          "subscription-boxes": {
            data: []
          }
        }
      }
    };
  }

  componentDidMount() {
    showLoader();
    if (this.props.location.state) {
      const order = this.props.location.state[0];
      this.setInitialOrder(order);
      hideLoader();
    } else {
      axios_instance.get(`/v1/orders/${this.props.match.params.id}`).then((response) => {
        const order = response.data.data;
        this.setInitialOrder(order);
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  setInitialOrder = (order) => {
    const products = order.attributes['subscription-boxes'].data.map((product, i) => {
      return {
        productType: product.attributes['storable-type'],
        label: product.attributes['title'] + " (" + product.attributes["default-title"] + ")",
        id: product['id'],
        index: i,
        currentStorableId: product.attributes.storable["storable-id"]
      }
    });

    this.setState({ order, products });
  }

  openModal = (product, modalName) => {
    const currentProduct = this.state.products.filter((p) => { return p.id === product.id })[0];
    this.createProduct(currentProduct, modalName);
  }

  closeCameraModal = () => {
    this.setState((prevState) => ({cameraModal: {...prevState.cameraModal, visibility: false }}));
  }

  closeQrModal = () => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: false }}));
  }

  takePicture = (image, product) => {
    const products = this.state.products.map((p) => {
      let images = p.images || [];
      if(product.id === p.id) {
        images.push({image: image});
      }
      p.images = images;
      return p;
    });
    this.setState({
      products: products
    });
  }

  pickupBoxes = (product, QRValue) => {
    const products = this.state.products.map((p) => {
      if(p.id === product.id) {
        // const productType = product.productType
        if(QRValue.type[0].toLowerCase() !== product.productType[0].toLowerCase())
        {
          NotificationManager.error("Diferencia entre etiqueta y tipo de producto", "Verifica que lo que estes asignando sea Caja u Objeto", 5000);
        } else {
          if(
            parseInt(QRValue.id) !== p.currentStorableId
            && p.currentStorableId
          )
          {
            NotificationManager.error("La caja u objeto que intentas asignar no corresponde con la etiqueta.");
            NotificationManager.error(`Etiqueta: ${QRValue.id}. \n Caja/Objeto: ${p.currentStorableId}`);
          } else {
            p.storableId = QRValue.id;
            if(product.productType[0].toLowerCase() === 's')
            {
              NotificationManager.success("Objeto Asignado", "", 3000);
            } else {
              NotificationManager.success("Caja Asignada", "", 3000);
            }
          }
        }


      }
      return p;
    });
    this.setState({
      products: products
    });
    this.nextProduct(product, "qrModal");
  }

  createProduct = (product, modal) => {
    this.setState({
      [modal]: {
        visibility: true,
        currentProduct: product,
        type: 'pickup_pending',
        products: this.state.products
      }
    });
  }

  nextProduct = (currentProduct, modal) => {
    let nextIndex = currentProduct.index + 1;
    const nextProduct = this.state.products.filter((p) => { return p.index === nextIndex })[0];
    if(nextProduct)
      this.createProduct(nextProduct, modal);
  }

  lastProduct = (currentProduct, modal) => {
    let lastIndex = currentProduct.index - 1;
    const nextProduct = this.state.products.filter((p) => { return p.index === lastIndex })[0];
    if(nextProduct)
      this.createProduct(nextProduct, modal);
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const products = this.state.products.map((product) => {
      let description = "";
      if(product.id === name) {
        description = value;
        product.description = description;
      }
      return product;
    });

    this.setState({
      products: products
    });
  }

  assignBoxes = () => {
    showLoader();
    let formData = new FormData();
    let successProducts = this.state.products.map((product, index) => {
      formData.append(`order[subscription_boxes_attributes][${index}][id]`, product.id);
      if(product.storableId)
      {
        formData.append(`order[subscription_boxes_attributes][${index}][storable_id]`, product.storableId );
        formData.append(`order[subscription_boxes_attributes][${index}][storable_type]`, product.productType );
      }
      else {
        return false;
      }
      if(product.images) {
        product.images.map((p) => {
          formData.append(`order[subscription_boxes_attributes][${index}][boxmi_attachments][]`, p.image);
          return "";
        });
      }
      formData.append(`order[subscription_boxes_attributes][${index}][boxmi_description]`, product.description );
      return product;
    });
    if(successProducts.includes(false))
    {
      NotificationManager.error("Favor de revisar que todos los objetos/cajas hayan sido escaneados", "Falta Escaneo", 3000);
      hideLoader();
    } else {
      axios_instance.post('/v1/orders/' + this.state.order.id + '/pickup_storables', formData)
      .then((response) => {
        this.props.history.push('/store_boxes')
        NotificationManager.success("Orden Completada", "", 3000);
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  removeImage = (product, image) => {
    var images = this.state[product].images
    var newImages = images.filter((attachment) => { return attachment.id !== image.id});
    this.setState({ [product]: {...this.state[product], images: newImages, counter: 0 } } )
  }

  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <GoBack />
            <div className="dashboard-content-header-item">
              <h2>Orden #{this.state.order.id}</h2>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            {
              this.state.products.map((product, index) => {
                const productImages = product.images || [];
                return(
                  <div key={index} className="recolect-item recolect-card">
                    <div className="recolect-item-header" onClick={() => this.openModal(product, "qrModal")}>
                      <h1 style={{paddingRight: "1vw"}}>{product.label}</h1>
                      {
                        product.storableId ?

                        <FontAwesomeIcon  className="recolect-item-qr-icon" icon={faQrcode} size="6x" style={{background: "#5E3DFC"}}/>
                        :
                        <FontAwesomeIcon  className="recolect-item-qr-icon" icon={faQrcode} size="6x" style={{background: "#F74164"}}/>
                      }
                    </div>
                    <label style={{ paddingLeft: '1em' }} htmlFor="description">IMAGENES:</label>
                    <div className="upload-photo-method" onClick={() => {this.openModal(product, "cameraModal")}}>
                      <FontAwesomeIcon icon={faCamera} />
                    </div>
                    {
                      productImages.length ? (
                        <div className="recolect-item-photos" onClick={() => {this.openModal(product, "cameraModal")}}>
                          {
                            productImages.map((image, index) => {
                              return(
                                <img key={index} className="recolect-item-photo" src={URL.createObjectURL(image.image)} alt="description" onClick={() => { this.removeImage(product.id, image) }}/>
                              )
                            })
                          }
                        </div>
                      ) : ''
                    }
                    <label style={{ paddingLeft: '1em' }} htmlFor="description">DESCRIPCIÓN:</label>
                    <textarea id="description" className="recolect-item-text" name={product.id} onChange={this.handleChange} value={this.state.currentValue}/>
                  </div>
                )
              })
            }
            <button style={{margin: '1em'}} className="btn btn-primary" onClick={() => { this.assignBoxes() }}>RECOLECTAR</button>
            <CameraModal
              display={this.state.cameraModal}
              closeModal={this.closeCameraModal}
              takePicture={this.takePicture}
              nextProduct={this.nextProduct}
              lastProduct={this.lastProduct}
              label={this.state.cameraModal.currentProduct.label}/>
            <QrModal
              display={this.state.qrModal}
              closeModal={this.closeQrModal}
              pickupBoxes={this.pickupBoxes}
              nextProduct={this.nextProduct}
              lastProduct={this.lastProduct}
              label={this.state.qrModal.currentProduct.label}/>
          </div>
        </div>
      </div>
    )
  }
}
