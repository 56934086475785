
import { configureStore } from '@reduxjs/toolkit';
import subscriptionReducer from './reducers';

const store = configureStore({
  reducer: {
    subscription: subscriptionReducer,
  },
});

export default store;