import React from "react";
import DashboardMenu from "templates/common/dashboard-menu";

export default class UserReceipts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      receipt:{
        attributes:{
          amount: "",
          "billing-cycle-start": "",
          month: "",
          "event-type": "",
          "conekta-response": ""
        }
      }
    }
  }

  componentDidMount() {
    this.setState({receipt: this.props.location.state[0]})
  }

  goBack = () => {
    this.props.history.push(`/admin/users/${this.props.match.params.id}/receipts`)
  }

  render() {
    return(
      <div className="dashboard-container dashboard-margin-top">
      <DashboardMenu />
        <div className="user-dashboard-container">
          <div className="user-dashboard-banner">
            <div className="flex-column">
              <div style={{marginLeft: '1em'}} className="flex-row">
                <span style={{fontSize: "2em"}} className="main-title red-item">Pago #{this.props.match.params.receiptId}</span>
              </div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', paddingBottom:"20px", paddingLeft:"20px"}}>
            <button onClick={this.goBack} className="btn btn-back">Regresar</button>
            <br />
          </div>
          <div className="receipts-container">
            <div className="receipts-item">

            </div>
          </div>
        </div>
      </div>
    )
  }
}
