import React from 'react';
import 'css/landing-styles.css';
import Slider from "react-slick";
import Process1 from 'img/proceso-01-agenda.svg';
import Process2 from 'img/proceso-02-recolectamos.svg';
import Process3 from 'img/proceso-03-almacenamos.svg';
import Process4 from 'img/proceso-04-entregamos.svg';
import Uncomplicated from 'img/sin-complicaciones-cajas-01.svg';
import VideoIcon from 'img/video-icon.svg';
import WheneverIcon from 'img/cuando-quieres-icon.svg';
import WhereverIcon from 'img/a-donde-quieres-icon.svg';
import ObjectLayoutIcon from 'img/objetos-layout-02.jpg';
import DashboardIcon from 'img/dashboard-mockup.png';
import Quoter from 'templates/common/quoter';

export default class Landing extends React.Component {
  render() {
    var settings = {
      autoplay: true,
      autoplaySpeed: 4500,
      arrows: false,
      dots: true,
      pauseOnFocus: false,
      fade: true
    };
    return (
      <div>
        <main>
          <div className="main-slider">
            <Slider {...settings}>
              <div className="slider-item slider01"></div>
              <div className="slider-item slider02"></div>
              <div className="slider-item slider03"></div>
            </Slider>
          </div>
        </main>
        <section className="como-funciona py-5">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="text-center">Almacenar tus cosas nunca había sido tan fácil</h1>
              </div>
            </div>
            <div className="row mt-5 center-row-ol">
              <ol>
                <li>
                  <img src={Process1} alt="agenda" />
                  <p>Agenda tu cita a través de nuestra plataforma</p>
                </li>
                <li>
                  <img src={Process2} alt="recolectamos" />
                  <p>Recolectamos gratis a domicilio</p>
                </li>
                <li>
                  <img src={Process3} alt="almacenamos" />
                  <p>Almacenamos con seguridad y monitoreo 24/7</p>
                </li>
                <li>
                  <img src={Process4} alt="regresamos" />
                  <p>Te entregamos de regreso cuando quieres</p>
                </li>
              </ol>
            </div>
          </div>
        </section>
        <section className="sin-complicaciones py-5">
          <div className="cajas01">
            {/* <img src={Uncomplicated} alt="sin-complicaciones" /> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="sc-img-container col-xs-4 offset-xs-0 col-sm-2 offset-sm-5 col-md-2 offset-md-5 d-flex align-items-center justify-content-center">
                <button className="link-button" data-toggle="modal" data-target="#exampleModal">
                  <img src={VideoIcon} alt="video" />
                </button>
              </div>
              <div className="col-xs-8 offset-xs-0 col-sm-5 offset-sm-0 col-md-5 offset-md-0 d-flex align-items-center justify-content-center">
                <div className="sc-title-content">
                  <div className="row">
                    <div className="col">
                      <h1>Sin Complicaciones <small>Aquí te explicamos</small></h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <img src={WheneverIcon} alt="whenever" />
                      <p>Cuando Quieres</p>
                    </div>
                    <div className="col-6">
                      <img src={WhereverIcon} alt="wherever" />
                      <p>A donde Quieres</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-body" id="yt-player">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe title="boxmiPlayer" id="boxmiPlayer" width="560" height="315" src="" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Quoter quoterSection={0} />
        <section className="cajas mt-5 pb-0 pb-sm-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-7 col-lg-6">
                <h1>No gastes en cajas</h1>
                <h4>¡Las nuestras son GRATIS!</h4>
                <p>Guarda tus cosas de la manera más segura ya que nuestras cajas son firmes y resistentes. Días antes a tu cita nuestros Boxeros te llevarán las cajas que solicites para que puedas empacar con tiempo.</p>
                <h4 className="mt-5">Características de las cajas</h4>
                <table>
                  <thead>
                      <tr>
                        <th>Tamaño</th>
                        <th>Ancho</th>
                        <th>Largo</th>
                        <th>Alto</th>
                        <th>Peso Max.</th>
                        <th>Volumen</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span>Mediana</span></td>
                      <td>40 cm</td>
                      <td>60 cm</td>
                      <td>32 cm</td>
                      <td>25 kg</td>
                      <td>58 lts</td>
                    </tr>
                    <tr>
                      <td><span>Grande</span></td>
                      <td>50 cm</td>
                      <td>60 cm</td>
                      <td>32 cm</td>
                      <td>30 kg</td>
                      <td>71 lts</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section className="objetos my-5">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <img src={ObjectLayoutIcon} alt="objetos-layout" className="img-fluid" />
              </div>
              <div className="col-xs-12 col-sm-6">
                <h1>Libera tu espacio</h1>
                <h4>¿Te estorban tus muebles, hobbies o pino de navidad?</h4>
                <p>También podemos cuidar de forma segura todo tipo de objetos grandes que no quepan en cajas, hasta que los necesites de regreso.</p>
                <h4 className="my-5">Características de los objetos <small>*Medidas Máximas</small></h4>
                <table>
                  <thead>
                    <tr>
                      <th>Tamaño</th>
                      <th>Ancho</th>
                      <th>Largo</th>
                      <th>Alto</th>
                      <th>Peso Max.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span>Medianos</span></td>
                      <td>0.5 mts</td>
                      <td>0.5 mts</td>
                      <td>1 mts</td>
                      <td>35 kg</td>
                    </tr>
                    <tr>
                      <td><span>Grandes</span></td>
                      <td>1 mts</td>
                      <td>1 mts</td>
                      <td>1 mts</td>
                      <td>50 kg</td>
                    </tr>
                    <tr>
                      <td><span>Extragrandes</span></td>
                      <td>1 mts</td>
                      <td>1.5 mts</td>
                      <td>3 mts</td>
                      <td>75 kg</td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-5">Si tienes objetos extragrandes que excedan nuestras medidas máximas por favor <a href="/contact?quote=true">ponte en contacto con nosotros</a> para hacerte una cotización.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="dashboard">
          <div className="container-fluid">
            <div className="row no-gutters">
              <div className="col-xs-12 col-sm-6">
                <img src={DashboardIcon} alt="dashboard" className="img-fluid" />
              </div>
              <div className="dashboard-info-col col-xs-12 col-sm-6 d-flex">
                <h1 className="">Tus cosas a un click de distancia</h1>
                <div className="">
                  <p>Ingresa a tu Dashboard en línea para administrar lo que tienes almacenado en Boxmi. Mantén un inventario fotográfico de todas tus cosas, incluyendo los artículos dentro de cada caja.</p>
                  <p>Podrás solicitar de regreso los objetos y cajas que necesites con un sólo click.</p>
                </div>
              </div>
            </div>
          </div>
          <a href="/users" className="dashboard-access-btn">Ingresa a tu dashboard aquí</a>
        </section>
        <Quoter quoterSection={1} />
      </div>
    )
  }
}
