import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from 'templates/common/dashboard-menu';
import CustomTable from "components/table";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import QRCode from 'qrcode.react';
import * as jsPDF from 'jspdf'


const itemsColumns = [
  {
    Header: "id",
    accesor: d => d.id,
    id: "id",
    show: false
  },
  {
    Header: "Tipo",
    accessor: d => d.storable,
    id: 'type',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => (`${value.storable_type === 'Box' ? 'Caja':'Objeto'} ${value.storable_size_t}`)
  },{
    Header: "Nombre",
    accessor: d => d.title,
    id: 'name',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => (value)
  },{
    Header: "Usuario",
    accessor: d => d.user,
    id: 'user',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => (value.name)
  },
];

export default class Space extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      space: {
        attributes: {
          "active-status": false,
          box: {},
          item: {},
        },
        id: 0,
        pages: null,
        loading: true,
        page: 1,
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.match.params.id !== prevState.space.id){
       return { space_id: nextProps.match.params.id};
    }
    else {
       return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.id !== this.state.space_id){
      axios_instance.get('/v1/warehouses/' + this.state.space_id).then((response) => {
        this.setState({
          space: response.data.data,
        })
      }).catch(() => {
      })
    }
  }

  componentDidMount() {
    showLoader();
    var space = this.props.location.state === undefined ? this.props.match.params.id : this.props.location.state[0]
    if (typeof(space) === "string") {
      axios_instance.get('/v1/spaces/' + space).then((response) => {
        this.setState({
          space: response.data.data,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      this.setState({
        space: space,
      })
      hideLoader();
    }
  }

  goBack = () => {
    this.props.history.push('/spaces')
  }

  edit = () => {
    this.props.history.push('/spaces/'+this.props.match.params.id+'/edit', [this.state.space])
  }

  sendToProduct = (product, type) => {
    type = type === "Box" ? "boxe" : type;
    this.props.history.push('/' + type + "s/" + product.id)
  }

  goToStorable = (storable) => {
    if (storable.storable_type === 'Box') {
      this.props.history.push(`/Boxes/${storable['storable_id']}`);
    } else {
      this.props.history.push(`/storage-items/${storable['storable_id']}`);
    }
  }

  renderQr = () => {
    var code = {
      type: "spaces",
      id: this.state.space.id,
      url: 'https://www.boxmi.mx'
    }
    const object_type = "Espacio";
    code = JSON.stringify(code);
    return (
      <div className={("pointer HpQrcode_" + this.state.space.id)} style={{display:"flex", padding:"10px", flex:"1", flexDirection: "column" }}>
        <h3>{object_type} {this.state.space.id} </h3>
        <QRCode
          value={code}
          size={128}
          level={'H'}
          onClick={() => {this.qrToPdf()}}
          />
      </div>
    )
  }

  addQRToPdf = (boxName, canvasUrl, pdf) => {
    pdf.text(boxName, 20, 20);
    pdf.text(boxName, 100, 20);
    pdf.text(boxName, 20, 130);
    pdf.text(boxName, 100, 130);
    pdf.addImage(canvasUrl, 'PNG', 20, 30);
    pdf.addImage(canvasUrl, 'PNG', 100, 30);
    pdf.addImage(canvasUrl, 'PNG', 20, 140);
    pdf.addImage(canvasUrl, 'PNG', 100, 140);
  }

  qrToPdf = () => {
    const pdf = new jsPDF();
    const canvas = document.querySelector('.HpQrcode_' + this.state.space.id + ' > canvas');
    const canvasUrl = canvas.toDataURL('image/png');
    const object_type = "Espacio"
    const boxName = `${object_type} ${this.state.space.id}`;
    this.addQRToPdf(boxName, canvasUrl, pdf);
    pdf.save(boxName);
  }

  render() {
    const { loading, pages } = this.state;
    var { attributes } = this.state.space
    let subscripion_boxes = this.props.location.state[0].attributes['subscription-boxes']
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h2>Espacio #{this.state.space.id}</h2>
                <div>
                  <button className="btn btn-primary" onClick={this.edit}>Editar</button>
                  <button className="btn btn-back ml-1" onClick={this.goBack}>Regresar</button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div>
              <span>Activo ?: </span>
              <span style={{paddingLeft: "5px"}}>{attributes["active-status"] ? "Sí" : "No"}</span>
            </div>
            <div>
                <span style={{paddingLeft: "5px"}}>
                  {this.renderQr()}
                </span>
              </div>
            <div >
              <span>Almacen asignado: </span>
              <span style={{paddingLeft: "5px"}}>{attributes["warehouse-id"] ? `Almacen ${this.state.space.attributes["warehouse-id"]}` : "No esta ligada"}</span>
            </div>
            <div>
              <CustomTable {...this.props}
                columns={itemsColumns}
                data={subscripion_boxes}
                pages={pages}
                loading={loading}
                manual
                onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
                filterable
                defaultPageSize={10}
                defaultSorted={[
                  {
                    id: "id",
                    desc: false
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if(column.Header) {
                        this.goToStorable(rowInfo.original.storable)
                      } else {
                        handleOriginal();
                      }
                    }
                  }
                }}
              />
            </div>
            {
              attributes["box"] ? (
                <div style={{display:"flex", flexDirection: "row"}} onClick={() => this.sendToProduct(attributes["box"], "Box")}>
                  <div style={{display: "flex"}}>
                    <span>Caja Asignada: </span>
                    <span style={{ paddingLeft: "5px"}} >Caja {attributes["box"].id}</span>
                  </div>
                  <div style={{display: "flex", paddingLeft:"20px"}}>
                    <span>Tamaño: </span>
                    <span style={{ paddingLeft: "5px"}}>{attributes["box"].box_type}</span>
                  </div>
                </div>
              ) : (
                attributes["item"] ?
                  <div style={{display:"flex", flexDirection: "row"}} onClick={() => this.sendToProduct(attributes["item"], "Item")}>
                    <div style={{display: "flex"}}>
                      <span>Item Asignado: </span>
                      <span style={{ paddingLeft: "5px"}} >Caja {attributes["item"].id}</span>
                    </div>
                    <div style={{display: "flex", paddingLeft:"20px"}}>
                      <span>Tamaño: </span>
                      <span style={{ paddingLeft: "5px"}}>{attributes["item"].storage_item_type}</span>
                    </div>
                  </div>
                : ""
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
