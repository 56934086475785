import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { NotificationManager } from 'react-notifications';
import { ToCurrency } from "components/utils";

export default class Quote extends React.Component {
  resend(quoteId) {
    showLoader();
    axios_instance.post(`/v1/quotes/${quoteId}/resend`).then((res) => {
      NotificationManager.info("Se ha enviado la cotización nuevamente.", "");
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  render() {
    return (
      <div className="row" key={this.props.key}>
        <div className="col-sm-6">
          <b>Cotización #{this.props.quote.id}</b><button className="btn btn-primary" onClick={() => this.resend(this.props.quote.id)} >Reenviar</button>
        </div>
        <div className="col-sm-6">
          Envíada {this.props.quote.created_at}
        </div>
        <div className="col-sm-6">
          Cotizado {this.props.quote.size} m<sup style={{top: "-0.5em", fontSize: "100%"}}>2</sup> a {ToCurrency(this.props.quote.price)}
        </div>
        <div className="col-sm-6">
          Con Adicionales {this.props.quote.additional_concept} a {ToCurrency(this.props.quote.additional_amount)}
        </div>
      </div>
    )
  }
}
