import React from "react";
import moment from 'moment'
import axios_instance from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import arrowDownK from 'img/arrow-down-k.svg';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: {},
      dateSelected: "",
      selectedHour: "",
      availableHours: [ "00:00"]
    };
  }

  componentDidMount() {
    this.getMoreDates();
  }

  getMoreDates = () => {
    showLoader();
    var lastElement, nextWeeks;
    if (Object.keys(this.state.dates).length > 0) {
      lastElement = Object.keys(this.state.dates)[Object.keys(this.state.dates).length - 1]
      nextWeeks = moment(lastElement, "YYYY-MM-DD").day(7);
    } else {
      nextWeeks = moment().format("YYYY-MM-DD")
    }
    axios_instance.get('/v1/next_available_weeks', { params:{ date: nextWeeks }})
    .then((response) => {
      var data = response.data;
      var dates = {...this.state.dates, ...data};
      var choosenDate = Object.keys(dates).filter((date) => { return dates[date].available === true})[0]
      var date = dates[choosenDate];
      var hours = Object.keys(date).filter((hour) => {
        if (date[hour].available) {
          return date[hour].label;
        }
        return null;
      })
      var initHour = hours[0];
      var hour = date[initHour].label;
      this.setState({
        dates: dates,
        availableHours: hours,
        calendarContainerClass: "calendar flex-column",
        dateSelected: date,
        selectedHour: hour
      });
      var dateSelected = choosenDate + " " + hour
      this.props.save(moment(dateSelected).format())
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  selectDate = (date) => {
    if (date.available) {
      var hours = Object.keys(date).filter((hour) => {
        if (date[hour].available) {
          return date[hour].label;
        }
        return null;
      })
      var initHour = hours[0];
      var hour = date[initHour].label;
      this.setState({
        dateSelected: date,
        availableHours: hours,
        selectedHour: hour
      });
      var dateSelected = Object.keys(this.state.dates).filter((key) => { return this.state.dates[key].label === date.label })[0]
      var newDate = dateSelected + " " + hour
      this.props.save(moment(newDate).format())
    }
  }

  selectHour = () => {
    var availableHours = this.state.availableHours.map((hour, index) => {
      if (hour !== "00:00") {
        return (
          <option key={index} value={this.state.dateSelected[hour].label}>{this.state.dateSelected[hour].label} hrs</option>
        )
      }
      return null
    })
    return availableHours
  }

  setHour = (event) => {
    const target = event.target;
    const value = target.value;
    this.setState({ selectedHour: value });
    var dateSelected = Object.keys(this.state.dates).filter((date) => { return this.state.dates[date].label === this.state.dateSelected.label })[0]
    var date = dateSelected + " " + value;
    this.props.save(moment(date).format())
  }


  render() {
    return (
      <div style={{marginBottom: '2em', paddingTop: '0'}} className={this.state.calendarContainerClass}>
        <div className="calendar-hour-selection">
          <div style={{ flexGrow: 1, marginRight: "0" }} className="form-group">
            <span style={{fontSize: "1.5em", marginRight: "1rem"}}>Horarios<br/>Disponibles </span>
            <select name="selectedHour" id="" defaultValue={this.state.selectedHour} onChange={this.setHour}>
              {this.selectHour()}
            </select>
          </div>
          <div style={{flexGrow: 1, color: "#c3baba"}}>
            <span>Las horas disponibles pueden<br/>variar de acuerdo al dia seleccionado </span>
          </div>
        </div>
        <div className="cal-week-days">
          <p>DOM</p>
          <p>LUN</p>
          <p>MAR</p>
          <p>MIÉ</p>
          <p>JUE</p>
          <p>VIE</p>
          <p>SÁB</p>
        </div>
        <div className="cal-day-items">
        {
          Object.keys(this.state.dates).map((date, index) => {
            return (
              <div key={index} className={"pointer day-item " + (this.state.dates[date].available ? "" : "day-off ") + (this.state.dateSelected.label === this.state.dates[date].label ? "day-sel" : "" )} onClick={() => this.selectDate(this.state.dates[date])}>
                <p className="day-num">{this.state.dates[date].label.split(' ')[0]}</p>
                <p className="month">{this.state.dates[date].label.split(' ')[1]}</p>
              </div>
            )
          })
        }
        </div>
        <div style={{display:"flex", justifyContent:"center"}} className="pointer">
          <img onClick={this.getMoreDates} src={arrowDownK} alt="boxPhoto" />
        </div>
      </div>
    )
  }
}
