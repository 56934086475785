// reducers.js
const subscriptionReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTION':
      return action.payload;
    default:
      return state;
  }
};

export default subscriptionReducer;