export function validations(fields) {
  var errors = {}
  for (var field in fields) {
    if (fields.hasOwnProperty(field)) {
      if(fields[field])
        fields[field].length === 0 || fields[field] === "" ? errors[field] = true : errors[field] = false;
      else
        errors[field] = true;
    }
  }
  return errors;
}
