import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class NotFound extends React.Component {
  render() {
    return(
      <div className="common-container">
        <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
        <h3>
          No hay nada en <code>{window.location.pathname}</code>
        </h3>
      </div>
    )
  }
}
