import React from "react";
import moment from 'moment';
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { LeftPad } from 'components/utils';
import orderIconB from 'img/order-ico-02.svg';
import DashboardMenu from 'templates/common/dashboard-menu';

export default class Dashboard extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      calendar: {},
      today: {
        total_orders: 0,
        active_orders: 0,
        complete_orders: 0,
      },
      total: 0
    };
    this.currentDate = moment().format("YYYY-MM-DD");
  }

  componentDidMount() {
    showLoader();
    axios_instance.get("/v1/order_calendar").then((response) => {
      const todayDate = moment().format("YYYY-MM-DD");
      var today, total = 0;

      Object.keys(response.data).map((date, index) => {
        if (todayDate === date) {
          today = response.data[date];
        }
        total += response.data[date].active_orders;
        return total;
      })
      this.setState({
        calendar: response.data,
        today: today,
        total: total
      })
    }).catch((error) => {
      if(error.response.status === 403)
        this.props.history.push('/sign_in');
    }).finally(() => {
      hideLoader();
    })
  }

  orderNavigate = (order) => {
    if(order.orders.length > 0) {
      this.props.history.push('/boxero/orders', {currentOrder: order})
    }
  }

  getOrderItemClassName = (date) => {
    return this.currentDate > date ? "order-day-content-item-body-orders grey-item" : "order-day-content-item-body-orders"
  }

  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">TOTAL</span>
              <hr />
              <div className="dashboard-content-header-body">
                <div className="dashboard-content-header-body-item">
                  <div className="dashboard-content-header-body-item-number">
                    <span>
                      {LeftPad(this.state.total, 2)}
                    </span>
                  </div>
                  <div className="dashboard-content-header-body-item-number-title">
                    <img src={orderIconB} alt="order" />
                    <span>ÓRDENES ACTIVAS</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-content-header-item">
              <div className="dashboard-content-header-item">
                <span className="dashboard-content-header-title">HOY</span>
                <hr />
                <div className="dashboard-content-header-body">
                  <div className="dashboard-content-header-body-item">
                    <div className="dashboard-content-header-body-item-number">{LeftPad(this.state.today.total_orders, 2)}</div>

                    <div className="dashboard-content-header-body-item-number-title">
                      <img src={orderIconB} alt="order" />
                      <span>ÓRDENES</span>
                    </div>
                  </div>
                  <div className="dashboard-content-header-body-item">
                    <div className="dashboard-content-header-body-item-number blue-item">{LeftPad(this.state.today.active_orders, 2)}</div>

                    <div className="dashboard-content-header-body-item-number-title">
                      <span className="grey-item">RECEPCIÓN</span>
                    </div>
                  </div>
                  <div className="dashboard-content-header-body-item">
                    <div className="dashboard-content-header-body-item-number blue-item">{LeftPad(this.state.today.complete_orders, 2)}</div>

                    <div className="dashboard-content-header-body-item-number-title">
                      <span className="grey-item">ENVIOS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="order-day-header">
              <div className="order-day-header-item">DOM</div>
              <div className="order-day-header-item">LUN</div>
              <div className="order-day-header-item">MAR</div>
              <div className="order-day-header-item">MIE</div>
              <div className="order-day-header-item">JUE</div>
              <div className="order-day-header-item">VIE</div>
              <div className="order-day-header-item">SAB</div>
            </div>
            <div className="order-day-content">
              {
                Object.keys(this.state.calendar).map((date, index) => {
                  const currentObject = this.state.calendar[date];
                  console.log(currentObject);
                  return (
                    <div key={index} className="order-day-content-item" onClick={() => this.orderNavigate(currentObject)}>
                      <div className="order-day-content-item-header">
                        <span className="date-tag">{currentObject["day_label"]}<span className="red-item">{currentObject["month_label"]}</span></span>
                        {
                          this.currentDate === date ?
                            <div className="today-tag">HOY</div>
                            :
                            ""
                        }
                      </div>
                      <div className="order-day-content-item-body">
                        <div className={this.getOrderItemClassName(date)}>
                          <span className="big-item">{currentObject["active_orders"]}</span>
                          <span className="red-item">ÓRDENES<br /> ACTIVAS</span>
                        </div>
                        <div className={this.getOrderItemClassName(date)}>
                          <span className="big-item">{currentObject["complete_orders"]}</span>
                          <span>ÓRDENES<br /> ATENDIDAS</span>
                        </div>
                      </div>
                      {
                        currentObject['percentage'] >= 0 ?
                          (
                            <div className="order-day-content-item-footer">
                              <div style={{'width': `${currentObject['percentage']}%`}} className="order-day-content-item-progress">
                                {currentObject['percentage']}%
                              </div>
                            </div>
                          )
                          :
                          (
                            ""
                          )
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
