import React from 'react';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { findGetParameter } from 'components/utils';
import { GoToUserDashboard } from 'components/session';
import { NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { axios_instance } from 'components/axios';
import { loadHeader } from 'templates/header';

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      terms: false,
      privacy: false
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  goToTerms = (event) => {
    event.preventDefault();
    window.open('/terminos', '_blank');
  }

  goToPrivacy = (event) => {
    event.preventDefault();
    window.open('/aviso', '_blank');
  }

  register = (event) => {
    event.preventDefault();
    if(this.state.privacy && this.state.terms)
    {
      if(this.state.email && this.state.password)
      {
        let params = {
          email: this.state.email,
          password: this.state.password,
          name: this.state.name,
          last_name: this.state.lastName,
          phone: this.state.phone,
          country_code_id: "MX",
          confirm_success_url: 'http://boxmi.mx/confirm'
        };
        showLoader();
        axios_instance.post('/auth', params)
        .then(() => {
          showLoader();
          axios_instance.post('/auth/sign_in', {email: params.email, password: params.password})
          .then((res) => {
            localStorage.setItem("headers", JSON.stringify(res.headers));
            localStorage.setItem("currentUser", JSON.stringify(res.data.data));
            loadHeader();

            let redirect = findGetParameter('redirect');
            if(redirect === "quotes") {
              redirect = `quotes/${findGetParameter('id')}`;
            }
            this.props.history.push("/quoting");
          }).catch(() => {
          }).finally(() => {
            hideLoader();
          });
        }).catch(() => {
          hideLoader();
        });
      }
    } else {
      NotificationManager.warning("Favor de marcar como leídas los TyC y el Aviso de Privacidad", "");
    }
  }

  render() {
    return(
      <div className="session-container">
        <div className="flex-column">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <p className="session-title">Regístrate con tu dirección de email</p>
          <form onSubmit={this.register} className="flex-column">
            <div style={{alignItems: 'center'}} className="flex-column">
              <div className="form-group-sign-in">
                <input type="name" className="form-control bigger-input" id="name" name="name" placeholder="Nombre" onChange={this.handleInputChange} />
              </div>
              <div className="form-group-sign-in">
                <input type="name" className="form-control bigger-input" id="lastName" name="lastName" placeholder="Apellidos" onChange={this.handleInputChange} />
              </div>
              <div className="form-group-sign-in">
                <input type="email" className="form-control bigger-input" id="email" name="email" placeholder="Correo Electrónico" onChange={this.handleInputChange} />
              </div>
              <div className="form-group-sign-in">
                <input type="phone" className="form-control bigger-input" id="phone" name="phone" placeholder="Teléfono" onChange={this.handleInputChange} />
              </div>
              <div className="form-group-sign-in">
                <input type="password" className="form-control bigger-input" id="password" name="password" placeholder="Contraseña" onChange={this.handleInputChange} />
              </div>
              <div className="form-group-sign-in">
                <input type="password" className="form-control bigger-input" id="confirmPassword" placeholder="Confirmar Contraseña" name="confirmPassword" onChange={this.handleInputChange} />
              </div>
            </div>
            <div className="obt-legal">
              <label className="pointer">
                <input style={{ marginRight: '0.5em' }} name="terms" type="checkbox" onChange={this.handleInputChange} />
                He leído y acepto las <br /><span onClick={this.goToTerms}>POLÍTICAS Y TÉRMINOS DE USO</span>
            </label>

            <label className="pointer">
              <input style={{ marginRight: '0.5em' }} name="privacy" type="checkbox" onChange={this.handleInputChange} />
              He leído y acepto el <br /><span onClick={this.goToPrivacy}>AVISO DE PRIVACIDAD</span>
            </label>
            </div>
            <button type="submit"className="sign-in-btn"><strong>Regístrate</strong></button>
          </form>
        </div>
      </div>
    )
  }
}
