import React from "react";
import arrowDown from 'img/arrow-down.svg';

export default class UserStorable extends React.Component {

  disableIfOngoingOrder = () => {
    let ongoingOrder = JSON.parse(sessionStorage.getItem('ongoingOrder'))
    let selectable = true
    if (ongoingOrder) {
      ongoingOrder.forEach(order => {
        if (order.id === this.props.id && this.props.selectable) selectable = false
      })
    }

    if (!selectable ) {
      return 'notSelectable'
    } else {
      return 'selectable'
    }
  }

  handleRedirect = () => {
    if(this.props.isAdmin) {
      this.props.goToStorable()
    } else {
      this.props.goToInventory()
    }
  }


  render() {
    return (
      <div className={ this.disableIfOngoingOrder() }>
        <div className="user-dashboard-content-box">
          <div className={this.props.selectable ? "user-dashboard-content-box-title pointer" : "user-dashboard-content-box-title"} onClick={() => this.props.toggleStorableSelected(this.props.selectable, this.props.storableIndex, this.props.storablesName)}>
            <img height="30px" className="user-dashboard-content-box-placeholder" src={this.props.storableIcon} alt="storable" />
            <div style={{minHeight: '48px'}}>
              <span>{this.props.title.toUpperCase()}</span>
            </div>
            <div className={this.props.selectable ? "storable-selection" : "hide-node"}>
              <input type="checkbox" className="custom-checkbox" checked={this.props.selected} readOnly />
            </div>
          </div>
          {
            this.props.storable && !this.props.storable.attributes.storable['storable-id'] ?
            <div className="user-dashboard-content-box-footer" onClick={() => this.handleRedirect()}>
              <div className="user-dashboard-content-box-inventory">
                <span style={{fontSize: '0.8em', marginRight: '1rem'}}>NO TIENE {this.props.storable.storableType === 'box'?'CAJA':'ESPACIO'} DESIGNAD{this.props.storable.storableType === 'box'?'A':'O'}</span>
              </div>
            </div>
            :
            <div className="user-dashboard-content-box-footer" onClick={() => this.handleRedirect()}>
              <div className="user-dashboard-content-box-inventory">
                <span style={{fontSize: '0.8em'}}>VER INVENTARIO</span>
                <div className="dashboard-menu-item-arrow">
                  <img src={arrowDown} alt="arrow-down" />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
