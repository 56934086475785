import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import DashboardMenu from "templates/common/dashboard-menu";
import { NotificationManager } from "react-notifications";
import StringResources from "components/string-resources";
import React from "react";
import CustomerStorable from "components/customer-storable";
import DashboardUserMenu from "templates/common/dashboard-user-menu";
import { BaseUrl } from "components/axios";
import truckImage from "img/agregatusproductos-asset.png";

export default class CustomerStorables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storables: [],
      storablesToDelete: [],
      showModal: false,
      attachedFile: null,
      currentUser: {},
    };
    this.dashboardUserMenuElement = React.createRef();
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ currentUser: currentUser });
    const { userId } = this.props.match.params;
    if (userId) {
      showLoader();
      axios_instance
        .get("/v2/customer_storables", {
          params: {
            by_customer: userId,
          },
        })
        .then((response) => {
          const storables = response.data.data.map((storable) => {
            return {
              object_name: storable.attributes["object-name"] || "",
              width: storable.attributes.width,
              height: storable.attributes.height,
              long: storable.attributes.long,
              storable_id: storable.id,
              attachments: storable.attributes.attachments,
            };
          });
          if (storables.length) {
            this.setState({ storables });
          } else {
            // this.handleAddObject();
          }
        })
        .catch((e) => {})
        .finally(() => {
          hideLoader();
        });
    }
  }

  handleChange = (index, event) => {
    const { name, value, files } = event.target;
    this.setState((prevState) => {
      const newStorables = [...prevState.storables];
      const updatedStorable = { ...newStorables[index] };
      if (name === "file") {
        console.log(files);
        updatedStorable["attachments"] = files;
        updatedStorable.isFileAttached = true;
      } else {
        updatedStorable[name] = value;
      }
      newStorables[index] = updatedStorable;
      return { storables: newStorables };
    });
  };

  updateStorable = (newStorable, index) => {
    const { storables } = this.state;
    const updatedStorables = [...storables];
    updatedStorables[index] = newStorable;
    this.setState({ storables: updatedStorables });
  };

  handleAddObject = () => {
    this.setState((prevState) => ({
      storables: [
        ...prevState.storables,
        {
          object_name: "",
          width: 0,
          height: 0,
          long: 0,
          attachments: [],
        },
      ],
    }));
  };

  handleRemoveObject = (index) => {
    const { storables } = this.state;
    const newStorables = [...storables];
    const removed = newStorables.splice(index, 1)[0];
    if (removed.storable_id) {
      this.setState((prevState) => ({
        storablesToDelete: [...prevState.storablesToDelete, removed],
      }));
    }
    this.setState({ storables: newStorables });
  };

  handleShowModal = (index) => {
    const storable = this.state.storables[index];
    if (typeof storable.attachments[0] === "string") {
      const file = `${BaseUrl}${storable.attachments[0]}`;
      this.setState({ attachedFile: file, showModal: true });
    } else if (typeof storable.attachments[0] === "object") {
      const file = URL.createObjectURL(storable.attachments[0]);
      this.setState({ attachedFile: file, showModal: true });
    }
  };
  handleCloseModal = () => {
    this.setState({ attachedFile: null, showModal: false });
  };

  createCustomerStorable = (storable) => {
    return new Promise((resolve, reject) => {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const formData = new FormData();
      formData.append("customer_storables[object_name]", storable.object_name);
      formData.append("customer_storables[width]", storable.width);
      formData.append("customer_storables[height]", storable.height);
      formData.append("customer_storables[long]", storable.long);
      // formData.append("customer_storables[attachments]", storable.attachments[0]);
      const attachments = [...storable.attachments].map((file) => file);
      attachments.forEach((file, index) => {
        formData.append(`customer_storables[attachments][]`, file);
      });
      formData.append("customer_storables[customer_id]", currentUser.id);
      // axios_instance
      //   .post("/v2/customer_storables", { customer_storables: storable })
      axios_instance
        .post("/v2/customer_storables", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          hideLoader();
        });
    });
  };
  editCustomerStorable = (storable) => {
    return new Promise((resolve, reject) => {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const formData = new FormData();
      formData.append("customer_storables[object_name]", storable.object_name);
      formData.append("customer_storables[width]", storable.width);
      formData.append("customer_storables[height]", storable.height);
      formData.append("customer_storables[long]", storable.long);
      // formData.append("customer_storables[attachments]", storable.attachments[0]);
      const attachments = [...storable.attachments].map((file) => file);
      attachments.forEach((file, index) => {
        formData.append(`customer_storables[attachments][]`, file);
      });
      formData.append("customer_storables[customer_id]", currentUser.id);
      // axios_instance
      //   .post("/v2/customer_storables", { customer_storables: storable })
      axios_instance
        .put(`/v2/customer_storables/${storable.storable_id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          hideLoader();
        });
    });
  };
  deleteCustomerStorable = (storable) => {
    return new Promise((resolve, reject) => {
      axios_instance
        .delete(`/v2/customer_storables/${storable.storable_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          hideLoader();
        });
    });
  };

  // submit = () => {
  //   console.log(this.state.storablesToDelete);
  // };
  submit = () => {
    showLoader();
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log(this.state.storables);
    const storablesToBeCreated = this.state.storables.map((s) => {
      const storable = { ...s, customer_id: currentUser.id };
      const promise = s.storable_id
        ? this.editCustomerStorable(storable)
        : this.createCustomerStorable(storable);
      return promise;
    });
    const deletePromises = this.state.storablesToDelete.map((storable) => {
      return this.deleteCustomerStorable(storable);
    });
    Promise.all([...storablesToBeCreated, ...deletePromises])
      .then((response) => {
        NotificationManager.success(
          StringResources.common.success,
          "Informacion guardada",
          3000
        );
        this.props.history.push("/users");
        // console.log(storablesToBeCreated)
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  };
  delete = () => {
    showLoader();
    axios_instance
      .delete("/v2/plans/" + this.props.match.params.id)
      .then((response) => {
        this.props.history.push("/admin/plans");
      })
      .catch(() => {})
      .finally(() => {
        hideLoader();
      });
  };

  render() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return (
      <div className="dashboard-container">
        {currentUser.attributes.role === "client" ? (
          <DashboardUserMenu ref={this.dashboardUserMenuElement} />
        ) : (
          <DashboardMenu />
        )}
        {this.state.storables.length > 0 ? (
          <div className="dashboard-content">
            <div className="dashboard-content-header">
              <div className="dashboard-content-header-item">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="main-title">
                    Recibimos tu orden, ayudanos diciendo que es lo que piensas
                    guardar
                  </span>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleAddObject()}
                  >
                    Agregar objeto
                  </button>
                </div>
                <hr />
              </div>
            </div>
            <div className="dashboard-content-body">
              <div className="">
                {this.state.storables.map((storable, index) => (
                  <CustomerStorable
                    key={index}
                    storable={storable}
                    handleRemoveStorable={() => this.handleRemoveObject(index)} // Pasar la función como prop
                    handleShowModal={() => this.handleShowModal(index)} // Pasar la función como prop
                    updateStorable={(newStorable) =>
                      this.updateStorable(newStorable, index)
                    }
                  ></CustomerStorable>
                ))}
              </div>
              <div className="my-2">
                <button
                  className="btn btn-primary ml-2 ml-md-0"
                  type="submit"
                  onClick={this.submit}
                >
                  {" "}
                  Confirmar
                </button>
              </div>
              {this.state.attachedFile && (
                <div
                  className={`modal ${
                    this.state.showModal ? "d-block" : "d-none"
                  }`}
                  tabIndex="-1"
                  role="dialog"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">File Preview</h5>
                        <button
                          type="button"
                          className="close"
                          onClick={this.handleCloseModal}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          className="w-100"
                          src={this.state.attachedFile}
                          alt="Preview"
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.handleCloseModal}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="dashboard-content">
            <div className="dashboard-content-body py-5 px-3">
              <div className=" d-flex flex-column justify-content-center align-items-center">
                <h1 className="mb-4" style={{ color: "#6142f7" }}>
                  ¡Tu cita fue agendada con éxito!
                </h1>
                <img className="mb-4 img-fluid" src={truckImage}></img>
                <span className="h4 mb-4 text-center">
                  Ayudanos diciendo que es lo que piensas guardar
                </span>
                <button
                  className="btn btn-primary"
                  onClick={() => this.handleAddObject()}
                >
                  Agregar objeto
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
