import React from "react";
import GoogleMapReact from "google-map-react";
import "./marker.css";

export default function SimpleMap(props) {
  const { center, zoom, isMarkerShown } = props;
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const Marker = () => (
    <>
      <a
        href={`https://www.google.com/maps?q=${center.lat},${center.lng}`}
        target="_blank"
        className="pin cursor-pointer"
      ></a>
      {/* <div className="pulse"></div> */}
    </>
  );

  const goToGoogleMaps = (e) => {
    console.log(e);
  };
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "300px", maxWidth: "540px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        zoom={zoom}
      >
        {isMarkerShown && <Marker lat={center.lat} lng={center.lng} />}
      </GoogleMapReact>
    </div>
  );
}
