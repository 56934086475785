import React from "react";
import { axios_instance } from 'components/axios';
import { NotificationManager} from 'react-notifications';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from 'templates/common/dashboard-menu';
import GoBack from 'components/go-back';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import QrModal from 'templates/modals/qr-modal';
import truckIcon from 'img/truck-icon.svg';
export default class OrderShow extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      qrModal:{
        visibility: false,
        type: "assign",
        currentSubscriptionBox: {
          id: -1,
          index: -1
        }
      },
      order: {
        id: 0,
        attributes: {
          products: [],
          'additional-items': [],
          'subscription-boxes': {
            data: []
          },
          user: {
            name: ''
          },
          'shipping-address': {
            'postal-code': '',
            'suburb': '',
            'ext-number': '',
            'int-number': '',
            'street': ''
          }
        }
      }
    };
  }

  componentDidMount() {
    showLoader();
    var id = this.props.match.params.id;
    axios_instance.get(`/v1/orders/${id}`).then((response) => {
      const order = response.data.data;
      const shippingAddress = order.attributes['shipping-address'];
      const address = `${shippingAddress['street']} ${shippingAddress['ext-number']} ${shippingAddress['suburb']} ${shippingAddress['postal-code']}`;

      this.setState({
        order: order,
        id: order.id,
        address: encodeURI(address)
      });
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  nextState = (currentState) => {
    let hasError = false;
    this.state.order["attributes"]["subscription-boxes"]["data"].forEach((sb) => {
      if(sb["attributes"] && sb["attributes"]["storable"]) {
        if(sb["attributes"]["storable"]["storable-type"] === "Box")
        {
          if(sb["attributes"]["storable"]["storable-id"])
          {
            // Boxes that have storable-id
            // OK
          } else {
            // Boxes that do not have storable-id
            hasError = true;
          }
        }
        // StorageItems are always OK
        // OK
      } else {
        hasError = true;
      }
    })
    if(hasError) {
      NotificationManager.error("Favor de asignar todas las cajas primero. Dando tap en el icono del QR.");
      return false;
    }
    if(currentState === "assign" || currentState === "assign_quote" || currentState === "pickup")
    {
      currentState = "pickup";
      this.props.history.push(`/orders/${this.props.match.params.id}/${currentState}`, [this.state.order]);
    }

    if(currentState === "return") {
      showLoader();
      axios_instance.post(`/v1/orders/${this.props.match.params.id}/return_order`).then((res) => {
        NotificationManager.success("Se regresó con éxito");
        this.props.history.push("/dashboard");
      }).finally(() => {
        hideLoader();
      });
    }

    if(currentState === "cancel") {
      showLoader();
      axios_instance.post(`/v1/orders/${this.props.match.params.id}/cancel_storable`).then((res) => {
        NotificationManager.success("Se canceló con éxito");
        this.props.history.push("/dashboard");
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      });
    }
  }

  openQrModal = (productId, productIndex) => {
    let qrModal = this.state.qrModal;
    qrModal.visibility = true;
    qrModal.currentSubscriptionBox = {
      id: productId,
      index: productIndex
    };

    this.setState({
      qrModal: qrModal
    });
  }

  closeQrModal = () => {
    this.setState((prevState) => ({qrModal: {...prevState.qrModal, visibility: false }}));
  }

  assignBox = (storable, selectedIndex) => {
    let currentOrder = this.state.order;
    let qrModal = this.state.qrModal;
    qrModal.currentSubscriptionBox = {
      id: -1,
      index: -1
    };
    qrModal.visibility = false;
    currentOrder.attributes['subscription-boxes'].data = currentOrder.attributes['subscription-boxes'].data.map((subscription_box, index) => {
      if(index === selectedIndex)
      {
        subscription_box.attributes['storable-status'] = 'pending_pickup';
        subscription_box.attributes["storable"] = storable;
      }
      return subscription_box;
    });
    this.setState({
      qrModal: qrModal,
      order: currentOrder
    });
  }

  mapSelector = () => {
    if(
      ((navigator.platform.indexOf("iPhone") !== -1)
      || (navigator.platform.indexOf("iPod") !== -1)
      || (navigator.platform.indexOf("iPad") !== -1))
    )
    {
      window.open("https://maps.apple.com/?q=" + this.state.address);
    }
    else
    {
      window.open("https://maps.google.com/?q=" + this.state.address);
    }
  }

  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <GoBack />
          <div className="dashboard-content-header">
            <div className="" style={{display: 'flex', justifyContent: 'space-between', marginTop: '1rem'}}>
              <div>
                <h2 style={{marginBottom: 0}}>Orden #{this.state.order.id}</h2>
                <span><b>Fecha: </b>{this.state.order.attributes['shipping-date']}</span>
              </div>
              <div style={{display: 'flex'}}>
                <div style={{textAlign: 'right'}}>
                  <div className="box-info-label">Estatus de la orden</div>
                  <h3>
                    {this.state.order.attributes['order-status']}
                  </h3>
                </div>
                <div style={{marginLeft: '1rem'}} className="btn-box padding-2 dashboard-action pointer order-action-button hideMobile" onClick={() => {this.nextState(this.state.order.attributes['order-type'])}}>
                  <img style={{marginRight: '1vw'}} src={truckIcon} alt="cancel" />
                  <span className="dashboard-action-title">
                    {this.state.order.attributes['order-type-t']}
                  </span>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="dashboard-content-body">
            <div className="order-grid">
              <div className="order-client order-client-card">
                <h2>Datos del Cliente</h2>
                <span style={{marginTop: '1rem'}}>
                  <div className="box-info-label">Nombre: </div>
                  {this.state.order.attributes['client-name']}
                </span>
                <span onClick={this.mapSelector} style={{marginTop: '1rem'}}>
                  <div className="box-info-label">Dirección (TAP para ver en mapa): </div>
                  {this.state.order.attributes['shipping-address']['postal-code']},
                  {" " + this.state.order.attributes['shipping-address'].suburb + " "}
                  {this.state.order.attributes['shipping-address'].street + " "}
                  {this.state.order.attributes['shipping-address']['ext-number'] + " "}
                  {this.state.order.attributes['shipping-address']['int-number']}
                </span>
                {
                  this.state.order.attributes['shipping-address']['references'] ?
                  (
                    <span style={{marginTop: '1rem'}}>
                      <div class="box-info-label">Referencias: </div>
                      {this.state.order.attributes['shipping-address']['references']}
                    </span>
                  )
                  :
                  ""
                }
                <span style={{marginTop: '1rem'}}>
                  <div className="box-info-label">Teléfono: </div>{this.state.order.attributes.user.phone}
                </span>
                <span style={{marginTop: '1rem'}}>
                  <div className="box-info-label">Email: </div>{this.state.order.attributes.user.email}
                </span>
              </div>
              <div className="">
                <div className="order-aditionals order-client-card">
                  <h2>A {this.state.order.attributes['order-type-t']}</h2>
                  <ul style={{paddingLeft: 0}}>
                    {
                      this.state.order.attributes['subscription-boxes'].data.map((product, key) => {
                        return (
                            <li key={key} className="object-in-list">
                              {product.attributes.title}({product.attributes['default-title']})
                              {
                                product.attributes['storable-status'] === 'pending_assignment' ?
                                (
                                  <span onClick={() => this.openQrModal(product.id, key)}><FontAwesomeIcon icon={faQrcode} size="2x" style={{marginLeft: '1rem', cursor: 'pointer'}} /></span>
                                )
                                :
                                ""
                              }
                            </li>
                        )
                      })
                    }
                  </ul>

                </div>
                {
                  this.state.order.attributes['additional-items'].length > 0 ?
                  (
                    <div className="order-aditionals order-client-card mt-4">
                      <h2>Adicionales</h2>
                      <ul>
                        {
                          this.state.order.attributes['additional-items'].map((additional, key) => {
                            if(!additional.name.startsWith("Por agregar"))
                              return (
                                <li key={key}>{additional.name}</li>
                              )
                            return ""
                          })
                        }
                      </ul>
                    </div>
                  )
                  :
                  ""
                }

              </div>
            </div>
            <div className="btn-box padding-2 dashboard-action pointer order-action-button showMobile my-4" onClick={() => {this.nextState(this.state.order.attributes['order-type'])}}>
              <img style={{marginRight: '1vw'}} src={truckIcon} alt="cancel" />
              <span className="dashboard-action-title">
                {this.state.order.attributes['order-type-t']}
              </span>
            </div>
          </div>
        </div>
        <QrModal
          display={this.state.qrModal}
          closeModal={this.closeQrModal}
          assignBox={this.assignBox}
          label={this.state.currentLabel}/>
      </div>
    )
  }
}
