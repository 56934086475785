import React from "react";
import Modal from 'react-modal';
import Camera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Resizer from 'react-image-file-resizer';
import navArrowR from "img/nav-desktop-arrow-r.svg";
import navArrowL from "img/nav-desktop-arrow-l.svg";
import remove from "img/remove-button.svg";

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay : {
    backgroundColor       : 'rgba(0, 0, 0, 0.75)',
    zIndex                : '999'
  }
};

export default class CameraModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      currentProduct: "",
      id: 0
    }
  }

  setRef = webcam => {
    this.webcam = webcam;
  };
  onTakePhoto (dataUri) {
    var blob = this.dataURItoBlob(dataUri)
    // var blobUrl = URL.createObjectURL(blob);
    Resizer.imageFileResizer(
      blob,
      300,
      300,
      'JPEG',
      100,
      0,
      uri => {
          var id = this.state.id + 1
          this.setState({id: id})
          var image = "image_" + id + ".jpg"
          const file = new File([uri], image)
          if (this.props.display.currentProduct) {
            this.props.takePicture(file, this.props.display.currentProduct)
          } else {
            this.props.takePicture(file);
          }
      },
      'blob'
    );
  }

  dataURItoBlob = (dataURI, callback) => {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      // write the ArrayBuffer to a blob, and you're done
      try {
        return new Blob([ab], {type: mimeString});
      } catch (e) {
        // The BlobBuilder API has been deprecated in favour of Blob, but older
        // browsers don't know about the Blob constructor
        // IE10 also supports BlobBuilder, but since the `Blob` constructor
        //  also works, there's no need to add `MSBlobBuilder`.
        var BlobBuilder = window.WebKitBlobBuilder || window.MozBlobBuilder;
        var bb = new BlobBuilder();
        bb.append(ab);
        return bb.getBlob(mimeString);
      }
  }

  render() {
    return(
      <Modal
        isOpen={this.props.display.visibility}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.props.closeModal}
        className="camera-modal"
        style={customStyles}
        contentLabel="Example Modal">
        <div className="camera-modal-close" onClick={this.props.closeModal}>
          <img src={remove} alt="remove"></img>
        </div>
        <div style={{display: "flex", color: "White", justifyContent: "center", flexDirection:"row"}}>
          {
            this.props.lastProduct ?
              <div style={{display:"flex", paddingRight:"10px"}}>
                <img src={navArrowL} onClick={() => { this.props.lastProduct(this.props.display.currentProduct, "cameraModal")}} alt="arrowL"/>
              </div> : ""
          }
          <h2 style={{textAlign: 'center'}}>{this.props.label}</h2>
          {
            this.props.nextProduct ?
              <div style={{display:"flex", paddingLeft:"10px"}}>
                <img src={navArrowR} onClick={() => { this.props.nextProduct(this.props.display.currentProduct, "cameraModal") }} alt="arrowR"/>
              </div> : ""
          }
        </div>
        <Camera
          onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
          imageType = {IMAGE_TYPES.JPG}
          idealFacingMode = { FACING_MODES.ENVIRONMENT }
          isImageMirror = { false }
          style = {{height: '-webkit-fill-available'}}
        />

      </Modal>
    )
  }
}
