import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import DashboardMenu from "templates/common/dashboard-menu";

export default class StorageItemEdit extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      box: {
        attributes: {}
      },
      quantity: 0,
      isNew: true
    };
  }

  componentDidMount() {
    const box = this.props.location.state ? this.props.location.state[0] : "new";
    if (box === "new") {
      showLoader();
      axios_instance.get('/v1/spaces')
      .then((response) => {
        this.setState({
          isNew: true,
          spaces: response.data.data,
        })
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      this.setState({
        box: box,
        isNew: false,
        space_id: box.attributes.space_id,
        id: box.id,
      })
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === "active_status") {
      var val = value === true ? "active" : "inactive";
      this.setState({[name]: val})
    } else {
      this.setState({[name]: value});
    }
  }

  submit = () => {
    showLoader();
    var params = {
      quantity: this.state.quantity,
      storage_item: {
        id: this.state.box.id,
        storage_item_type: 0
      }
    }
    if (this.state.isNew) {
      axios_instance.post('/v1/storage_items', params).then((response) => {
        this.props.history.push('/qr_generator', [response.data.data])
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else {
      axios_instance.put('/v1/storage_items/' + this.props.match.params.id, params).then((response) => {
        this.props.history.push('/storage-items')
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    }
  }

  render() {
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <span className="dashboard-content-header-title">Crear objetos</span>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="" style={{display: "flex", flexDirection:"row"}}>
              <span>Activo? </span>&nbsp;
              <input type="checkbox" name="active_status" checked onChange={this.handleChange} value={this.state.active_status}/>
            </div>
            {
              this.state.isNew ?
              <div className="form-group" style={{flexDirection:"column"}}>
                <span>Cantidad:</span>
                <input type="number" value={this.state.quantity} name="quantity" onChange={this.handleChange} />
              </div> : ""
            }
            <div>
              <button className="btn btn-primary" type="submit" onClick={this.submit}> Confirmar</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
