import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import StringResources from 'components/string-resources';
import { validations } from 'components/validations';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default class RecoverPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      errors: []
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value}, this.validateShippingAddress)
  }

  recoverPassword = (values) => {
    showLoader();
    return axios_instance.post('/auth/password',{
      email: values.email,
      redirect_url: `${window.location.protocol}//${window.location.host}/reset_password`
    }).then((response) => {
      NotificationManager.success(response.data.message, StringResources.common.success, 3000);
    }).catch(() => {
      NotificationManager.warning(`No se encontró una cuenta con el correo ${values.email}`, "No encontrado", 3000);
    }).finally(() => {
      hideLoader();
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const fields = {
      email: this.state.email
    };

    var errors = validations(fields)
    this.setState({errors: errors})
    errors = Object.keys(errors).some(x => errors[x])
    if (errors) {
      NotificationManager.error(StringResources.common.missing_fields, "", 3000);
    } else {
      this.recoverPassword(fields);
    }
  }

  render() {
    return(
      <div className="session-container">
        <div className="flex-column">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <p className="session-title">Recupera tu Contraseña</p>
          <strong style={{paddingLeft: '2.5em', paddingRight: '2.5em', paddingBottom: '1em'}}>{StringResources.recover_password.description}</strong>
            <form onSubmit={this.handleSubmit} className="flex-column" >
              <div style={{alignItems: 'center'}} className="flex-column">
                <div className="form-group">
                  <input
                    type="text"
                    className={(this.state.errors.email ? "form-control bigger-input error" : "form-control bigger-input")}
                    name="email"
                    placeholder={StringResources.register.email}
                    onChange={this.handleChange}/>
                </div>
                <button type="submit" className="sign-in-btn"><strong>Recuperar Contraseña</strong></button>
              </div>
            </form>
        </div>
      </div>
    )
  }
}
