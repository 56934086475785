import React from "react";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { axios_instance } from 'components/axios';
import CustomTable from "components/table";
import DashboardMenu from 'templates/common/dashboard-menu';

const warehouseColumns = [
  {
    Header: "id",
    accesor: d => d.id,
    id: "id",
    show: false
  },
  {
    Header: "Nombre",
    accessor: d => d.id,
    id: 'name',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => ("Espacio " + value)
  },
  {
    Header: "Bodega",
    accessor: d => d.attributes["warehouse-id"],
    id: 'warehouse_id',
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => ("Bodega " + value)
  },
  {
    Header: "Activo?",
    accessor: d => d.attributes['active-status'],
    id: "active_status",
    style: {
      cursor: "pointer"
    },
    Cell: ({ value }) => (value === true ? "Sí" : "No"),
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "active") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false;
    },
    Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Todos</option>
        <option value="active">Activo</option>
        <option value="inactive">Inactivo</option>
      </select>
  }
];

export default class Spaces extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: [],
      pages: null,
      loading: true,
      page: 1,
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize
    }

    if(state.sorted.length) {
      state.sorted.forEach(function(sort) {
        params[sort.id] = sort.desc ? "desc" : "asc"
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function(filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance.get("/v1/spaces", {params})
    .then((res) => {
      if(res.data.data) {
        this.setState({
          data: res.data.data,
          loading:false,
          pages: res.data.meta.pagination["total-pages"],
        });
      }
    }).catch(() => {
    });
  }

  addWarehouse = () => {
    this.props.history.push('/Spaces/new')
  }

  render() {
    const { data, loading, pages } = this.state;
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h2>Espacios </h2>
                <div>
                  <button type="button" className="btn btn-primary" onClick={this.addWarehouse}>
                    + Agregar Espacio
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="">
            <CustomTable {...this.props}
              columns={warehouseColumns}
              data={data}
              pages={pages}
              loading={loading}
              manual
              onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
              filterable
              defaultPageSize={10}
              defaultSorted={[
                {
                  id: "id",
                  desc: false
                }
              ]}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if(column.Header) {
                      this.props.history.push(`/Spaces/${rowInfo.original.id}`, [rowInfo.original])
                    } else {
                      handleOriginal();
                    }
                  }
                }
              }}
            />
            <br />
          </div>
        </div>
      </div>
    )
  }
}
