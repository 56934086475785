import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import { NotificationManager} from 'react-notifications';
import Modal from 'react-modal';
import QrReader from 'react-qr-reader'
import navArrowR from "img/nav-desktop-arrow-r.svg";
import navArrowL from "img/nav-desktop-arrow-l.svg";
import remove from "img/remove-button.svg";
import soundfile from 'assets/Qr-Scanner-sound.mp3'

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
  overlay : {
    backgroundColor       : 'rgba(0, 0, 0, 0.75)',
    zIndex                : '999'
  }
};

const storableTypes = ["boxes", "storage_items", "storage-items"];

export default class QrModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      currentProduct: {},
      boxId: "",
      boxes: "",
      spaces: "",
      storage_items: "",
      warehouses: "",
      storable: "",
      currentLabel: "",
      savingData: false,
    }

    this.audio = new Audio(soundfile);
  }

  play = () => {
    this.audio.play();
  }

  componentDidMount() {
    this.setState({currentLabel:"Escanea el codigo Qr"})
  }


  handleScan = (data) => {
    if (data) {
      this.setState({savingData:true})
      // try to convert the scanned data to JSON if fails return error
      this.play();
      try {
        var newData = JSON.parse(data);
      } catch (e) {
        NotificationManager.error("Favor de usar un codigo QR valido", "", 3000);
      } finally {
        this.setState({savingData:false})
      }

      if (newData) {
        // Store in warehouse
        if (this.props.display.type === "store") {
          if (["spaces", "boxes", "storage_items", "storage-items"].includes(newData["type"]) ) {
            var type = storableTypes.includes(newData["type"]) ?  "storable" : newData["type"]
            this.setState({
              [type]: newData,
              savingData: false
            }, this.storeBox(newData));
          } else {
            NotificationManager.error("Favor de seleccionar una Caja o Espacio", "", 3000);
            this.setState({savingData:false})
          }

        }
        // pick up pending boxes and items
        else if (this.props.display.type === "pickup_pending"){
          if (storableTypes.includes(newData["type"])) {
            if (newData !== this.state.boxId || this.props.display.currentProduct.boxId) {
              this.pickupBoxes(newData)
            } else {
              this.setState({savingData:false})
              NotificationManager.error("Esta caja ya ha sido asignada", "", 3000);
            }
          } else {
            this.setState({savingData:false})
            NotificationManager.error("Favor de usar un codigo QR de caja u objeto", "", 3000);
          }
        }
        // SEARCH QR CODE
        else if (this.props.display.type === "search") {
          if (["boxes", "storage-items", "spaces", "warehouses"].includes(newData["type"])) {
            this.setState({savingData:false}, this.closeModal)

            var path = "/" + newData["type"] + "/" + newData["id"];
            this.props.search(path)
          } else {
            this.setState({savingData:false})
            NotificationManager.error("Favor de usar un codigo QR valido", "", 3000);
          }
        }
        // return to the user
        else if (this.props.display.type === "return") {
          if (storableTypes.includes(newData["type"]) ) {
            this.returnBox(newData);
          } else {
            NotificationManager.error("Favor de seleccionar una Caja o un Item", "", 3000);
            this.setState({savingData:false})
          }
        }
        // assign box to the user
        else if ( this.props.display.type === "assign") {
          if (newData["type"] === "boxes") {
            this.setState({
              storable: newData,
              savingData: false
            }, this.assignBoxes(newData));
          } else {
            NotificationManager.error("Favor de seleccionar una Caja", "", 3000);
            this.setState({savingData:false})
          }
        }
        // cancel subscription box
        else if (this.props.display.type === "cancel") {
          if (storableTypes.includes(newData["type"])) {
            if (newData !== this.state.boxId || this.props.display.currentProduct.boxId) {
              this.cancelBox(newData);
            } else {
              this.setState({savingData: false});
              NotificationManager("Esta caja ya ha sido regresada", "", 3000);
            }
          } else {
            this.setState({savingData:false});
            NotificationManager.error("favor de usar un codigo QR de caja o item", "", 3000);
          }
        }
        // ends  else if
      }
    }
  }

  handleError = err => {
    console.error(err)
  }
  // change to pickup_boxes
  pickupBoxes = (data) => {
    this.setState({
      boxId: data,
      savingData: false
    }, this.closeModal);
    this.props.pickupBoxes(this.props.display.currentProduct, data);
  }

  closeModal = () => {
    var id = this.props.display.currentProduct ? this.props.display.currentProduct.id + 1 : 0;
    var length = this.props.display.products ? this.props.display.products.length : 2;
    if (id > length) {
      setTimeout(this.closeWebcam, 1000)
    } else if (this.props.display.type !== "pickup_pending"){
      setTimeout(this.closeWebcam, 1000)
    }
  }

  closeWebcam = () => {
    this.setState({boxes:"", storage_items:"", warehouses: "", spaces:"", storable:""})
    this.props.closeModal();
  }

  storeBox = (data) => {
    if (data["type"] === "spaces") {
      NotificationManager.success("Alamacen seleccionado", "", 3000);
      this.setState({currentLabel: "Escanea el codigo Qr de la caja"})
    } else {
      if (this.state.spaces === "") {
        NotificationManager.success("Caja seleccionada", "", 3000);
      } else {
        NotificationManager.success("Caja Alamacenada", "", 3000);
      }
    }

    if (this.state.spaces && storableTypes.includes(data["type"])) {
      showLoader();
      this.closeModal();

      axios_instance.post('/v1/spaces/' + this.state.spaces.id + '/store', { storable_id: data["id"], storable_type: data["type"]}).then((response) => {
        this.props.storeBox(response.data.storable);
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    } else if (this.state.storable && data["type"] === "spaces") {
      showLoader();
      this.closeModal();
      var type = this.state.storable.type === "boxes" ? "Box" : "StorageItem"
      axios_instance.post('/v1/spaces/ '+ data["id"] + '/store', { storable_id: this.state.storable.id, storable_type: type}).then((response) => {
        this.props.storeBox(response.data.storable);
      }).catch(() => {
      }).finally(() => {
        hideLoader();
      })
    }
  }

  returnBox = (data) => {
    showLoader();
    this.closeModal();
    const storableType = data.type === "boxes" ? "Box" : "StorageItem";
    const returnParams = {
      order: {
        storable_id: data["id"],
        storable_type: storableType
      }
    };
    axios_instance.post(`/v1/orders/${this.props.orderId}/return_storable`, returnParams).then((response) => {
      console.log(response);
      this.props.returnBox(response.data.message);
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    });
  }

  assignBoxes = (data) => {
    const subscription_box = {
      storable_id: data["id"],
      storable_type: "Box"
    };
    this.closeWebcam();
    const qrThis = this;
    axios_instance.post('/v1/subscription_boxes/' + this.props.display.currentSubscriptionBox.id + '/assign_storable', {subscription_box})
    .then((response) => {
      this.props.assignBox(response.data.data.attributes.storable, qrThis.props.display.currentSubscriptionBox.index);
      NotificationManager.success("Caja Asignada", "", 3000);
    }).catch(() => {
    }).finally(() => {
      hideLoader();
    })
  }

  cancelBox = (data) => {
    this.setState({
      boxId: data,
      savingData: false
    }, this.closeModal);

    NotificationManager.success("Caja Regresada", "", 3000);
    this.props.cancelBox(this.props.display.currentProduct, data);
  }

  render() {
    return(
      <Modal
        isOpen={this.props.display.visibility}
        onRequestClose={this.props.closeModal}
        className="camera-modal"
        style={customStyles}
        contentLabel="Example Modal">
        <div className="camera-modal-close pointer" onClick={this.props.closeModal}>
          <img src={remove} alt="remove"></img>
        </div>
        <div style={{display: "flex", color: "White", justifyContent: "center", flexDirection:"row"}}>
          {
            this.props.display.currentProduct ?
            <div style={{display: "flex", paddingRight: "10px", cursor: "pointer"}}>
              <img src={navArrowL} onClick={() => { this.props.lastProduct(this.props.display.currentProduct, "qrModal")}} alt="arrowL" />
            </div> : ""
          }
          <h2 style={{textAlign: 'center'}}>
            {
              this.props.display.type === "store" ? this.state.currentLabel : this.props.label
            }
          </h2>
          {
            this.props.display.currentProduct ?
            <div style={{display:"flex", paddingLeft:"10px", cursor: "pointer"}}>
              <img src={navArrowR} onClick={() => { this.props.nextProduct(this.props.display.currentProduct, "qrModal") }} alt="arrowR" />
            </div> : ""
          }
        </div>
        {
          this.state.savingData ? (
            <div style={{display: "flex", backgroundColor: "Black"}}> </div>
          ) : (
            <div className="qr-area">
              <QrReader
                delay={500}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ minWidth: '400px', maxWidth: '600px' }}
                />
            </div>
          )
        }
      </Modal>
    )
  }
}
