import React from "react";
import { Collapse } from "react-collapse";
import { NotificationManager } from "react-notifications";
import ShippingSection from "templates/checkout/shipping";
import CalendarSection from "templates/checkout/calendar";
import { axios_instance } from "components/axios";
import { showLoader, hideLoader } from "components/loading-spinner";
import conektaHelper from "components/conekta-helper";
import arrowDown from "img/arrow-down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BoxImg from "img/Caja.png";
import BoxFlyImg from "img/Caja2.png";
import PersonImg from "img/Persona.png";
import securityImg from "img/Seguridad.png";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./slider.css";
import MapGoogle from "components/map";
import moment from "moment";
import Geocode from "react-geocode";
import { Link } from "react-router-dom";
import { IsUserSignedIn } from "components/session";
export default class Body extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      orderV2: {
        product_id: "",
        plan_id: "",
        user_id: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        suburb: "",
        latitude: "",
        longitude: "",
        order_status: "",
        shipping_date: "",
      },
      showPayment: false,
      shippingDate: null,
      shippingAddress: null,
      orderBoxes: [],
      orderAdditionalItems: [],
      shipping: {},
      card: {},
      items: [],
      boxes: [],
      imgKeys: [],
      images: [],
      additionalItems: [],
      selectedDate: {},
      availableDates: {},
      faqOpen: false,
      anyOrder: false,
      orderTab: 1,
      activeTab: 1,
      productTab: 1,
      prices: {
        total: 0,
        additionalItemsTotal: 0,
        boxTotal: 0,
        discount: 0,
      },
      shippingErrors: false,
      showShippingErrors: false,
      styles: {
        orderBar: "order-bar",
      },
      currentWindowHeight: 0,
      loading: true,
      planNumber: 0,
      planType: null,
      pricesOptions: [],
      plansOptions: [],
      center: {
        lat: 25.6866142,
        lng: -100.3161126,
      },
      zoom: 10,
      isMarkerShown: false,
    };
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const images = this.importAll(
      require.context("img/products-images", false, /\.(png|jpe?g|svg)$/)
    );
    const imgKeys = Object.keys(images);
    this.setState({ imgKeys, images });
    // if (!currentUser) {
    //   this.props.history.push("/sign_in?redirect=quoting");
    //   return;
    // }
    // if(currentUser.attributes.subscription.id){
    //   this.props.history.push('users')
    //   return
    // }
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    // set response language. Defaults to english.
    Geocode.setLanguage("es");

    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("mx");
    this.setState({
      currentWindowHeight: window.innerHeight,
    });
    window.addEventListener("resize", this.updateDimensions);
    var order = JSON.parse(sessionStorage.getItem("order"));
    var orderV2 = JSON.parse(sessionStorage.getItem("orderV2"));
    if (orderV2) {
      this.setState({ orderV2, planType: orderV2.plan_id });
    } else {
      sessionStorage.setItem("orderV2", JSON.stringify(this.state.orderV2));
    }

    axios_instance.get("/v2/product_v2s", {}).then((response) => {
      let products = response.data.data;
      products = products.sort((a, b) => +a.attributes.m2 - b.attributes.m2);
      this.setState({ pricesOptions: products });
      if (orderV2) {
        const selectedPrice = response.data.data.findIndex(
          (price) => price.id === orderV2.product_id
        );
        if (selectedPrice > -1) {
          this.setState({
            orderV2,
            planType: orderV2.plan_id,
            planNumber: selectedPrice,
          });
        }
      }
    });
    axios_instance.get("/v2/plans", {}).then((response) => {
      this.setState({ plansOptions: response.data.data });
    });

    if (order) {
      this.setState({
        orderBoxes: order.orderBoxes || [],
        orderAdditionalItems: order.orderAdditionalItems || [],
        prices: order.prices,
        orderTab: order.orderTab || 1,
        activeTab: order.orderTab,
        card: order.card,
        selectedDate: order.selectedDate,
        shipping: order.shipping,
      });
    }
    var checkoutInit = JSON.parse(sessionStorage.getItem("checkoutInit"));
    if (checkoutInit) {
      this.setState({
        boxes: checkoutInit.boxes,
        items: checkoutInit.items,
        additionalItems: checkoutInit.additionalItems,
        availableDates: checkoutInit.availableDates,
      });
      this.setState({ loading: false });
    } else {
      showLoader();
      this.setState({ loading: true });
      const postalCode = this.props.match.params.postalCode;
      axios_instance
        .get("/v1/checkout/init", { params: { postal_code: postalCode } })
        .then((response) => {
          var data = response.data;
          this.setState(
            {
              suburbs: response.data.available_addresses,
              boxes: data.boxes.data,
              items: data.items.data,
              additionalItems: data.additional_items.data,
              availableDates: data.available_date,
            },
            this.saveToSessionStorageInit
          );

          const suburbs = response.data.available_addresses.map((suburb) => {
            return {
              id: suburb.id,
              attributes: {
                "postal-code": suburb.postal_code,
                suburb: suburb.suburb,
              },
            };
          });
          const order = {
            orderBoxes: this.state.orderBoxes,
            orderAdditionalItems: [],
            shipping: {
              postal_code: postalCode,
              suburbs: suburbs,
            },
            selectedDate: this.state.selectedDate,
            card: this.state.card,
          };
          this.saveToSessionStorage(order);
        })
        .catch(() => {})
        .finally(() => {
          hideLoader();
          this.setState({ loading: false });
        });
    }
  }

  importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  incrementPlan = () => {
    if (this.state.planNumber < this.state.pricesOptions.length - 1) {
      const value = +this.state.planNumber + 1;
      this.setState({ planNumber: value });
      const orderV2 = {
        ...this.state.orderV2,
        product_id: this.state.pricesOptions[value].id,
      };
      this.setState({ orderV2 });
    }
  };
  decrementPlan = () => {
    if (this.state.planNumber > 0) {
      const value = +this.state.planNumber - 1;
      this.setState({ planNumber: value });
      const orderV2 = {
        ...this.state.orderV2,
        product_id: this.state.pricesOptions[value].id,
      };
      this.setState({ orderV2 });
    }
  };
  selectedPriceOption() {
    return this.state.pricesOptions[this.state.planNumber];
  }
  selectedPlanOption() {
    return this.state.plansOptions.find((p) => p.id === this.state.planType);
  }
  selectPlanType = (type) => {
    const order = { ...this.state.orderV2, plan_id: type };
    this.setState({ planType: type, orderV2: order });
  };
  // computed
  selectedPrice() {
    const price =
      this.state.pricesOptions[this.state.planNumber]?.attributes?.price;
    const plan = this.selectedPlanOption();
    let discountPercentage = plan?.attributes["discount-percentage"];
    if (!discountPercentage) discountPercentage = 0;
    const discount = price * (discountPercentage / 100);
    const priceWDiscount = price - discount;
    if (priceWDiscount) {
      return <span>{priceWDiscount.toFixed(2)}</span>;
    }
    return null;
  }
  discountInfo() {
    const price =
      this.state.pricesOptions[this.state.planNumber]?.attributes?.price;
    const plan = this.selectedPlanOption();
    let discountPercentage = plan?.attributes["discount-percentage"];
    if (!discountPercentage) discountPercentage = 0;
    const discount = price * (discountPercentage / 100);
    if (discount) {
      return (
        <div
          className="card-header text-center text-white py-1"
          style={{ backgroundColor: "#fa3e61" }}
        >
          Estas ahorrando ${discount.toFixed(2)}/mes
        </div>
      );
    }
    return <span></span>;
  }
  selectedPriceImage() {
    if (this.state.imgKeys.length) {
      const m2 = this.state.pricesOptions.find(
        (opt) => opt.id === this.selectedPriceOption().id
      )?.attributes?.m2;
      const imgKey = `alpha_${m2}.png`;
      // this.state.imgKeys[+this.state.planNumber % +this.state.imgKeys.length];
      return this.state.images[imgKey];
    }
    return "error";
  }
  // computed
  selectedFreightPrice() {
    const price =
      this.state.pricesOptions[this.state.planNumber]?.attributes[
        "freight-price"
      ];
    if (price) {
      return <span>{price.toFixed(2)}</span>;
    }
    return <span>{0}</span>;
  }
  //  computed
  selectedPriceName() {
    const name =
      this.state.pricesOptions[this.state.planNumber]?.attributes["m2"];
    if (name) {
      return <span>{name} m²</span>;
    }
    return null;
  }

  goToSummary = () => {
    this.validateOrder()
      .then(() => {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        const order = {
          product_id: this.state.pricesOptions[this.state.planNumber].id,
          plan_id: this.state.planType,
          user_id: user?.id,
          street: this.state.shippingAddress.street,
          ext_number: this.state.shippingAddress.ext_number,
          int_number: this.state.shippingAddress.int_number,
          postal_code: this.state.shippingAddress.postal_code,
          suburb: this.state.shippingAddress.suburb,
          latitude: this.state.orderV2.latitude,
          longitude: this.state.orderV2.longitude,
          order_status: "pending",
          shipping_date: this.state.shippingDate,
        };
        // axios_instance.post("/v2/order_v2s", { order_v2: order });

        sessionStorage.setItem("orderV2", JSON.stringify(order));
        if (IsUserSignedIn()) {
          this.props.history.push("/summary");
        } else {
          this.props.history.push("/sign_in?redirect=summary");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = (e) => {
    let styles = this.state.styles;
    if (window.innerHeight < this.state.currentWindowHeight) {
      styles.orderBar = "order-bar hide-bar";
    } else {
      styles.orderBar = "order-bar";
    }
    this.setState({
      styles: styles,
    });
  };

  saveToSessionStorageInit = () => {
    var checkoutInit = JSON.parse(sessionStorage.getItem("checkoutInit"));
    if (checkoutInit === null) {
      checkoutInit = {
        boxes: this.state.boxes,
        items: this.state.items,
        additionalItems: this.state.additionalItems,
        availableDates: this.state.availableDates,
      };
      sessionStorage.setItem("checkoutInit", JSON.stringify(checkoutInit));
    }
  };

  setOrder = (box) => {
    var orders = this.state.orderBoxes.filter((i) => i.id !== box.id);
    // Order by size
    orders.push(box);
    var order = {
      orderBoxes: orders,
      orderAdditionalItems: this.state.orderAdditionalItems,
      shipping: this.state.shipping,
      selectedDate: this.state.selectedDate,
      card: this.state.card,
    };

    this.saveToSessionStorage(order);
  };

  removeOrder = (box) => {
    var orders = this.state.orderBoxes.filter((i) => i.id !== box.id);
    var order = {
      orderBoxes: orders,
      orderAdditionalItems: this.state.orderAdditionalItems,
      shipping: this.state.shipping,
      selectedDate: this.state.selectedDate,
      card: this.state.card,
    };
    this.saveToSessionStorage(order);
  };

  setAdditionalItem = (item) => {
    let items = this.state.orderAdditionalItems;
    items.push(item);
    var order = {
      orderBoxes: this.state.orderBoxes,
      orderAdditionalItems: items,
      shipping: this.state.shipping,
      selectedDate: this.state.selectedDate,
      card: this.state.card,
    };
    this.saveToSessionStorage(order);
  };

  removeAdditionalItem = (item) => {
    var items = this.state.orderAdditionalItems.filter(
      (i) => i.title !== item.title
    );
    var order = {
      orderBoxes: this.state.orderBoxes,
      orderAdditionalItems: items,
      shipping: this.state.shipping,
      selectedDate: this.state.selectedDate,
      card: this.state.card,
    };
    this.saveToSessionStorage(order);
  };

  openFaq = () => {
    this.setState({ faqOpen: !this.state.faqOpen });
  };

  saveToSessionStorage = async (order = {}) => {
    order = order || JSON.parse(sessionStorage.getItem("order"));
    order.prices = this.setTotal(order.orderBoxes, order.orderAdditionalItems);
    order.total = order.prices;
    order.orderTab = this.state.orderTab;
    this.setState({
      orderBoxes: order.orderBoxes,
      orderAdditionalItems: order.orderAdditionalItems,
      prices: order.total,
      shipping: order.shipping,
      selectedDate: order.selectedDate,
      card: order.card,
      order: order,
    });

    await sessionStorage.setItem("order", JSON.stringify(order));
  };

  setTotal = (boxes = [], additionalItems = []) => {
    let boxTotal = 0;
    let additionalItemsTotal = 0;
    boxes.map((order) => {
      return (boxTotal += order.total);
    });
    if (additionalItems.length > 0) {
      additionalItems.map((item) => {
        return (additionalItemsTotal += item.price);
      });
    }
    let total = boxTotal + additionalItemsTotal;
    return {
      total: total,
      boxTotal: boxTotal,
      additionalItemsTotal: additionalItemsTotal,
    };
  };

  toggleOrderBar = () => {
    var styles = this.state.styles;
    if (styles.orderBar.includes("ob-toggle")) {
      styles.orderBar = "order-bar";
    } else {
      styles.orderBar = "order-bar ob-toggle";
    }
    this.setState({
      styles: styles,
    });
  };

  setShippingAddress = (shipping) => {
    const formatedAddress = `${shipping.postal_code} ${shipping.suburb} ${shipping.street} ${shipping.ext_number}`;
    Geocode.fromAddress(formatedAddress).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ center: { lat, lng }, isMarkerShown: true, zoom: 16 });
        const orderV2 = {
          ...this.state.orderV2,
          latitude: lat,
          longitude: lng,
        };
        this.setState({ orderV2 });
      },
      (error) => {}
    );
    this.setState({ shippingAddress: shipping });
    var order = {
      orderBoxes: this.state.orderBoxes,
      orderAdditionalItems: this.state.orderAdditionalItems,
      shipping: shipping,
      selectedDate: this.state.selectedDate,
      card: this.state.card,
    };
    this.setState({ shippingErrors: false });
    this.saveToSessionStorage(order);
  };

  shippingErrors = () => {
    this.setState({ shippingErrors: true });
  };

  selectDate = (date) => {
    var d = moment(date.key_value);
    if (date.hour !== "Selecciona tu horario" && date.hour.length > 0) {
      d.add(parseInt(date.hour.split(":")[0]), "hour");
      d.set("minute", parseInt(date.hour.split(":")[1]));
    }
    // 'YYYY-MM-DDT-h:mm'
    this.setState({ shippingDate: d.format() });
    var order = {
      shipping: this.state.shipping,
      selectedDate: date,
      card: this.state.card,
    };
    this.saveToSessionStorage(order);
  };

  nextTab = () => {
    let currentTab = this.state.activeTab;
    let orderTab = this.state.orderTab;
    this.validateNextTab(currentTab).then((response) => {
      if (response) {
        currentTab += 1;
        currentTab = currentTab >= 6 ? 6 : currentTab;
        if (currentTab > orderTab) orderTab = currentTab;
        if (orderTab > currentTab) currentTab = orderTab;
        this.setState({
          orderTab: orderTab,
          activeTab: currentTab,
        });
        var order = JSON.parse(sessionStorage.getItem("order")) || {};
        order.orderTab = currentTab;
        sessionStorage.setItem("order", JSON.stringify(order));
        window.scrollTo(0, 0);
      }
    });
  };

  backTab = () => {
    var backTab = this.state.activeTab - 1;
    if (backTab >= 1) {
      this.setState({
        activeTab: backTab,
      });
      window.scrollTo(0, 0);
    }
  };

  setTab = (tab) => {
    if (
      tab <= this.state.orderTab &&
      this.validateNextTab(this.state.activeTab)
    ) {
      this.setState({
        activeTab: tab,
      });
      window.scrollTo(0, 0);
    }
  };
  validateOrder() {
    return new Promise((resolve, reject) => {
      if (!this.state.shippingAddress) {
        NotificationManager.error("Favor de introducir Direccion");
        reject();
      }
      if (this.state.shippingErrors) {
        NotificationManager.error("Favor de llenar los campos faltantes");
        this.setState({
          showShippingErrors: true,
        });
        reject();
      } else {
        this.setState({
          showShippingErrors: false,
        });
      }
      const addr = this.state.shippingAddress;
      const validAddress =
        addr.postal_code.length > 0 &&
        addr.ext_number.length > 0 &&
        addr.suburb.length > 0 &&
        addr.street.length > 0;
      if (!validAddress) {
        NotificationManager.error("Favor de introducir Direccion");
        reject();
      }
      var order = JSON.parse(sessionStorage.getItem("order"));
      if (order.selectedDate) {
        if (
          order.selectedDate.hour === "Selecciona tu horario" ||
          order.selectedDate.hour.length === 0
        ) {
          NotificationManager.error("Favor de seleccionar un horario");
          reject();
        }
      } else {
        NotificationManager.error("Favor de seleccionar una fecha válida");
        reject();
      }
      resolve();
    });
  }

  validateNextTab = (currentTab) => {
    const validatePromise = new Promise((resolve) => {
      var order = JSON.parse(sessionStorage.getItem("order"));
      order = order || { orderBoxes: [], selectedDate: {} };
      switch (currentTab) {
        case 1:
          if (order.orderBoxes.length < 1) {
            NotificationManager.error(
              "Favor de seleccionar al menos una caja u objeto"
            );
            resolve(false);
          }
          resolve(true);
          break;
        case 2:
          resolve(true);
          break;
        case 3:
          if (this.state.shippingErrors) {
            NotificationManager.error("Favor de llenar los campos faltantes");
            this.setState({
              showShippingErrors: true,
            });
            resolve(false);
          } else {
            this.setState({
              showShippingErrors: false,
            });
          }
          resolve(true);
          break;
        case 4:
          if (order.selectedDate) {
            if (order.selectedDate.hour !== "Selecciona tu horario")
              resolve(true);
            else {
              NotificationManager.error("Favor de seleccionar un horario");
              resolve(false);
            }
          } else {
            NotificationManager.error("Favor de seleccionar una fecha válida");
            resolve(false);
          }
          break;
        case 5:
          if (!localStorage.getItem("currentUser")) {
            conektaHelper.initConekta();
            showLoader();
            conektaHelper.tokenize(
              this.state.card,
              (response) => {
                order.token_id = response.id;
                this.saveToSessionStorage(order);
                hideLoader();
                resolve(true);
              },
              (error) => {
                NotificationManager.error(error.message_to_purchaser);
                hideLoader();
                resolve(false);
                this.setTab(5);
              }
            );
          }
          resolve(true);
          break;
        case 6:
          resolve(true);
          break;
        default:
          resolve(false);
          break;
      }
    });

    return validatePromise;
  };
  // Finish tabs logic

  goToContact = () => {
    this.props.history.push("/contact?quote=true");
  };

  renderFAQ = () => {
    switch (this.state.activeTab) {
      case 1:
        return (
          <ol>
            <li>
              ¿Qué cajas uso para empacar?
              <ul>
                <li>
                  Previo a tu cita de recolección, un boxero acudirá a tu
                  domicilio a dejarte nuestras cajas sin costo. Es necesario
                  utilizar nuestras cajas para poder garantizar la seguridad de
                  tus artículos.
                  <br />
                  Es importante que antes de tu cita tengas todo preparado para
                  que cuando llegue el boxero pueda recolectar tus cosas dentro
                  del tiempo incluido en tu servicio (1 hora).
                </li>
              </ul>
            </li>
            <li>
              ¿Los boxeros me ayudan a empacar?
              <ul>
                <li>
                  Tu cita incluye una hora de servicio, dentro de la cual un
                  Boxero puede ayudarte a empacar. Sin embargo, recomendamos
                  tener todo listo antes de que llegue para evitar cargos por
                  tiempo adicional de servicio de $75 pesos por cada 15 minutos.
                </li>
              </ul>
            </li>
            <li>
              ¿Como puedo empacar y proteger mis cosas?
              <ul>
                <li>
                  Contamos con material de empaque con costo adicional como
                  bolsas para pino de navidad, papel burbuja, plástico para
                  emplayar, cobijas para proteger muebles, etc. Asegúrate de
                  marcar "Sí" en “Material de Empaque” de la sección 2 – ALGUNAS
                  PREGUNTAS.
                </li>
              </ul>
            </li>
            <li>
              ¿Qué no puedo guardar?
              <ul>
                <li>
                  Está prohibido guardar artículos ilegales (droga, explosivos o
                  combustibles, armas, etc.), plantas, animales, perecederos,
                  líquidos, químicos, dinero en efectivo, artículos que expidan
                  olor y cualquier otra cosa que pueda dañar a personal de
                  Boxmi, otros clientes, o nuestra bodega.
                  <br />
                  No recomendamos guardar artículos frágiles debido a que la
                  responsabilidad de empacar es de nuestros clientes, por lo que
                  no podemos asegurar que dichos objetos no sufran daños durante
                  el traslado. Tampoco recomendamos guardar artículos de muy
                  alto valor como joyas, relojes finos y pieles.
                </li>
              </ul>
            </li>
          </ol>
        );
      case 2:
        return (
          <ol>
            <li>
              Estas preguntas son necesarias para conocer la complejidad del
              servicio. Al elegir “SÍ” en alguna de ellas, te contactaremos para
              conocer los detalles.
            </li>
          </ol>
        );
      case 3:
        return (
          <ol>
            <li>
              ¿Puedo poner diferentes direcciones de recolección y envío?
              <ul>
                <li>
                  En esta sección deberás ingresar la dirección en donde quieres
                  que pasemos a hacer la primera recolección. Una vez creado tu
                  perfil, en tu Dashboard podrás modificar o agregar otros
                  domicilios de entrega o recolección.
                </li>
              </ul>
            </li>
            <li>
              No aparece mi colonia.
              <ul>
                <li>
                  Selecciona una colonia que sí aparezca en la lista y escribe
                  tu colonia en la sección de referencias.
                </li>
              </ul>
            </li>
            <li>
              No me deja ingresar mi código postal.
              <ul>
                <li>
                  Es probable que no tengamos servicio en tu área. Envíanos un
                  mensaje de whastapp dando click al ícono que aparece en
                  pantalla para verificarlo.
                </li>
              </ul>
            </li>
            <li>
              ¿Puedo ir yo a dejarles mis cosas?
              <ul>
                <li>
                  Por seguridad para todos nuestros clientes, el acceso a
                  nuestra bodega está restringido sólo para personal autorizado
                  de Boxmi. Nuestro servicio incluye la recolección en tu
                  domicilio, por lo que no es necesario que nos lleves tus cosas
                  a algún lugar.
                </li>
              </ul>
            </li>
          </ol>
        );
      case 4:
        return (
          <ol>
            <li>
              ¿Puedo pedir un servicio para hoy mismo?
              <ul>
                <li>
                  Si se puede, siempre y cuando tengamos disponibilidad y
                  programes tu cita antes de las 12 pm. Los servicios dentro del
                  mismo día tienen un costo adicional de $250 pesos.
                </li>
              </ul>
            </li>
            <li>
              ¿Cómo puedo reservar más de una hora?
              <ul>
                <li>
                  Asegúrate que en la sección 2 – ALGUNAS PREGUNTAS, selecciones
                  "SI" en la pregunta, "HORAS ADICIONALES DE SERVICIO". Nosotros
                  te contactaremos para coordinar el tiempo de servicio.
                </li>
              </ul>
            </li>
            <li>
              ¿Puedo solicitar la recolección entre horas? (ejemplo – 4:45)
              <ul>
                <li>
                  Selecciona la hora más cercana, y contáctanos por whatsapp
                  para coordinar la hora de llegada.
                </li>
              </ul>
            </li>
            <li>
              ¿Como puedo hacer un cambio en la hora de recolección?
              <ul>
                <li>
                  Contáctanos por whatsapp para cambiar el horario de
                  recolección.
                </li>
              </ul>
            </li>
          </ol>
        );
      default:
        return (
          <ol>
            <li>
              ¿Puedo cambiar mi método de pago?
              <ul>
                <li>
                  Sí, Boxmi te hará cargos automáticos a la tarjeta que indiques
                  en la plataforma. Podrás modificarla en cualquier momento en
                  tu dashboard.
                </li>
              </ul>
            </li>
            <li>
              ¿Qué pasa si no pago o no pasa mi tarjeta?
              <ul>
                <li>
                  Si tu tarjeta no funciona te avisaremos en la fecha de corte
                  para que puedas actualizar tus datos y continuar utilizando
                  nuestro servicio.
                </li>
              </ul>
            </li>
            <li>
              ¿Cuándo se realizan los cargos a mi tarjeta?
              <ul>
                <li>
                  El primer cargo se hará al momento de la primera recolección y
                  para los meses posteriores, el día del mes en que inició tu
                  servicio por primera ocasión.
                </li>
              </ul>
            </li>
            <li>
              ¿Puedo pagar con tarjeta de débito? ¿Puedo pagar en efectivo?
              <ul>
                <li>
                  Aceptamos todas las tarjetas de crédito y débito de las
                  principales instituciones bancarias nacionales. Por tu
                  seguridad y evitar problemas, no podemos aceptar pagos en
                  efectivo.
                </li>
              </ul>
            </li>
          </ol>
        );
    }
  };

  finishCheckout = () => {
    this.setState({
      orderBoxes: [],
      orderAdditionalItems: [],
      shipping: {},
      card: {},
      selectedDate: {},
      faqOpen: false,
      orderTab: 1,
      activeTab: 1,
      prices: {
        total: 0,
        additionalItemsTotal: 0,
        boxTotal: 0,
      },
      shippingErrors: false,
    });
  };

  toCurrency = (amount) => {
    amount = amount.toString();
    var len = amount.length;
    return amount === 0
      ? "0"
      : amount.substring(0, len - 2) + "." + amount.substring(len - 2);
  };

  render() {
    if (this.state.loading) {
      return null;
    }

    const numberStyle = {
      position: 'absolute',
      bottom: 0,
      right: 0,
      backgroundColor: 'transparent',
      color: 'white',
      padding: '5px',
      borderRadius: '50%',
      width: '25px',
      height: '25px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid white',
    };

    return (
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="font-weight-bold text-uppercase">
                ¡Cotiza con nosotros!
              </h1>
              <p>Lo recogemos, guardamos y devolvemos.</p>
            </div>
            <div className="col-12 mb-4">
              <div
                className="rainbow-box p-3"
                style={{ backgroundColor: "#5e3dfc" }}
              >
                <div className="d-flex flex-column align-items-center justify-content-between text-center">
                  <div className="position-relative">
                    <img src={BoxImg} width="100" />
                    <span className="position-absolute" style={numberStyle}>
                      1
                    </span>
                  </div>
                  <span className="mt-3" style={{ fontSize: "14px" }}>
                    Fotografiamos y recogemos lo que sea que quieras almacenar.
                  </span>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-between text-center">
                  <div className="position-relative">
                    <img src={BoxFlyImg} width="100" />
                    <span className="position-absolute" style={numberStyle}>
                      2
                    </span>
                  </div>
                  <span className="mt-3" style={{ fontSize: "14px" }}>
                    Embalamos y protegemos tus objetos de manera gratuita.
                  </span>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-between text-center">
                  <div className="position-relative">
                    <img src={securityImg} width="100" />
                    <span className="position-absolute" style={numberStyle}>
                      3
                    </span>
                  </div>
                  <span className="mt-3" style={{ fontSize: "14px" }}>
                    Almacenamos sus objetos de forma segura y protegida.
                  </span>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-between text-center">
                  <div className="position-relative">
                    <img src={PersonImg} width="100" />
                    <span className="position-absolute" style={numberStyle}>
                      4
                    </span>
                  </div>
                  <span className="mt-3" style={{ fontSize: "14px" }}>
                    Entregamos articulos de tu inventario en cualquier momento.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h4 className="font-weight-bold mt-2 text-uppercase">
                Elige el tamaño de almacenamiento
              </h4>
              <div className="quote-card mb-4">
                <div>
                  Puedes seleccionar el tamaño navegando con las flechas o
                  deslizando la barra de abajo.
                </div>
                <div className="quote-selector">
                  <button className="prev-btn btn" onClick={this.decrementPlan}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <h1 className="value-area">{this.selectedPriceName()}</h1>
                  <button className="next-btn btn" onClick={this.incrementPlan}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
                <div>Tamaño Mediano</div>

                <div className="price my-2">${this.selectedPrice()}/mes</div>
                <div className="w-100 px-2">
                  <input
                    type="range"
                    step="1"
                    min="0"
                    max={this.state.pricesOptions.length - 1}
                    className="slider"
                    id="myRange"
                    value={this.state.planNumber}
                    name="planNumber"
                    onChange={this.handleChange}
                    style={{ backgroundColor: "#f7f5f6" }}
                  />
                </div>
                <div className="d-flex w-100">
                  <span className="mr-1">
                    Si deseas cotizar menos de 1 mt² o más de 25 mts²
                    contactanos
                  </span>
                  <Link
                    style={{ color: "#5e3dfc" }}
                    to={{ pathname: "https://wa.me/528110039250" }}
                    target="_blank"
                  >
                    {" "}
                    aqui
                  </Link>
                  .
                </div>
              </div>

              <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                Selecciona tu plan
              </h4>
              <div className="row">
                {this.state.plansOptions.map((plan) => {
                  return (
                    <div
                      className="col btn"
                      onClick={() => this.selectPlanType(plan.id)}
                      key={plan.id}
                    >
                      <div
                        className="quote-card h-100"
                        style={{
                          border: "3px solid",
                          borderColor:
                            this.state.planType === plan.id
                              ? "black"
                              : "transparent",
                        }}
                      >
                        <h4 className="font-weight-bold mb-0">
                          {plan.attributes.title}
                        </h4>
                        <div>{plan.attributes.description}</div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={this.state.planType !== null ? "" : "notSelectable"}
              >
                <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                  Dirección de recolección
                </h4>
                <div className="mb-4">¿A donde vamos?</div>
                <ShippingSection
                  setShippingAddress={this.setShippingAddress}
                  setShippingErrors={this.shippingErrors}
                  showShippingErrors={this.state.showShippingErrors}
                  suburbs={this.state.suburbs}
                  icon={false}
                />
              </div>

              <div
                className="card mt-4 p-0 d-block d-md-none"
                style={{
                  height: "300px",
                  maxWidth: "540px",
                  backgroundColor: "grey",
                  boxShadow: "none",
                }}
              >
                <MapGoogle
                  center={this.state.center}
                  zoom={this.state.zoom}
                  isMarkerShown={this.state.isMarkerShown}
                ></MapGoogle>
                {/* <img
                    style={{ height: "300px", maxWidth: "540px" }}
                    src={googleMap}
                    alt=""
                  /> */}
              </div>

              <div
                className={this.state.planType !== null ? "" : "notSelectable"}
              >
                <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                  Agenda tu cita de recolección
                </h4>
                <div>¿Cuando vamos?</div>
                <CalendarSection
                  availableDates={this.state.availableDates}
                  selectDate={this.selectDate}
                ></CalendarSection>
              </div>

              {/* <h4 className="font-weight-bold mt-2 text-uppercase mt-5">
                Agregar un seguro
              </h4>
              <div className="row">
                <div className="col">
                  <div className="insurance-card">
                    <FontAwesomeIcon icon={faStar} size="2x" className="mr-3"/>
                    <div>
                      <div className="d-flex justify-content-between">
                        <h4 classNamePaymentSection=" font-weight-bold mb-0">Premium</h4>
                        <div>$100/mes</div>
                      </div>
                      <div className="insurance-small-text">
                        Protege artículos de valor máximo de $2,500
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div
                className={this.state.planType !== null ? "" : "notSelectable"}
              >
                <div
                  className="insurance-card mt-4 btn d-none d-md-block"
                  style={{ backgroundColor: "#fa3f62" }}
                  onClick={this.goToSummary}
                >
                  <div className="d-flex justify-content-center w-100">
                    <h4
                      className="text-white mb-0 font-weight-light"
                      style={{ textTransform: "none" }}
                    >
                      Continúa al método de pago
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="showcase-card">
                <div className="d-flex justify-content-between w-100">
                  <h1>{this.selectedPriceName()}</h1>
                  <h1>${this.selectedPrice()}/mes</h1>
                </div>
                <div
                  className="my-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <img
                    src={this.selectedPriceImage()}
                    className=""
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      transform: "scale(2)",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between w-100">
                  *Reduciremos su tarifa mensual si necesita menos espacio.
                </div>
              </div>

              <div
                className="mt-4"
                style={{ top: "140px", position: "sticky" }}
              >
                <div className="card mt-4 p-0" style={{ boxShadow: "none" }}>
                  {this.discountInfo()}
                  <div className="p-3 d-flex justify-content-between">
                    <div className="font-weight-bold">
                      ALMACENAMIENTO DE {this.selectedPriceName()}
                    </div>
                    <div className="font-weight-bold">
                      ${this.selectedPrice()}/mes
                    </div>
                  </div>

                  <div
                    className="d-flex flex-row justify-content-between p-3"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="d-flex flex-column">
                      <span className="font-weight-bold text-uppercase">
                        Costo de transporte
                      </span>
                      Costo de transporte apartir de 6x6
                    </div>
                    <span className="font-weight-bold">
                      ${this.selectedFreightPrice()} MXN
                    </span>
                  </div>

                  <div className="p-3 d-flex justify-content-between">
                    <div className="" style={{ fontSize: "11px" }}>
                      Tu almacenamiento incluye: seguridad 24/7, espacio
                      climatizado, y un inventario en linea con fotogragfias.
                    </div>
                    <div className="font-weight-bold text-secondary">
                      {/* <strike>$500/mes</strike> */}
                    </div>
                  </div>
                </div>

                <div
                  className="card mt-4 p-0 d-none d-md-block"
                  style={{
                    height: "300px",
                    maxWidth: "540px",
                    backgroundColor: "grey",
                    boxShadow: "none",
                  }}
                >
                  <MapGoogle
                    center={this.state.center}
                    zoom={this.state.zoom}
                    isMarkerShown={this.state.isMarkerShown}
                  ></MapGoogle>
                  {/* <img
                    style={{ height: "300px", maxWidth: "540px" }}
                    src={googleMap}
                    alt=""
                  /> */}
                </div>

                <div
                  className={
                    this.state.planType !== null ? "" : "notSelectable"
                  }
                >
                  <div
                    className="insurance-card mt-4 btn d-block d-md-none"
                    style={{ backgroundColor: "#fa3f62" }}
                    onClick={this.goToSummary}
                  >
                    <div className="d-flex justify-content-center w-100">
                      <h4
                        className="text-white mb-0 font-weight-light"
                        style={{ textTransform: "none" }}
                      >
                        Continúa al método de pago
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-container-faq" onClick={this.openFaq}>
          <div className="faq-container">
            <div className="faq-info-container">
              <h4>¿TIENES DUDAS?</h4>
              <p>Consulta las preguntas más frecuentes.</p>
            </div>

            <div className="faq-arrow-container">
              <img src={arrowDown} alt="" />
            </div>
          </div>
          <Collapse isOpened={this.state.faqOpen}>
            <div className="faq-questions-container">{this.renderFAQ()}</div>
          </Collapse>
        </div>
      </div>
    );
  }
}
