import React from "react";
import orderIcon from 'img/order-ico-02.svg';
import { axios_instance } from 'components/axios';
import OngoingOrderElement from 'templates/users/ongoing-order-element';

export default class OngoingOrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: {
        ongoing_orders: {
          data: []
        }
      }
    }
  }

  componentDidMount() {
    var currentUser = JSON.parse(localStorage.getItem("currentUser"))
    if (currentUser) {
      // axios_instance.get('/v2/order_v2s')
      // axios_instance.get(`/v1/users/${currentUser.id}/ongoing_orders`).then((response) => {
      //   if(!response.data && !response.data.ongoing_orders) {
      //     this.setState({
      //       orders: {
      //         ongoing_orders: {
      //           data: []
      //         }
      //       }
      //     });
      //   }
      //   else {
      //     sessionStorage.setItem('ongoingOrder', JSON.stringify(response.data.ongoing_orders.data) )
      //     this.setState({
      //       orders: response.data
      //     });
      //   }
      // }).catch((err) => {
      //   console.log(err);
      // })
    }
  }

  render() {
    const orders = this.state.orders.ongoing_orders.data
    .map((order, index) => {
      return (
        <OngoingOrderElement key={`on_going_order-${index}`} listNumber={index + 1} order={order} />
      )
    });
    if(this.state.orders.ongoing_orders.data.length > 0)
    {
      return (
        <div style={{padding: '1em', backgroundColor: "#C0FFDF"}} className="dashboard-menu-item">
          <div style={{flex: "1 1 100%"}} className="flex-column">
            <div style={{justifyContent: 'flex-start', marginBottom: '0.5em'}} className="dashboard-menu-item-title">
              <img width="25" src={orderIcon} alt="order" className="dashboard-menu-item-icon" style={{marginLeft: '15px'}} />
              <p style={{color: "#19083A"}}>ORDEN<br /><span className="red-item">ACTIVA</span></p>
            </div>
            <div className="dashboard-menu-item-orders">
              {orders}
            </div>
          </div>
        </div>
      )
    }
    return null;
  }
}
