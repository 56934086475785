import React from "react";
import { axios_instance } from "components/axios";
import { IsUserClient } from "components/session";
import { NotificationManager } from "react-notifications";
import { showLoader, hideLoader } from "components/loading-spinner";
import { SaveUserToLocalStorage } from "components/utils";
import { loadHeader } from "templates/header";
import DashboardUserMenu from "templates/common/dashboard-user-menu";
import warehouseIcon from "img/warehouse-icon-w.svg";
import boxCancelIcon from "img/box-cancel-icon.svg";
import truckIcon from "img/truck-icon.svg";
import cancelIcon from "img/cancel-icon.svg";
import boxIcon from "img/box-icon.svg";
import objectIcon from "img/object-icon.svg";
import boxAddIcon from "img/box-add-icon.svg";
import UserStorable from "templates/common/user-storable";
import OrderCard from "templates/common/order-card";
import { Link } from "react-router-dom";
import moment from "moment";
import MapGoogle from "components/map";
import { connect } from "react-redux";
import { setSubscription } from "redux/actions";

const STORABLE_TYPE_BOX = "Box";

class UserDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: {
        attributes: {
          "subscription-details": {},
          subscription: {},
        },
      },
      lastOrder: {},
      subscription: {},
      warehouseStorables: [],
      houseStorables: [],
      orders: [],
      order: {
        attributes: {
          user: {},
        },
      },
      storableActions: {
        cancellingWarehouse: false,
        warehouse: false,
        house: false,
        cancellingHouse: false,
      },
      styles: {
        sidebar: "dashboard-menu",
      },
      center: {
        lat: 25.6866142,
        lng: -100.3161126,
      },
      zoom: 10,
      isMarkerShown: false,
    };
    this.dashboardUserMenuElement = React.createRef();
  }
  componentDidMount() {
    const { dispatch } = this.props;
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ currentUser });
    if (currentUser) {
      if (IsUserClient()) {
        showLoader();
        // axios_instance.get('/v1/users/' + currentUser.id).then((resp) => {
        //   var subscription = resp.data.data.attributes.subscription;
        //   SaveUserToLocalStorage(resp.data.data);
        //   let houseStorables = [];
        //   let warehouseStorables = [];
        //   if (subscription) {
        //     warehouseStorables = subscription['in_warehouse'].data || [];
        //     houseStorables = subscription['in_house'].data || [];
        //   }
        //   this.setState({
        //     lastOrder: resp.data.data.attributes['last-order'],
        //     subscription: subscription,
        //     warehouseStorables: warehouseStorables,
        //     houseStorables: houseStorables
        //   });
        // }).catch(() => {
        // }).finally(() => {
        //   hideLoader();
        // });
        axios_instance
          .get(`/v1/users/${currentUser.id}/user_subscription`)
          .then((resp) => {
            const { customer } = resp.data;
            const subscription = customer.subscription;
            dispatch(setSubscription(subscription));
            localStorage.setItem("subscription", JSON.stringify(subscription));
            if (subscription.billing_cycle_start) {
              subscription.billing_cycle_start_ISO = this.unixToDate(
                subscription.billing_cycle_start
              );
            } else {
              subscription.billing_cycle_start_ISO = "N/D";
            }
            subscription.billing_cycle_end_ISO = this.unixToDate(
              subscription.billing_cycle_end
            );
            subscription.trial_end_ISO = this.unixToDate(
              subscription.trial_end
            );
            if (subscription.subscription_start) {
              subscription.subscription_start_ISO = this.unixToDate(
                subscription.subscription_start
              );
            } else {
              subscription.subscription_start_ISO = "N/D";
            }
            this.setState({
              subscription: subscription,
            });
            console.log(this.state.subscription, ";;;");
          })
          .catch(() => {})
          .finally(() => {
            hideLoader();
          });
        axios_instance
          .get("/v2/order_v2s")
          .then((resp) => {
            const orders = resp.data.data;
            let order = {};
            if (orders.length) {
              order = orders[0];
              const lat = +order.attributes.latitude;
              const lng = +order.attributes.longitude;
              this.setState({
                center: { lat, lng },
                isMarkerShown: true,
                zoom: 15,
              });
            }
            this.setState({
              orders: orders,
              order: order,
            });
          })
          .catch(() => {})
          .finally(() => {
            hideLoader();
          });
      } else {
        this.props.history.push("dashboard");
      }
    } else {
      loadHeader();
    }
  }

  unixToDate(unixTime) {
    return moment.unix(unixTime).format("DD/MMM/YYYY");
  }

  selectedPlan() {
    const plan =
      this.state.currentUser.attributes["subscription"].monthly_interval;
    if (plan) {
      if (plan === 1) return "mes";
      return `${plan} meses`;
    }
    return "";
  }

  toggleStorableSelected = (selectable, key, storableName) => {
    if (selectable) {
      let storables = {};
      storables[storableName] = this.state[storableName];
      storables[storableName][key].attributes.selected =
        !storables[storableName][key].attributes.selected;
      this.setState({
        storables,
      });
    }
  };

  atLeastOneSelected = (storableName1) => {
    for (let i = 0; i < this.state[storableName1].length; i++) {
      if (this.state[storableName1][i].attributes.selected) return true;
    }
    return false;
  };

  enableSelectable = (storableName1, selectableType) => {
    if (this.state.storableActions[selectableType]) {
      if (this.atLeastOneSelected(storableName1)) {
        this.props.history.push("/users/order", [
          this.state.lastOrder,
          this.state.storables[storableName1],
          selectableType,
        ]);
      } else {
        NotificationManager.warning(
          "Favor de seleccionar al menos 1 OBJETO o CAJA",
          ""
        );
      }
    } else {
      let storables = {};
      storables[storableName1] = this.state[storableName1];
      storables.storableActions = this.state.storableActions;
      Object.keys(storables.storableActions).forEach((key) => {
        return (storables.storableActions[key] = key === selectableType);
      });
      if (storables[storableName1]) {
        storables[storableName1].map((storable) => {
          return (storable.attributes.selectable = true);
        });
        this.setState({
          storables,
        });
      }
    }
  };

  disableSelectable = (storableName1, e) => {
    if (e) e.stopPropagation();
    let storables = {};
    storables[storableName1] = this.state[storableName1];
    storables.storableActions = this.state.storableActions;
    Object.keys(storables.storableActions).forEach((key) => {
      return (storables.storableActions[key] = false);
    });
    if (storables[storableName1]) {
      storables[storableName1].map((storable) => {
        storable.attributes.selectable = false;
        storable.attributes.selected = false;
        return storable;
      });

      this.setState({
        storables,
      });
    }
  };

  renderProducts = (storables, storablesName) => {
    let storablesHtml = storables.map((storable, index) => {
      let storableIcon = objectIcon;
      const storableType = storable.attributes.storable["storable-type"];
      const storableSize =
        storable.attributes.storable["storable-size-t"].toUpperCase();

      if (storableType === STORABLE_TYPE_BOX) {
        storableIcon = boxIcon;
      }
      return (
        // <UserStorable
        //   key={index}
        //   id={storable.id}
        //   storableIcon={storableIcon}
        //   storableSize={storableSize}
        //   title={storable.attributes.title}
        //   selected={storable.attributes.selected}
        //   selectable={storable.attributes.selectable}
        //   toggleStorableSelected={this.toggleStorableSelected}
        //   storableIndex={index}
        //   storablesName={storablesName}
        //   goToInventory={() => this.goToInventory(storable.id)}
        // />
        <OrderCard></OrderCard>
      );
    });
    return storablesHtml;
  };

  renderOrders = (orders) => {
    let storablesHtml = orders.map((order, index) => {
      return <OrderCard order={order.attributes}></OrderCard>;
    });
    return storablesHtml;
  };

  goToInventory = (storableId, storableType) => {
    this.props.history.push(`inventory/${storableId}`);
  };

  scheduleReturn = () => {
    this.props.history.push("/users/order", [this.state.lastOrder]);
  };

  goToQuoting = () => {
    this.props.history.push("/quoting");
  };

  toggleSidebar = () => {
    this.dashboardUserMenuElement.current.toggleSidebar();
  };

  isAccountConfirmed = () => {
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (!currentUser.attributes["is-confirmed"]) {
      return (
        <div
          className="p-4 h3 back-red w-100 text-white mb-5 welcome-intro-title"
          style={{ border: "2px solid #19083A" }}
        >
          Confirma tu cuenta, revisa tu correo electrónico para continuar.
          <div id="requestMsg" className="h5 mb-0">
            ¿No encuentras el correo? click{" "}
            <u
              style={{ cursor: "pointer" }}
              className="cursor-pointer"
              onClick={() => this.requestAccountConfirmation()}
            >
              aqui
            </u>{" "}
            para reenivar el mensaje de confirmación
          </div>
        </div>
      );
    }
  };

  toCustomerStorables() {
    this.props.history.push(`customer_storable/${this.state.currentUser.id}`);
  }
  toSubscriptionBoxes() {
    this.props.history.push(
      `/subscription_boxes/${this.state.currentUser.id}/${this.state.order.id}`
    );
  }
  // toEditOrder() {
  //   this.props.history.push(`/orders_v2/${this.state.order.id}/edit`);
  // }

  requestAccountConfirmation() {
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
    axios_instance
      .post("/auth/confirmation", {
        user: {
          email: currentUser.attributes.email,
        },
      })
      .then(() => {
        alert("msg enviado");
        document.getElementById("requestMsg").innerHTML = "Mensaje enviado ✌";
      });
  }

  statusClass() {
    switch (this.state.order.attributes["order-status"]) {
      case "pending":
        return "badge badge-pill badge-warning";
      case "fulfilled":
        return "badge badge-pill badge-primary";
      case "canceled":
        return "badge badge-pill badge-danger";
      default:
        return "";
    }
  }

  orderStatus() {
    switch (this.state.order.attributes["order-status"]) {
      case "pending":
        return "En casa";
      case "fulfilled":
        return "En bodega";
      case "canceled":
        return "Cancelado";
      default:
        return "";
    }
  }

  render() {
    const cancellingWarehouse = this.state.storableActions.cancellingWarehouse;
    const warehouse = this.state.storableActions.warehouse;
    const cancellingHouse = this.state.storableActions.cancellingHouse;
    const house = this.state.storableActions.house;
    return (
      <div className="dashboard-container dashboard-margin-top">
        <DashboardUserMenu ref={this.dashboardUserMenuElement} />
        <div className="user-dashboard-container">
          {this.isAccountConfirmed()}
          {this.state.orders.length > 0 && (
            <div className="user-dashboard-banner">
              <div className="flex-column flex-wrap">
                <button
                  className="sidebar-button"
                  onClick={() => this.toggleSidebar()}
                >
                  &#9776;
                </button>
                <span className="welcome-intro-title">DASHBOARD</span>
                <div className="flex-row flex-wrap">
                  {this.state.order.attributes["order-status"] ===
                    "fulfilled" && (
                    <div
                      className="img-box img-box-purple cursor-pointer"
                      onClick={() => this.toSubscriptionBoxes()}
                    >
                      <img src={warehouseIcon} alt="warehouse" height="30" />
                      <span className="img-box-span">EN BODEGA</span>
                    </div>
                  )}
                  {this.state.order.attributes["order-status"] ===
                    "pending" && (
                    <div
                      className="img-box img-box-purple cursor-pointer"
                      onClick={() => this.toCustomerStorables()}
                    >
                      <img src={warehouseIcon} alt="warehouse" height="30" />
                      <span className="img-box-span">EN CASA</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-column">
                <span
                  style={{ color: "white" }}
                  className="welcome-intro-title"
                >
                  .
                </span>
                <div className="flex-row justify-content-center flex-wrap">
                  <div className="mr-3" style={{ textAlign: "right" }}>
                    <div className="box-info-label">Estatus de la orden</div>
                    <h1
                      className={`text-center ${this.statusClass()}`}
                      style={{ fontSize: "18px" }}
                    >
                      {this.orderStatus()}
                    </h1>
                  </div>
                  <Link
                    className="btn-box back-red cancel-action pointer"
                    to={{ pathname: "https://wa.me/528110039250" }}
                    target="_blank"
                  >
                    <img
                      className={
                        cancellingWarehouse
                          ? "dashboard-action-active-left"
                          : "hide-node"
                      }
                      onClick={(e) =>
                        this.disableSelectable("warehouseStorables", e)
                      }
                      src={cancelIcon}
                      alt="cancel"
                    />
                    <img height="30" src={boxCancelIcon} alt="cancel" />
                    <span className="cancel-box-span">CANCELAR</span>
                  </Link>
                  <Link
                    className="btn-box back-red bg-primary cancel-action pointer mt-4 mt-lg-0"
                    to={{ pathname: "https://wa.me/528110039250" }}
                    target="_blank"
                  >
                    <img height="30" src={boxIcon} alt="cancel" />
                    <span className="cancel-box-span">CAMBIAR MI PLAN</span>
                  </Link>
                  <Link
                    className="btn-box padding-2 dashboard-action pointer mt-4 mt-lg-0"
                    to={{ pathname: "https://wa.me/528110039250" }}
                    target="_blank"
                  >
                    <img
                      className={
                        warehouse ? "dashboard-action-active" : "hide-node"
                      }
                      onClick={(e) =>
                        this.disableSelectable("warehouseStorables", e)
                      }
                      src={cancelIcon}
                      alt="cancel"
                    />
                    <img
                      height="30"
                      style={{ marginRight: "1vw" }}
                      src={truckIcon}
                      alt="action"
                    />
                    <span className="dashboard-action-title">
                      {warehouse ? "PROCESAR" : "AGENDAR"}
                      <br />
                      <span className="blue-item">RECUPERACIÓN</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {/* <div className="flex mb-3">
            <button
              className="btn btn-primary"
              onClick={() => this.toCustomerStorables()}
            >
              Objetos por recoger
            </button>
          </div> */}

          {this.state.orders.length > 0 && (
            <div className="">
              <div className="order-grid">
                <div className="order-client order-client-card">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h2>Datos del Cliente</h2>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Nombre: </div>
                        {this.state.currentUser.attributes["full-name"]}
                      </span>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Dirección: </div>
                        {this.state.order.attributes["postal-code"]},
                        {" " + this.state.order.attributes.suburb + " "}
                        {this.state.order.attributes.street + " "}
                        {this.state.order.attributes["ext-number"] + " "}
                        {this.state.order.attributes["int-number"]}
                      </span>
                      {this.state.order.attributes["references"] ? (
                        <span style={{ marginTop: "1rem" }}>
                          <div class="box-info-label">Referencias: </div>
                          {this.state.order.attributes["references"]}
                        </span>
                      ) : (
                        ""
                      )}
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Teléfono: </div>
                        {this.state.order.attributes.user.phone}
                      </span>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Email: </div>
                        {this.state.order.attributes.user.email}
                      </span>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <h2 className="m-0 mr-2">
                          Datos de la orden # {this.state.order.id}
                        </h2>
                        {/* <button
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => this.toEditOrder()}
                      >
                        Editar
                      </button> */}
                      </div>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Producto: </div>
                        {this.state.order.attributes["product-name"]}
                      </span>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Precio:</div>$
                        {this.state.order.attributes["total"]} MXN
                      </span>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">Envio: </div>$
                        {this.state.order.attributes["freight-price"]} MXN
                      </span>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">
                          Intervaluo mensual:{" "}
                        </div>
                        {this.state.order.attributes["monthly-interval"]}
                      </span>
                      <span style={{ marginTop: "1rem" }}>
                        <div className="box-info-label">
                          Fecha de siguiente cobro:{" "}
                        </div>
                        {this.state.subscription.billing_cycle_start_ISO}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="order-client-card">
                  <h2>Ubicación</h2>

                  <MapGoogle
                    center={this.state.center}
                    zoom={this.state.zoom}
                    isMarkerShown={this.state.isMarkerShown}
                  ></MapGoogle>
                </div>
              </div>
            </div>
          )}

          {this.state.houseStorables.length > 0 ? (
            <React.Fragment>
              <div className="user-dashboard-banner">
                <div className="flex-row">
                  <div className="img-box img-box-blue">
                    <img src={warehouseIcon} alt="warehouse" height="30" />
                    <span className="img-box-span red-item">
                      &nbsp;EN CASA&nbsp;
                    </span>
                  </div>
                  <span className="main-title">TEMPORALMENTE</span>
                </div>
                <div className="flex-column">
                  <div className="flex-row">
                    <div
                      className="btn-box back-red cancel-action pointer"
                      onClick={() =>
                        this.enableSelectable(
                          "houseStorables",
                          "cancellingHouse"
                        )
                      }
                    >
                      <img
                        className={
                          cancellingHouse
                            ? "dashboard-action-active-left"
                            : "hide-node"
                        }
                        onClick={(e) =>
                          this.disableSelectable("houseStorables", e)
                        }
                        src={cancelIcon}
                        alt="cancel"
                      />
                      <img src={boxCancelIcon} alt="cancel" />
                    </div>
                    <div
                      className="btn-box padding-2 dashboard-action pointer"
                      onClick={() =>
                        this.enableSelectable("houseStorables", "house")
                      }
                    >
                      <img
                        className={
                          house ? "dashboard-action-active" : "hide-node"
                        }
                        onClick={(e) =>
                          this.disableSelectable("houseStorables", e)
                        }
                        src={cancelIcon}
                        alt="cancel"
                      />
                      <img
                        style={{ marginRight: "1vw" }}
                        src={truckIcon}
                        alt="cancel"
                      />
                      <span className="dashboard-action-title">
                        {house ? "PROCESAR" : "AGENDAR"}
                        <br />
                        <span className="blue-item">RECOLECCIÓN</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  house || cancellingHouse
                    ? "flex-row dashboard-action-instructions"
                    : "hide-node"
                }
              >
                <div className="flex-row">
                  <span className="red-item">01.</span>SELECCIONA LAS CAJAS U
                  OBJETOS QUE DESEAS{" "}
                  {cancellingHouse ? "CANCELAR" : "QUE RECOLECTEMOS"}
                </div>
                <div className="flex-row">
                  <span className="red-item">02.</span>PROCESA LA{" "}
                  {cancellingHouse ? "CANCELACIÓN" : "RECOLECCIÓN"} OPRIMIENDO
                  EL BOTÓN DE ARRIBA
                </div>
                <div className="flex-row">
                  <span className="red-item">03.</span>CONFIRMA DATOS DE LA{" "}
                  {cancellingHouse ? "CANCELACIÓN" : "RECOLECCIÓN"}
                </div>
              </div>
              <div className="user-dashboard-content-grid">
                {this.renderProducts(
                  this.state.houseStorables,
                  "houseStorables"
                )}
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default connect()(UserDashboard);
