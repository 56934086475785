import React from 'react';
import Modal from 'react-modal';
import LoadingImg from 'img/spinner-loading.gif';
Modal.setAppElement(document.getElementById('root'));
const customStyles = {
  overlay: {
    zIndex                : '100'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0'
  }
};
function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

class LoaderManager {
  _defaultLoader = null;
  register(_ref) {
    if (!this._defaultLoader && "_id" in _ref) {
      this._defaultLoader = _ref;
    }
  }
  unregister(_ref) {
    if (!!this._defaultLoader && this._defaultLoader._id === _ref._id) {
      this._defaultLoader = null;
    }
  }
  getDefault() {
    return this._defaultLoader;
  }
}

let loaderManager = new LoaderManager();

export function showLoader() {
  const ref = loaderManager.getDefault();
  if (!!ref) {
    ref.showLoader();
  }
}

export function hideLoader() {
  const ref = loaderManager.getDefault();
  if (!!ref) {
    ref.hideLoader();
  }
}

export default class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    if (!this._id) this._id = srid();
  }

  componentDidMount() {
    loaderManager.register(this);
  }

  componentWillUnmount() {
    loaderManager.unregister(this);
  }

  showLoader() {
    this.setState({
      isLoading: true
    })
  }

  hideLoader(){
    this.setState({
      isLoading: false
    })
  }

  render() {
    return (
        <Modal
          isOpen={this.state.isLoading}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <img src={LoadingImg} alt="loader" height="100px"></img>
        </Modal>
    );
  }
}
