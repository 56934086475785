import React from "react";
import Quoter from 'templates/common/quoter';
import "css/features.css";
import FHome from 'img/features_servicio-domicilio.jpg';
import FBoxero from 'img/features_boxero.jpg';
import FWarehouse from 'img/features_bodega-segura.jpg';
import FBoxes from 'img/features_cajas-sin-costo.jpg';
import FInventory from 'img/features_inventario.jpg';
import FSafe from 'img/features_seguro.jpg';
import FPickup from 'img/features_recolecciones.jpg';
import BoxIcon2 from 'img/cotiza-cajas-02.svg';

export default class Features extends React.Component {
  render() {
    return (
      <div>
        <main>
          <section class="features-section py-5">
            <div class="cajas05"><img src={BoxIcon2} alt="" /></div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h1>Features</h1>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FHome} alt="Boxmi. Servicio a domicilio" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Servicio a domicilio</h4>
                  <p>No más vueltas innecesarias a la minibodega. Nosotros vamos por tus cosas y te las entregamos en el momento que las necesites.</p>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FBoxero} alt="Boxmi. Boxeros" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Boxeros</h4>
                  <p>Deja que nuestros boxeros hagan el trabajo pesado por ti y evítate dolores de cabeza y de espalda.</p>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FWarehouse} alt="Boxmi. Bodega segura" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Bodega segura</h4>
                  <p>La seguridad de tus cosas es nuestra máxima prioridad. Nuestra bodega cuenta con acceso restringido  y seguridad y monitoreo 24/7.</p>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FBoxes} alt="Boxmi. Cajas sin costo" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Cajas sin costo</h4>
                  <p>Antes de tu cita de recolección te llevamos nuestras cajas sin costo para que puedas guardar tus cosas.</p>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FInventory} alt="Boxmi. Inventario fotográfico" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Inventario fotográfico</h4>
                  <p>Tendrás acceso a un inventario fotográfico en donde podrás ver y administrar tus cosas.</p>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FSafe} alt="Boxmi. Seguro de protección" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Seguro de protección</h4>
                  <p>Nuestro servicio incluye un paquete de cobertura básica para tus cosas, si requieres mayor cobertura podrás contratarla en nuestra página.</p>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5 feature-item">
                  <div class="fi-img">
                    <img src={FPickup} alt="Boxmi. Recolecciones gratis" class="img-fluid" />
                  </div>
                  <h4 class="mt-4">Recolecciones gratis</h4>
                  <p>Todas las recolecciones de cajas y objetos son gratis. No aplica para planes por m<sub>2</sub></p>
                </div>
              </div>
            </div>
          </section>
          <Quoter quoterSection={0} />
        </main>
      </div>
    )
  }
}
