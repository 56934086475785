export const resources = {
  date_time: {
    Sunday: "Domingo",
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miercoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado"
  },
  months: {
    January: "Enero",
    February: "Febrero",
    March: "Marzo",
    April: "Abril",
    May: "Mayo",
    June: "Junio",
    July: "Julio",
    August: "Agosto",
    September: "Septiembre",
    October: "Octubre",
    November: "Noviembre",
    December: "Diciembre"
  },
  common: {
    accept: "Aceptar",
    add: "Agregar",
    address: "Dirección",
    cancel: "Cancelar",
    confirm: "Confirmar",
    discount: "Descuento",
    error: "Error",
    hours: "hrs",
    location: "Ubicación",
    missing_fields: "Favor de llenar los campos marcados",
    next: "Siguiente",
    none: "No hay",
    payment_form: "Forma de Pago",
    payment_method: "Metódo de Pago",
    per: "POR ",
    report: "Reporte:",
    save: "Guardar",
    send: "Enviar",
    success: "Éxito",
    total: "Precio Total",
    validate: "Validar",
  },
  order: {
    statuses: {
      canceled: "CANCELADA",
      pending: "PENDIENTE",
      fulfilled: "COMPLETA",
    },
    type: {
      assign: "ASIGNAR",
      pickup: "RECOLECTAR",
      return: "REGRESAR",
      cancel: "CANCELAR",
      assign_quote: "ASIGNAR CUSTOM"
    }
  },
  recover_password: {
    description: "Ingresa tu correo electrónico y te enviaremos un mensaje con instrucciones.",
    title: "Recupera tu Contraseña",
  },
  register: {
    agreeable: "Al registrarte estas aceptando",
    email: "Correo Electrónico*",
    finish: "Registrarse",
    password: "Contraseña(min. 8 caracteres)",
    password_confirmation: "Confirmar Contraseña",
    phone: "Teléfono",
    placeholder_last_name: "Apellido(s)",
    privacy: "Políticas de Privacidad",
    terms_and_conditions: "Terminos y Condiciones de Uso",
    title: "Registrate",
    name: "Nombre*",
    message: "Mensaje"
  },
  box: {
    box_types: {
      medium: "Mediana",
      large: "Grande",
      extra_large: "Extra Grande"
    }
  },
  items: {
    labels: {
      box: "CAJA",
      item: "OBJETO"
    },
    item_types: {
      small: "Chico",
      medium: "Mediana",
      large: "Grande",
      extra_large: "Extra Grande"
    }
  }
}

export default resources
