import React from "react";
import { axios_instance } from 'components/axios';
import { showLoader, hideLoader } from 'components/loading-spinner';
import StringResources from 'components/string-resources';
import { validations } from 'components/validations';
import { NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Whatsapp from 'templates/whatsapp';
import { GetSearchParameters } from 'components/utils';

export default class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      name: "",
      message: "",
      errors: [],
      isQuote: false
    }
  }

  componentDidMount() {
    let locationParams = GetSearchParameters();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let stateParams = this.state;
    if(currentUser && currentUser.attributes)
    {
      stateParams.email = currentUser.attributes.email;
      stateParams.name = currentUser.attributes['full-name'];
    }
    if(locationParams.quote)
      stateParams.isQuote = true;

    this.setState({
      email: stateParams.email,
      name: stateParams.name,
      isQuote: stateParams.isQuote
    });
    console.log(this.state);
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  handleSubmit = () => {
    let fields = {
      email: this.state.email,
      name: this.state.name
    };

    var errors = validations(fields)
    this.setState({errors: errors})
    errors = Object.keys(errors).some(x => errors[x])
    if (errors) {
      NotificationManager.error(StringResources.common.missing_fields, "", 3000);
    } else {
      showLoader();
      fields.message = this.state.message;
      fields.is_quote = this.state.isQuote;
      axios_instance.post('/v1/contact_forms', {
        contact_form: fields
      }).catch(() => {
      }).then((response) => {
        let message = "Hemos recibido tu mensaje. Gracias";
        NotificationManager.info(message);
        this.props.history.push('/');
      }).finally(() => {
        hideLoader();
      })
    }
  }

  render() {
    return(
      <div style={{minHeight: "75vh"}} className="session-container">
        <div className="flex-column">
          <span className="back-btn" onClick={() => { this.props.history.goBack() }}><FontAwesomeIcon icon={faChevronLeft} /> Regresar</span>
          <p className="session-title">
            { this.state.isQuote ? "Solicita tu cotización" : "Contáctanos" }
          </p>
          <strong className="contact-subtitle">{
              this.state.isQuote ? "Dejanos tu contacto para solicitar una cotización" : "Estamos para apoyarte y explicarte todo lo que necesites"
          }</strong>
            <div className="flex-column">
              <div className="form-group" style={{alignSelf: 'center'}}>
                <input
                  type="text"
                  className={(this.state.errors.email ? "form-control bigger-input error" : "form-control bigger-input")}
                  name="email"
                  placeholder={StringResources.register.email}
                  value={this.state.email}
                  onChange={this.handleChange}/>
              </div>
              <div className="form-group" style={{alignSelf: 'center'}}>
                <input
                  type="text"
                  className={(this.state.errors.name ? "form-control bigger-input error" : "form-control bigger-input")}
                  name="name"
                  placeholder={StringResources.register.name}
                  onChange={this.handleChange}
                  value={this.state.name}/>
              </div>
              <div className="form-group" style={{alignSelf: 'center'}}>
                <textarea
                  className={(this.state.errors.message ? "form-control bigger-input error" : "form-control bigger-input")}
                  name="message"
                  rows="4"
                  placeholder={StringResources.register.message}
                  onChange={this.handleChange}/>
              </div>
              <button className="sign-in-btn" onClick={this.handleSubmit}><strong>Envíar</strong></button>
            </div>
        </div>
        <Whatsapp />
      </div>
    )
  }
}
