import React from "react";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { axios_instance } from 'components/axios';
import { resources } from 'components/string-resources';
import CustomTable from "components/table";
import DashboardMenu from "templates/common/dashboard-menu";

const orderColumns = [
  {
    Header: "id",
    accesor: d => d,
    id: "id",
    show: false
  },
  {
    Header: "Tipo de Orden",
    accessor: d => d.attributes["order-type"],
    id: 'order_type',
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => ( value ? resources.order.type[value] : ""),
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "active") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false;
    },
    Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Todos</option>
        <option value="assign">Asignar</option>
        <option value="pickup">Recoger</option>
        <option value="return">Regresar</option>
        <option value="cancel">Cancelar</option>
        <option value="assign_quote">Cotización</option>
      </select>
  },
  {
    Header: "Estatus de Orden",
    accessor: d => d.attributes["order-status"],
    id: 'order_status',
    style: {
      cursor: "pointer"
    },
    Cell: ({value}) => ( value ? resources.order.statuses[value] : ""),
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "active") {
        return row[filter.id] === true;
      }
      return row[filter.id] === false;
    },
    Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Todos</option>
        <option value="pending">Pendiente</option>
        <option value="canceled">Cancelado</option>
        <option value="fulfilled">Terminado</option>
      </select>
  },
  {
    Header: "Fecha de Pedido",
    accessor: d => d.attributes["shipping-date"],
    id: 'shipping_date',
    style: {
      cursor: "pointer"
    },
    filterable: false
  },
  {
    Header: "Total a añadir a suscripción",
    accessor: d => d.attributes["subscription-amount"] / 100,
    id: 'subscription-amount',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
  {
    Header: "Cliente",
    accessor: d => d.attributes["client-name"],
    id: 'client-name',
    sortable: false,
    filterable: false,
    style: {
      cursor: "pointer"
    }
  },
];

export default class Boxes extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: [],
      pages: null,
      loading: true,
      page: 1,
    };
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    let params = {
      page: state.page + 1,
      per_page: state.pageSize
    }

    if(state.sorted.length) {
      state.sorted.forEach(function(sort) {
        let sortBy = sort.id
        params[sortBy] = sort.desc ? "desc" : "asc"
      });
    }
    if (state.filtered.length) {
      state.filtered.forEach(function(filter) {
        params["by_" + filter.id] = filter.value;
      });
    }
    axios_instance.get("/v1/orders", {params})
    .then((res) => {
      if(res.data.data) {
        this.setState({
          data: res.data.data,
          loading: false,
          pages: res.data.meta.pagination["total-pages"],
        });
      }
    }).catch(() => {
    });
  }

  render() {
    const { data, pages, loading } = this.state;
    return(
      <div className="dashboard-container">
        <DashboardMenu />
        <div className="dashboard-content">
          <div className="dashboard-content-header">
            <div className="dashboard-content-header-item">
              <h3 className="dashboard-content-header-title">Órdenes</h3>
              <hr />
            </div>
          </div>
          <div className="dashboard-content-body">
            <div className="row">
              <div className="col-sm-12">
                <CustomTable {...this.props}
                  columns={orderColumns}
                  data={data}
                  pages={pages}
                  loading={loading}
                  manual
                  onFetchData={AwesomeDebouncePromise(this.fetchData, 300)}
                  filterable
                  defaultPageSize={10}
                  defaultSorted={[
                    {
                      id: "shipping_date",
                      desc: true
                    }
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if(column.Header) {
                          this.props.history.push(`/Orders/${rowInfo.original.id}`, [rowInfo.original])
                        } else {
                          handleOriginal();
                        }
                      }
                    }
                  }}
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
