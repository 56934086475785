export const LeftPad = (num, size) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
};

export const ToCurrency = (amount) => {
  if (amount) {
    amount = amount.toString();
    var len = amount.length;
    return amount === "0" ?  "$0" : "$" + amount.substring(0, len - 2);
  }
  return "$0";
}

export const SaveUserToLocalStorage = (user = {}) => {
  localStorage.setItem("currentUser", JSON.stringify(user))
}

export const GetSearchParameters = () => {
  let locationParams = window.location.search.substr(1);
  return locationParams !== null && locationParams !== "" ? transformToAssocArray(locationParams) : {};
}

const transformToAssocArray = (prmstr) => {
  let params = {};
  let prmarr = prmstr.split("&");
  for ( let i = 0; i < prmarr.length; i++) {
      let tmparr = prmarr[i].split("=");
      params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export const findGetParameter = (parameterName) => {
  var result = null,
      tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

export default LeftPad;
